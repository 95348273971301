import React from 'react';
import {Box, Stack} from '@mui/material';
import {Outlet} from 'react-router-dom';

import styles from './Dashboard.module.scss';
import {Filter} from './Filter/Filter';

const DashboardPage = () => {
    return (
        <Stack spacing={1}>
            <Filter />
            <Box
                className={styles.contentHeight}
                sx={{overflow: 'auto'}}
                component="div"
            >
                <Outlet />
            </Box>
        </Stack>
    );
};

export default React.memo(DashboardPage);
