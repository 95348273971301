import {Alert, AlertTitle, IconButton, Stack} from '@mui/material';
import {AccountDataWithPassword} from '../types';
import {Close, DownloadOutlined} from '@mui/icons-material';
import FileSaver from 'file-saver';
import {createTxtContent} from './utils';
import {forwardRef} from 'react';
import {SnackbarContent} from 'notistack';

type SuccessNotificationProps = {
    data: null | AccountDataWithPassword;
    onClose: () => void;
};

export const SuccessNotification = forwardRef(
    ({data, onClose}: SuccessNotificationProps, ref: React.ForwardedRef<HTMLDivElement>) => {
        const handleSaveDocument = () => {
            if (data) {
                const txtContent = createTxtContent(data); // Преобразуем объект в строку
                const blob = new Blob([txtContent], {type: 'text/plain;charset=utf-8'});
                FileSaver.saveAs(blob, `account-${data.username}_data.txt`);
            }
        };

        return (
            <SnackbarContent
                ref={ref}
                style={{width: '350px'}}
            >
                <Alert
                    severity='success'
                    sx={{width: '100%'}}
                    style={{whiteSpace: 'pre-wrap'}}
                    action={
                        <Stack
                            direction='row'
                            gap={1}
                        >
                            <IconButton
                                size='small'
                                onClick={handleSaveDocument}
                            >
                                <DownloadOutlined fontSize='inherit' />
                            </IconButton>
                            <IconButton
                                size='small'
                                onClick={onClose}
                            >
                                <Close fontSize='inherit' />
                            </IconButton>
                        </Stack>
                    }
                >
                    <AlertTitle>
                        Учетная запись {data?.username} успешно сохранена. Вы можете скачать
                        реквизиты для аутентификации.
                    </AlertTitle>
                </Alert>
            </SnackbarContent>
        );
    },
);
