import {Stack, Typography} from '@mui/material';
import React from 'react';
import {TripDetails} from '@features/wellDesign/DashboardItems/Trips/types';
import {ValueWithLabel} from './ValueWithLabel';

export const TripChiselInfo = ({
    isLoading,
    tripDetails,
}: {
    isLoading: boolean;
    tripDetails?: TripDetails;
}) => {
    return (
        <div>
            <Typography variant='h4'>Долото</Typography>
            <Stack
                direction='row'
                alignItems='center'
                sx={{
                    display: 'grid',
                    gridTemplateColumns: '1fr 1fr 1fr',
                }}
            >
                <ValueWithLabel
                    label='Тип'
                    isLoading={isLoading}
                >
                    {tripDetails?.chiselType}
                </ValueWithLabel>
                <ValueWithLabel
                    label='Размер'
                    isLoading={isLoading}
                >
                    {tripDetails?.chiselSize}
                </ValueWithLabel>
                <ValueWithLabel
                    label='Причина подъема'
                    isLoading={isLoading}
                >
                    {tripDetails?.chiselRiseReason}
                </ValueWithLabel>
            </Stack>
        </div>
    );
};
