import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {RootState} from '../../types/common';

export type ComponentPayload<Type = unknown> = {
    id: keyof RootState['components'];
    data: Type;
};

type RemoveComponentPayload = Pick<ComponentPayload, 'id'>;

const initialState: RootState['components'] = {};
export const componentsReducerName = 'components';

const componentSlice = createSlice({
    name: componentsReducerName,
    initialState,
    reducers: {
        set: (state: RootState['components'], {payload}: PayloadAction<ComponentPayload>) => {
            state[payload.id] = payload.data;
        },
        remove: (state, {payload}: PayloadAction<RemoveComponentPayload>) => {
            state[payload.id] = null;
        },
    },
});

export default componentSlice.reducer;
export const componentsActions = componentSlice.actions;

export const componentSelectors = {
    get:
        <Type = unknown>(id: keyof RootState['components']) =>
        (state: RootState) => {
            return state[componentsReducerName][id] as Type;
        },
};
