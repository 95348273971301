import React from 'react';
import {TimeBalanceTableContentProps} from '../types';
import {DataGrid} from '@mui/x-data-grid';
import {Alert, Box} from '@mui/material';

const TimeBalanceTableContent = ({
    data,
    sort,
    onSortChange,
    onRowClick,
    columns,
}: TimeBalanceTableContentProps) => {
    if (!data) {
        return (
            <Box sx={{flex: 1}} component="div">
                <Alert severity='info'>Нет данных</Alert>
            </Box>
        );
    }

    return (
        <DataGrid
            columns={columns}
            rows={data}
            sortingMode='server'
            sortModel={sort}
            hideFooter
            onRowClick={onRowClick}
            onSortModelChange={onSortChange}
            sx={{
                flex: 1,
                '& .MuiDataGrid-row': {
                    cursor: 'pointer',
                },
            }}
        />
    );
};

export default React.memo(TimeBalanceTableContent);
