const colors = [
    '#5B8FF9',
    '#5AD8A6',
    '#FF99C3',
    '#F6BD16',
    '#6F5EF9',
    '#6DC8EC',
    '#945FB9',
    '#FF9845',
    '#1E9493',
    '#5D7092',
];

export const getIndexColor = (i: number) => {
    return colors[i] ?? 'blue';
};
