// @ts-nocheck
import {FullscreenExitOutlined, FullscreenOutlined} from '@mui/icons-material';
import {IconButton} from '@mui/material';
import {LightTooltip} from '@ui/LightTooltip/LightTooltip';
import {useState, useEffect} from 'react';

export const FullscreenToggleButton = () => {
    const [isFullscreen, setIsFullscreen] = useState(false);

    const handleFullscreenToggle = () => {
        if (!isFullscreen) {
            openFullscreen();
        } else {
            closeFullscreen();
        }
    };

    const openFullscreen = () => {
        if (document.documentElement.requestFullscreen) {
            document.documentElement.requestFullscreen();
        } else if (document.documentElement.webkitRequestFullscreen) {
            // Safari
            document.documentElement.webkitRequestFullscreen();
        } else if (document.documentElement.msRequestFullscreen) {
            // IE11
            document.documentElement.msRequestFullscreen();
        }
    };

    const closeFullscreen = () => {
        if (document.exitFullscreen) {
            document.exitFullscreen();
        } else if (document.webkitExitFullscreen) {
            document.webkitExitFullscreen();
        } else if (document.msExitFullscreen) {
            document.msExitFullscreen();
        }
    };

    // Обновление состояния при изменении полноэкранного режима
    useEffect(() => {
        const handleFullscreenChange = () => {
            setIsFullscreen(!!document.fullscreenElement);
        };

        document.addEventListener('fullscreenchange', handleFullscreenChange);
        document.addEventListener('webkitfullscreenchange', handleFullscreenChange); // Safari
        document.addEventListener('msfullscreenchange', handleFullscreenChange); // IE11

        return () => {
            document.removeEventListener('fullscreenchange', handleFullscreenChange);
            document.removeEventListener('webkitfullscreenchange', handleFullscreenChange);
            document.removeEventListener('msfullscreenchange', handleFullscreenChange);
        };
    }, []);

    return (
        <LightTooltip title={isFullscreen ? 'Выйти из полноэкранного режима' : 'На весь экран'}>
            <IconButton onClick={handleFullscreenToggle}>
                {isFullscreen ? <FullscreenExitOutlined /> : <FullscreenOutlined />}
            </IconButton>
        </LightTooltip>
    );
};
