import {AutocompleteCloseReason} from '@mui/base';
import {useState} from 'react';

export const useKeepOpen = () => {
    const [open, setOpened] = useState<boolean>(false);
    const onFocus = () => setOpened(true);
    const onBlur = () => setOpened(false);
    const onClose = (_: React.SyntheticEvent, reason: AutocompleteCloseReason) => {
        if (reason !== 'selectOption' && reason !== 'removeOption') {
            setOpened(false);
        }
    };
    const onOpen = () => {
        setOpened(true);
    };
    return {
        open,
        onFocus,
        onBlur,
        onClose,
        onOpen,
    };
};
