import React, {CSSProperties} from 'react';

export type ReportLink = {
    name: string;
    type: string;
    className?: string;
    disabled?: boolean;
    withoutDate?: boolean;
    withComment?: boolean;
    withDateRange?: boolean;
    withCasingColumn?: boolean;
    wellConstructionStage?: boolean;
    switchableDataPeriod?: boolean;
};

export type NavLink = {
    name: string;
    link: string;
    Icon?: React.ReactNode;
    onClick?: () => void;
};

export enum UserActionType {
    Logout,
}

export type UserAction = {
    label: string;
    value: UserActionType;
};

export type UserAvatarProps = {
    style?: CSSProperties;
};

export type HistoryState = string[];
