import api from 'utils/api';

type NpvCasingColumns = {
    value: string;
}[];

type LoadCasingColumnsProps = {
    wellId?: string;
};
export const loadCasingColumns = ({wellId}: LoadCasingColumnsProps) => {
    return api.get<NpvCasingColumns>(`/npv/casing-columns`, {
        wellId: wellId,
    });
};
