import {TableSchemaService} from '../../services/Table';
import {setItems, setMenuLoading} from './menuSlice';
import {MenuItem, MenuItemType, ServerMenuItem} from '../../types/common';
import {MenuType} from 'constants/menu';
import {createAsyncThunk} from '@reduxjs/toolkit';

const service = TableSchemaService.getInstance();

export const getMenuItems = createAsyncThunk(
    'menu/getMenuItems',
    async (type: MenuType, {dispatch}) => {
        try {
            dispatch(setMenuLoading(true));
            const menu = await service.getMenu(type);

            const menuItems = mapServerMenuItemsToMenuItems(menu.subgroups);

            dispatch(setItems(menuItems));
        } catch (error) {
            console.error(error);
        } finally {
            dispatch(setMenuLoading(false));
        }
    },
);

export const mapServerMenuItemsToMenuItems = (items: ServerMenuItem[]): MenuItem[] => {
    return items.map((item) => ({
        key: getMenuItemKey(item),
        label: item.title,
        isGroup: item.type === MenuItemType.Group,
        isFiles: item.type === MenuItemType.Files,
        isCalendar:
            item.type === MenuItemType.CalendarOperations ||
            item.type === MenuItemType.LiquorActualCalendar,
        type: item.type,
        code: item.code,
        findAllUrl: (item.findAllUrl || '').replace('/api', ''),
        children: item.subgroups ? mapServerMenuItemsToMenuItems(item.subgroups) : [],
    }));
};

export const getMenuItemKey = (item: ServerMenuItem): string | string[] => {
    if (item.type === MenuItemType.Nested && item.subgroups) {
        return item.subgroups.flatMap((item) => getMenuItemKey(item));
    }
    return item.code;
};
