import {Box, Button, Stack, Typography} from '@mui/material';
import {getFormattedDuration} from 'utils';
import {IncidentDetailsDate} from './IncidentDetailsDate';
import KeyboardArrowRightOutlinedIcon from '@mui/icons-material/KeyboardArrowRightOutlined';
import {IncidentDetailsProps} from './IncidentInfo';
import {getIncidentValue} from '../utils';

export const IncidentInfoSmall = ({
    data,
    withDateLink = false,
    wellId,
    openTripModal,
}: IncidentDetailsProps) => {
    return (
        <Stack
            direction='row'
            gap='70px'
            width='100%'
        >
            <Stack
                direction='column'
                gap='20px'
                width='100%'
            >
                <Box
                    component='div'
                    sx={{
                        display: 'grid',
                        gridTemplateColumns: '1fr 1fr 1fr 1fr',
                        columnGap: '15px',
                        rowGap: '16px',
                    }}
                >
                    <Box
                        component='div'
                        style={{
                            padding: '8px',
                            borderRadius: '4px',
                            backgroundColor: 'rgba(211, 47, 47, 0.3)',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '40px',
                        }}
                    >
                        <Typography
                            variant='h3'
                            sx={{backgroundColor: 'unset !important'}}
                        >
                            {getFormattedDuration(data.duration * 60 * 60)}
                        </Typography>
                    </Box>

                    <Stack
                        direction='column'
                        gap='6px'
                        flexShrink={0}
                        width='132px'
                    >
                        <Typography
                            variant='caption'
                            color='var(--mui-palette-text-secondary)'
                        >
                            Дата начала
                        </Typography>
                        <IncidentDetailsDate
                            date={data.startTime}
                            withDateLink={withDateLink}
                            wellId={wellId}
                        />
                    </Stack>
                    <Stack
                        direction='column'
                        gap='6px'
                        flexShrink={0}
                        width='132px'
                    >
                        <Typography
                            variant='caption'
                            color='var(--mui-palette-text-secondary)'
                        >
                            Дата окончания
                        </Typography>
                        <IncidentDetailsDate
                            date={data.endTime}
                            withDateLink={withDateLink}
                            wellId={wellId}
                        />
                    </Stack>
                    <Stack
                        direction='column'
                        gap='6px'
                        flexShrink={0}
                    >
                        {data.run && (
                            <Button
                                onClick={() => {
                                    if (data.runId !== undefined) {
                                        openTripModal(data.runId);
                                    }
                                }}
                                size='small'
                                variant='contained'
                                color='inherit'
                                endIcon={<KeyboardArrowRightOutlinedIcon />}
                                sx={{
                                    fontSize: 16,
                                    fontWeight: 500,
                                    lineHeight: 1.6,
                                    textTransform: 'unset',
                                    paddingLeft: 0,
                                    '&.Mui-disabled': {
                                        color: 'var(--mui-palette-action)',
                                    },
                                    backgroundColor: 'var(--mui-palette-action-hover)',
                                    color: 'var(--mui-palette-text-primary)',
                                }}
                            >
                                Рейс {data.run}
                            </Button>
                        )}
                    </Stack>
                    <Stack
                        direction='column'
                        gap='6px'
                        flexShrink={0}
                        width='104px'
                    >
                        <Typography
                            variant='caption'
                            color='var(--mui-palette-text-secondary)'
                        >
                            Этап
                        </Typography>
                        <Typography variant='body1'>{data.wellConstructionStage}</Typography>
                    </Stack>
                    <Stack
                        direction='column'
                        gap='6px'
                        flexShrink={0}
                    >
                        <Typography
                            variant='caption'
                            color='var(--mui-palette-text-secondary)'
                        >
                            Ствол
                        </Typography>
                        <Typography variant='body1'>{data.wellbore}</Typography>
                    </Stack>
                    <Stack
                        direction='column'
                        gap='6px'
                        flexShrink={0}
                        sx={{width: '122px'}}
                    >
                        <Typography
                            variant='caption'
                            color='var(--mui-palette-text-secondary)'
                        >
                            Интервал, м
                        </Typography>
                        <Typography variant='body1'>
                            {data.startDepth !== null && <>от {data.startDepth}</>}
                            {data.endDepth !== null && <> до {data.endDepth}</>}
                        </Typography>
                    </Stack>
                    <Stack
                        direction='column'
                        gap='6px'
                    >
                        <Typography
                            variant='caption'
                            color='var(--mui-palette-text-secondary)'
                            sx={{
                                wordBreak: 'break-word',
                            }}
                        >
                            Вид инцидента
                        </Typography>
                        <Typography variant='body1'>{getIncidentValue(data.type)}</Typography>
                    </Stack>

                    <Stack
                        direction='column'
                        gap='6px'
                        sx={{
                            gridColumn: 'span 2',
                        }}
                    >
                        <Typography
                            variant='caption'
                            color='var(--mui-palette-text-secondary)'
                        >
                            Вид работ
                        </Typography>
                        <Typography variant='body1'>{data.workType}</Typography>
                    </Stack>
                    <Stack
                        direction='column'
                        gap='6px'
                        sx={{
                            gridColumn: 'span 2',
                        }}
                    >
                        <Typography
                            variant='caption'
                            color='var(--mui-palette-text-secondary)'
                        >
                            Обсадная колонна
                        </Typography>
                        <Typography variant='body1'>{data.casingColumn}</Typography>
                    </Stack>
                </Box>
            </Stack>
        </Stack>
    );
};
