import {Autocomplete, AutocompleteRenderOptionState, Checkbox, TextField} from '@mui/material';
import {useCallback, useEffect, useRef, useState} from 'react';
import {ColumnData} from '../types';
import {renderTags} from 'utils/functions/renderTags';
import {isEqual} from 'lodash';
import {useFilterOptions} from 'utils/hooks/useFilterOptions';

type HeaderFilterProps = {
    column: ColumnData;
    handleChangeFilterValue: (value: string[] | boolean[]) => void;
    selectedOptions: string[];
    setShowFilter: React.Dispatch<React.SetStateAction<boolean>>;
    filterRequestData?: Record<string, any>;
};

export const HeaderFilter = ({
    column,
    handleChangeFilterValue,
    selectedOptions,
    setShowFilter,
    filterRequestData,
}: HeaderFilterProps) => {
    const [value, setValue] = useState<(string | boolean)[]>(selectedOptions);
    const componentRef = useRef<HTMLDivElement>(null);

    const changeValue = useCallback(
        (newValue: (string | boolean)[]) => {
            const formattedValue = newValue.map((val) =>
                typeof val === 'boolean' ? String(val) : val,
            );
            // Если значение не изменилось, то закрываем фильтр, но не инициируем запрос
            if (isEqual(formattedValue, selectedOptions)) {
                setShowFilter(false);
                return;
            }
            handleChangeFilterValue(formattedValue);
        },
        [selectedOptions, setShowFilter, handleChangeFilterValue],
    );

    useEffect(() => {
        // Функция для обработки кликов, так как автокомплит по умолчанию должен быть открыт, и закрываться при клике вне его
        // А при изначально open = true функция закрытия не отрабатывает корректно (из-за логики внутри самого Autocomplete)
        function handleClickOutside(event: any) {
            // НЕ закрывает фильтр при кликах на инпут или элементы списка выбора, а также на иконку CloseIcon (очистка инпута)
            if (
                event.target.tagName.toLowerCase() === 'input' ||
                event.target.tagName.toLowerCase() === 'li'
            ) {
                return;
            }
            const isSvg = event.target.tagName === 'svg' || event.target.tagName === 'path';
            if (isSvg) {
                const svgParentId = event.target.closest('svg').getAttribute('data-testid');
                if (!!svgParentId && svgParentId === 'CloseIcon') {
                    return;
                }
            }
            // Закрываем фильтр при клике вне контейнера фильтра
            changeValue(value);
        }

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    const {data} = useFilterOptions({
        baseEntityId: filterRequestData?.baseEntityId,
        entityName: filterRequestData?.additionalProperties.entityName,
        fieldName: column.id,
    });
    const options = (data || []).map((el) => ({
        label: el.value === true ? 'Да' : el.value === false ? 'Нет' : el.value,
        value: el.value,
    }));

    const onClose = (_event: React.SyntheticEvent, reason: string) => {
        if (reason === 'blur' || reason === 'escape') {
            changeValue(value);
        }
    };
    const renderOption = (
        props: React.HTMLAttributes<HTMLLIElement>,
        option: {label: string; value: string | boolean},
        state: AutocompleteRenderOptionState,
    ) => (
        <li
            {...props}
            style={{padding: '4px', hyphens: 'auto', lineHeight: '115%', wordBreak: 'break-word'}}
        >
            <Checkbox checked={state.selected} />
            {option.label}
        </li>
    );

    useEffect(() => {
        setValue(selectedOptions);
    }, [selectedOptions]);

    return (
        <div ref={componentRef}>
            <Autocomplete
                fullWidth
                open
                clearText='Очистить'
                sx={{minWidth: column?.width ? column?.width - 12 : 155}}
                onClose={onClose}
                renderOption={renderOption}
                renderTags={renderTags}
                options={options}
                value={options.filter((opt) =>
                    value.some((val) =>
                        typeof val === 'boolean' || val === 'false' || val === 'true'
                            ? String(val) === String(opt.value)
                            : val === opt.value,
                    ),
                )}
                multiple
                disableCloseOnSelect
                onChange={(_event, newValue) => {
                    setValue(newValue.map((option) => option.value));
                }}
                getOptionLabel={(option) => option.label}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        variant='standard'
                        label={column?.title}
                        size='small'
                        InputLabelProps={{
                            sx: {
                                paddingRight: '30px',
                            },
                        }}
                    />
                )}
            />
        </div>
    );
};
