import React, {useEffect} from 'react';
import {ChartFilterProps} from './types';
import ChartModalFilter from './ChartModalFilter';
import ChartAutocomplete from './ChartAutocomplete';

const ChartFilter = ({filter, onFilterChange}: ChartFilterProps) => {
    const [value, setValue] = React.useState<string[]>(filter.defaultValues);
    const [openAutocomplete, setOpenAutocomplete] = React.useState(false);
    const [openModal, setOpenModal] = React.useState(false);

    const {code, possibleValues} = filter;

    useEffect(() => {
        // Отпавлять запрос не на каждый выбор пользовтеля, а после закрытия дропдауна
        if (!openAutocomplete && !openModal) {
            onFilterChange(code, value);
        }
    }, [code, onFilterChange, value, openAutocomplete, openModal]);

    const onOpenAutocomplete = () => {
        if (possibleValues.length > 10 && !filter.isSingleChoice) {
            setOpenModal(true);
            return;
        }
        setOpenAutocomplete(true);
    };

    return (
        <>
            <ChartAutocomplete
                isSingleChoice={filter.isSingleChoice}
                filter={filter}
                open={openAutocomplete}
                onOpen={onOpenAutocomplete}
                setOpen={setOpenAutocomplete}
                value={value}
                setValue={setValue}
            />
            <ChartModalFilter
                open={openModal}
                filter={filter}
                setOpenModal={setOpenModal}
                setValue={setValue}
                value={value}
            />
        </>
    );
};

export default ChartFilter;
