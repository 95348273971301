import {useQuery} from '@tanstack/react-query';
import {useCallback} from 'react';
import api from 'utils/api';

export enum Permission {
    Projects = 'CAN_PROJECTS_VIEW',
    Wells = 'CAN_WELLS_VIEW',
    canEditWells = 'CAN_WELLS_EDIT',
    canEditPads = 'CAN_WELL_PADS_EDIT',
    canEditFields = 'CAN_WELL_FIELDS_EDIT',
    Prices = 'CAN_PRICES_VIEW',
    CommonInfo = 'CAN_COMMON_INFO_EDIT',
    CanViewIncidentAnalyse = 'CAN_INCIDENT_ANALYSE_VIEW',
    CanViewAutoDrilling = 'CAN_AUTO_DRILLING_EDIT',
    CAN_VIEW_MONITORING = 'CAN_MONITORING_VIEW',
    CAN_VIEW_DRILLING_RIG = 'CAN_DRILLING_RIG_EDIT',
    CAN_VIEW_WELL_HEADER = 'CAN_WELL_HEADER_VIEW',
    CAN_VIEW_ASB_LOGO = 'CAN_ASB_LOGO_VIEW',
    CAN_VIEW_DRILLING_CARPET = 'CAN_DRILLING_CARPET_EDIT',
    CAN_VIEW_KNBK_IN_EXTERNAL_WELL = 'CAN_KNBK_IN_EXTERNAL_WELL_VIEW',
    CAN_VIEW_DRILLING_EFFICIENCY = 'CAN_DRILLING_EFFICIENCY_VIEW',
    CAN_VIEW_LAS_TAB = 'CAN_LAS_TAB_VIEW',
    CAN_VIEW_TND_TAB = 'CAN_TND_TAB_VIEW',
    CAN_VIEW_MONITORING_CONNECTION = 'CAN_MONITORING_CONNECTION_VIEW',
    CAN_VIEW_ADMIN_PANEL = 'CAN_ADMIN_PANEL_VIEW',
}

export const usePermissions = () => {
    const getPermissions: () => Promise<Permission[]> = useCallback(() => {
        return api.get(`/me/permissions`);
    }, []);
    const {data: roles, isLoading} = useQuery({
        queryKey: ['permissions'],
        queryFn: getPermissions,
        staleTime: 8 * (60 * 1000), // 8 mins
        gcTime: 10 * (60 * 1000), // 10 mins
    });

    return {roles, isLoading};
};
