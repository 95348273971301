import {FilterListOff} from '@mui/icons-material';
import {IconButton, Stack, TableCell, TableRow, Typography} from '@mui/material';
import {LightTooltip} from '@ui/LightTooltip/LightTooltip';
import {FilterConfig} from '../types';
import styles from '../NewTable.module.scss';
type FiltersRowProps = {
    totalElements: number;
    setFilterConfig?: React.Dispatch<React.SetStateAction<FilterConfig>>;
};

export const FiltersRow = ({totalElements, setFilterConfig}: FiltersRowProps) => {
    return (
        <TableRow
            sx={{
                minHeight: '40px',
            }}
        >
            <TableCell
                colSpan={3}
                sx={{padding: '4px 8px', borderBottom: 0}}
                className={styles.sticky}
            >
                <Stack
                    direction='row'
                    width='220px'
                    gap={1}
                    alignItems='center'
                >
                    <Typography
                        sx={{fontSize: '14px'}}
                        color='text.primary'
                    >
                        Всего найдено:{' '}
                        <span
                            style={{
                                color: 'var(--mui-palette-primary-main)',
                            }}
                        >
                            {totalElements}
                        </span>
                    </Typography>
                    <LightTooltip title='Сбросить фильтры'>
                        <IconButton
                            size='small'
                            onClick={() => setFilterConfig?.({})}
                        >
                            <FilterListOff fontSize='inherit' />
                        </IconButton>
                    </LightTooltip>
                </Stack>
            </TableCell>
        </TableRow>
    );
};
