import {Autocomplete, FilterOptionsState, createFilterOptions} from '@mui/material';
import {GetFormFieldProps} from '../types';
import {AutocompleteRenderInput} from './AutocompleteRenderInput';
import {useTableAutocomplete} from './utils';

type TableAutocompleteProps = Omit<GetFormFieldProps, 'CustomComponent' | 'customProps'>;

const filter = createFilterOptions<string>();

export const TableAutocomplete = ({
    valueKey,
    disabled,
    dictionary,
    setValue,
    required,
    values,
    column,
}: TableAutocompleteProps) => {
    const value = values[valueKey];

    const {finalOptions, getOptionLabel} = useTableAutocomplete({column, dictionary, values});

    const filterSelectOptions = (options: string[], params: FilterOptionsState<string>) => {
        const filtered = filter(options, params);

        if (params.inputValue !== '' && !filtered.includes(params.inputValue)) {
            filtered.push(params.inputValue);
        }

        return filtered;
    };

    const handleChangeValue = (newValue: string | null) => {
        if (!newValue) {
            setValue(valueKey, null);
            return;
        }
        setValue(valueKey, newValue);
        const currentValueOption = dictionary?.find((el) => el.value === newValue);
        // Если есть атрибут keyTableAttr, то в поле keyTableAttr устанавливаем значение по ключу key Для данного элемента
        if (column?.keyTableAttr && currentValueOption?.key) {
            setValue(column.keyTableAttr, currentValueOption?.key);
        }
    };

    const additionalProps = column?.dictIsChangeable ? {filterOptions: filterSelectOptions} : {};

    return (
        <Autocomplete
            fullWidth
            disabled={disabled}
            getOptionLabel={getOptionLabel}
            options={finalOptions}
            isOptionEqualToValue={(option, value) => {
                return String(option) === String(value);
            }}
            value={value ? String(value) : null}
            sx={{minWidth: '150px'}}
            onChange={(_event: any, newValue: string | null) => {
                handleChangeValue(newValue);
            }}
            renderInput={(params) => (
                <AutocompleteRenderInput
                    params={params}
                    value={value}
                    required={required}
                />
            )}
            {...additionalProps}
        />
    );
};
