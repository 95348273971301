import React, {useCallback, useMemo, useState} from 'react';
import {Box, CardContent, Pagination} from '@mui/material';
import {GridSortModel} from '@mui/x-data-grid';
import {useElementSize} from '../../../../utils';
import {getColumns} from '../utils/utils';
import {TimeBalanceTableProps} from '../types';
import {useTimeBalanceTable} from '../utils/useTimeBalanceTable';

import TimeBalanceTableContent from './TimeBalanceTableContent';

const TimeBalanceTable = ({onRowClick}: TimeBalanceTableProps) => {
    const [sort, setSort] = useState<GridSortModel | undefined>();
    const [page, setPage] = useState(1);
    const [ref, {width}] = useElementSize();
    const [data] = useTimeBalanceTable(page, sort);

    const columns = useMemo(() => getColumns(width), [width]);

    const onSortChangeHandle = useCallback((model: GridSortModel) => {
        setSort(model);
    }, []);

    const onPageChangeHandle = useCallback((event: React.ChangeEvent<unknown>, newPage: number) => {
        setPage(newPage);
    }, []);

    return (
        <Box sx={{flex: 1}} component="div">
            <CardContent
                sx={{width: 1, display: 'flex', flexDirection: 'column', height: '100%'}}
                ref={ref}
            >
                <TimeBalanceTableContent
                    data={data?.content}
                    columns={columns}
                    sort={sort}
                    onRowClick={onRowClick}
                    onSortChange={onSortChangeHandle}
                />
                <Pagination
                    onChange={onPageChangeHandle}
                    page={page}
                    count={data?.page?.totalPages || 0}
                    color='primary'
                    size='small'
                    sx={{
                        paddingTop: 2,
                        display: 'flex',
                        justifyContent: 'flex-end',
                    }}
                />
            </CardContent>
        </Box>
    );
};

export default React.memo(TimeBalanceTable);
