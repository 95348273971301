import {Row} from '@ui/NewTable/types';
import {DropResult} from 'react-beautiful-dnd';

export const reorderItems = (rows: Row[], result: DropResult) => {
    // находим элемент, который хотим перетащить
    const item = rows.find((i) => i?.id?.toString() === result.draggableId);
    // Если элемент и result.destination существует
    if (result.destination && item) {
        // Создаем массив id
        const itemsIds = rows.map((el) => el.id);
        // Удаляем текущий элемент из массива
        const [removed] = itemsIds.splice(result.source.index, 1);
        // И вставляем его в новое место
        itemsIds.splice(result.destination.index, 0, removed);

        return itemsIds;
    }
};
