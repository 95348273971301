import {getUrlForMenuItem} from 'components/Layout/Menu/utils';
import {useMemo} from 'react';
import {useSelector} from 'react-redux';
import {useLocation, useParams, useSearchParams} from 'react-router-dom';
import {selectMenu} from 'slices';
import {MenuItem, MenuItemType, RouteParams} from 'utils';

export const useTabs = () => {
    const {items: menuItems} = useSelector(selectMenu);
    const [searchParams] = useSearchParams();
    const {wellId, pageId, id, groupId} = useParams<RouteParams>();

    const location = useLocation();

    const allItemKeys = useMemo(
        () =>
            menuItems.reduce((acc, item) => {
                if (!item.children) return acc;

                for (const child of item.children) {
                    if (child.type === 'FILES') {
                        acc.set(`files/${child.key}`, item);
                    } else {
                        if (Array.isArray(child.key)) {
                            child.key.map((key) => acc.set(key, item));
                        } else {
                            acc.set(child.key, item);
                        }
                    }
                }

                return acc;
            }, new Map<string | string[], MenuItem>()),
        [menuItems],
    );

    const matchingParentItem = useMemo(() => {
        if (!pageId) {
            return null;
        }
        if (pageId === 'files' && id) {
            return allItemKeys.get(`${pageId}/${id}`);
        }
        // Для КНБК не должны отображаться табы, когда мы находимся на странице элементов с groupId
        if (groupId) {
            return null;
        }
        // Для страницы ГГД таблицы не должны отображаться табы
        if (pageId === 'ggd' && searchParams.get('id')) {
            return null;
        }
        return allItemKeys.get(pageId);
    }, [pageId, allItemKeys, id, groupId, searchParams]);

    const tabs = useMemo(
        () =>
            matchingParentItem?.children?.map((child) => {
                const isNested = child.type === MenuItemType.Nested;

                return {
                    key: child.key,
                    label: child.label,
                    url: getUrlForMenuItem(child, wellId ?? ''),
                    isNested: isNested,
                    children: isNested ? child.children : undefined,
                };
            }) ?? [],
        [matchingParentItem, wellId],
    );

    const nestedMenuItems = tabs.filter((tab) => tab.isNested);
    const currentNestedTab = nestedMenuItems.find((item) => {
        return item.url.includes(location.pathname);
    });

    return {tabs, currentValue: location.pathname, currentNestedTab};
};
