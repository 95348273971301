import {WaterfallChartProps} from '../types';
import {WaterfallConfig} from '@ant-design/plots';
import {Annotation} from '@antv/g2plot';
import {SupportedColorScheme} from '@mui/material';
import {
    computeAxisLineColor,
    computeStrokeOpacity,
    computeTextColor,
    splitStringToLines,
} from './common';

export const getWaterfallConfig = (
    colorScheme: SupportedColorScheme | undefined,
    data: WaterfallChartProps,
): Omit<WaterfallConfig, 'data'> => {
    const annotations: Annotation[] = [];

    const total = data.reduce((acc, d) => {
        if (d.title === 'План') {
            return acc + d.value;
        }

        const percentDeviation = ((d.value / acc) * 100).toFixed(1);

        annotations.push({
            type: 'text',
            position: () => {
                const y = acc + d.value;
                return [d.title, y];
            },
            offsetY: d.value > 0 ? -12 : 12,
            content:
                Number(percentDeviation) > 0 ? `+${percentDeviation} %` : `${percentDeviation} %`,
            style: {
                fontSize: 10,
                fill: computeTextColor(colorScheme),
                lineWidth: 1,
                textAlign: 'center',
                verticalAlign: 'middle',
            },
        });

        return acc + d.value;
    }, 0);

    const dataWithTotal = [...data, {title: 'Факт', value: total}];

    const maxValue = Math.max(...dataWithTotal.map((d) => d.value));

    const config: Omit<WaterfallConfig, 'data'> = {
        padding: 'auto',
        appendPadding: [20, 0, 0, 0],
        xField: 'title',
        yField: 'value',

        total: {
            label: 'Факт',
        },

        xAxis: {
            label: {
                autoHide: false,
                autoRotate: false,

                formatter: (text: string) => {
                    return splitStringToLines(text, 10);
                },
            },
        },

        yAxis: {
            min: 0,
            max: maxValue * 1.1,
            grid: {
                line: {
                    style: {
                        lineWidth: 1,
                        strokeOpacity: computeStrokeOpacity(colorScheme),
                        stroke: computeAxisLineColor(colorScheme),
                        lineDash: null,
                    },
                },
            },
        },

        color: ({title, value}) => {
            if (title === 'План') {
                return colorScheme === 'light'
                    ? 'rgba(218, 218, 218)'
                    : 'rgba(255, 255, 255, 0.12)';
            }

            if (title === 'Факт') {
                return 'rgba(41, 99, 197, 1)';
            }

            return value < 0 ? '#4DB6AC' : '#E04134';
        },
        legend: false,

        label: {
            position: 'top',
            style: {
                fontSize: 10,
                fill: computeTextColor(colorScheme),
            },

            formatter: (item) => {
                return `${item.value.toFixed(2)}`;
            },

            layout: [
                {
                    type: 'interval-adjust-position',
                },
            ],
        },
        waterfallStyle: () => {
            return {
                fillOpacity: 0.85,
            };
        },

        tooltip: false,

        annotations: annotations,
    };

    return config;
};
