import {configureStore} from '@reduxjs/toolkit';
import {RootState} from '../types/common';
import menuItemsReducer from './menu/menuSlice';
import dictionariesReducer from './dictionariesSlice';
import wellNumberReducer from './wellNumberSlice';
import notificationsReducer from './notifications/notificationsSlice';
import componentsReducer, {componentsReducerName} from './components/componentsSlice';
import {knbkApi} from './knbk';
export const selectMenu = (store: RootState) => store.menu;
export const selectDictionaries = (store: RootState) => store.dictionaries.data;
export const selectWellNumber = (store: RootState) => store.wellNumber.data || '';
export const selectNotification = (store: RootState) => store.notifications.data;

const store = configureStore({
    reducer: {
        menu: menuItemsReducer,
        dictionaries: dictionariesReducer,
        wellNumber: wellNumberReducer,
        [componentsReducerName]: componentsReducer,
        [knbkApi.reducerPath]: knbkApi.reducer,
        notifications: notificationsReducer,
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(knbkApi.middleware),
});

export type AppState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
