import {
    ChartCode,
    ChartItem,
    ChartProps,
    ChartType,
    ColumnChartProps,
    LineChartItem,
    LineChartProps,
    PieChartItem,
} from './types';

import {splitStringToLines} from './configurations/common';

export const chartHasData = (chart: ChartItem, values: ChartProps): boolean => {
    if (chart.type === ChartType.Line) {
        return (values as LineChartProps).some((line) => Boolean(line.values.length));
    }

    return Boolean(values.length);
};

export const checkShowEmptyValue = (chart: ChartItem, chartHasData: boolean) => {
    if (!chartHasData && chart.code === ChartCode.DRILLING_CHEMICAL_REAGENTS_CONSUMPTIONS) {
        return 'Выберите Хим.реагенты и Обсадную колонну для отображения диаграммы';
    }
    if (!chartHasData && chart.code === ChartCode.MTR_CONSUMPTIONS) {
        return 'Выберите Наименование МТР и Обсадную колонну для отображения диаграммы';
    }
    return 'Нет данных';
};

const isPieChart = (chart: ChartProps): chart is PieChartItem[] => {
    return Array.isArray(chart) && chart.every((item) => 'name' in item && 'value' in item);
};

export const getChartData = (chart: ChartItem, props: ChartProps) => {
    if (chart.type === ChartType.Line) {
        const data: LineChartItem[] = [];

        (props as LineChartProps).forEach((line) => {
            const chartItems = getLineChartData(line?.values, line?.title);

            data.push(...chartItems);
        });

        return data;
    }

    if (
        chart.code === ChartCode.KNBK_PARAMETERS_REFERENCE ||
        chart.code === ChartCode.KNBK_SINKING_REFERENCE
    ) {
        return (props as ColumnChartProps).map((row) => {
            return {
                ...row,
                // Так как нам надо подстроить высоту названия оси под соседний график
                x: row.x.replace(/(Cкв\.)/, '\n$1'),
            };
        });
    }

    if (chart.type === ChartType.Column || chart.type === ChartType.Bar) {
        return (props as ColumnChartProps).map((row) => {
            return {
                ...row,
                x: splitStringToLines(row.x),
            };
        });
    }

    // Need to filter pie data in order to avoid label with 0
    if (chart.code === ChartCode.WELL_OPERATIONS_PV_NPV) {
        if (isPieChart(props)) {
            return props.filter((i) => i.value !== 0);
        }
    }

    return props;
};

export const getLineChartData = (data: LineChartItem[], title: string): LineChartItem[] => {
    return data.map((item) => ({...item, type: title, initialX: item.x, x: item.x}));
};

export const getChartSize = (chart: ChartItem) => {
    const customWidth: Record<string, number> = {
        // ChartCode.Параметры бурения: 12,
    };

    const customHeight: Record<string, string> = {
        [ChartCode.DRILLING_CHEMICAL_REAGENTS_CONSUMPTIONS]: '890px',
        [ChartCode.FASTENING_CHEMICAL_REAGENTS_CONSUMPTIONS]: '890px',
        [ChartCode.DEVELOPMENT_CHEMICAL_REAGENTS_CONSUMPTIONS]: '890px',
        [ChartCode.MTR_CONSUMPTIONS]: '890px',
    };
    return {
        width: customWidth[chart.code] || 6,
        height: customHeight[chart.code] || '420px',
    };
};
