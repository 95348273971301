import {ChartCode, ChartItem, PieChartProps} from '../types';
import {PieConfig} from '@ant-design/plots';
import {SupportedColorScheme} from '@mui/material';
import {colors} from '@features/incident/constants';
import {costByServiceColors} from '@features/Cost/constants';
import {computeFill} from './common';

export const getPieConfig = (
    {code}: ChartItem,
    colorScheme: SupportedColorScheme | undefined,
    isFullscreen?: boolean,
    values?: PieChartProps,
): Omit<PieConfig, 'data'> => {
    const config = {
        angleField: 'value',
        colorField: 'name',
        label: {
            type: 'inner',
            // @ts-ignore
            content: (item) => item.y,
            style: {
                fill: computeFill(colorScheme),
            },
        },
        interactions: [
            {
                type: 'element-active',
            },
        ],
        legend: {
            itemName: {
                style: {
                    fill: computeFill(colorScheme),
                },
            },
        },
    };

    // Для графика с кодом WELL_OPERATIONS_PV_NPV задаем свои цвета
    if (code === ChartCode.WELL_OPERATIONS_PV_NPV) {
        // @ts-ignore
        config.color = (d: {name: string}) => {
            const {name} = d;

            if (name.includes('НПВ')) return colorScheme === 'light' ? '#D32F2F ' : '#D32F2F';
            if (name.includes('ПВ')) return colorScheme === 'light' ? '#4CAF50' : '#388E3C';
            if (name.includes('ПНВ')) return colorScheme === 'light' ? '#F57C00' : '#EF6C00';
            return colors[3];
        };
        config.label = {
            type: 'outer',
            labelHeight: 50,
            offset: 18,
            content: (item: {name: string; percent: number; value: number}) =>
                `${item.name} ${item.value.toFixed(1)} (${(item.percent * 100).toFixed(1)} %)`,
            style: {
                fill: computeFill(colorScheme),
                // @ts-ignore
                fontSize: isFullscreen ? 18 : 12,
            },
            labelLine: {
                style: {
                    stroke: computeStrokeColor(colorScheme),
                },
            },
        };
        // @ts-ignore
        config.legend = false;
    }

    if (code === ChartCode.COST_BY_SERVICE) {
        const colorMap = values?.reduce((acc: Record<string, string>, item, index) => {
            acc[item.name] = costByServiceColors[index % costByServiceColors.length];
            return acc;
        }, {});

        // @ts-ignore
        config.color = (d: {name: string}) => {
            const {name} = d;
            return colorMap?.[name] || costByServiceColors[0];
        };

        config.label = {
            type: 'outer',
            labelHeight: 20,
            offset: 18,
            content: (item: {name: string; percent: number; value: number}) =>
                `${(item.percent * 100).toFixed(1)}%, ${item.name}`,
            style: {
                fill: computeFill(colorScheme),
                // @ts-ignore
                fontSize: isFullscreen ? 18 : 12,
            },
            labelLine: {
                style: {
                    stroke: computeStrokeColor(colorScheme),
                },
            },
        };

        // @ts-ignore
        config.tooltip = {
            formatter: (item: {name: string; value: number; percent: number}) => {
                return {
                    name: item.name,
                    value: `${item.value.toFixed(3)} млн. руб`,
                };
            },
        };

        // @ts-ignore
        config.legend = false;

        // @ts-ignore
        config.radius = 0.7;

        // @ts-ignore
        config.statistic = {
            title: false,
            content: '',
        };

        // @ts-ignore
        config.innerRadius = 0.45;

        // @ts-ignore
        config.pieStyle = {
            stroke: colorScheme === 'light' ? '#ffffff' : '#2A2A2A',
            strokeWidth: 10,
        };
    }

    return config;
};

const computeStrokeColor = (colorScheme: SupportedColorScheme | undefined): string => {
    if (colorScheme === 'dark') return '#5E5E5E';
    return '#666666';
};
