import React, {PropsWithChildren} from 'react';
import {useAuth} from './useAuth';

import {Loader} from '@ui/Loader/Loader';

const AuthWrapper = ({children}: PropsWithChildren) => {
    const isLogin = useAuth();

    if (!isLogin) {
        return <Loader />;
    }

    return children;
};

export default AuthWrapper;
