import {Chip, IconButton, Stack} from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';

type CustomTagsProps = {
    dataLength: number;
    onClear: () => void;
};
export const CustomTags = ({dataLength, onClear}: CustomTagsProps) => {
    return (
        <Chip
            size='small'
            variant='outlined'
            label={
                dataLength !== 0 && (
                    <Stack
                        direction='row'
                        alignItems='center'
                    >
                        {dataLength}
                        <IconButton
                            size='small'
                            onClick={onClear}
                            sx={{
                                height: '28px',
                            }}
                        >
                            <CancelIcon fontSize='small' />
                        </IconButton>
                    </Stack>
                )
            }
            sx={{
                height: 'auto',
                marginRight: '0',
                borderWidth: '0',
            }}
        />
    );
};
