import {accordionStyles} from '@features/incident/listPopups/utils';

export const SX_ACCORDION = {
    ...accordionStyles,
    borderBottomLeftRadius: '0!important',
    borderTopLeftRadius: '0!important',
    '&.Mui-expanded': {
        marginBottom: 0,
        marginTop: 0,
    },
};

export const SX_ACCORDION_SUMMARY = {
    ...SX_ACCORDION,
    minHeight: 0,
    '& .MuiAccordionSummary-content': {
        margin: 0,
        marginTop: 0,
    },
};