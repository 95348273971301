import {Alert as MuiAlert, AlertColor, AlertTitle} from '@mui/material';

const getTitle = (variant: AlertColor) => {
    switch (variant) {
        case 'error':
            return 'Ошибка';
        case 'success':
            return 'Успешно';
        case 'warning':
            return 'Предупреждение';
        default:
            return '';
    }
};

type AlertProps = {
    severity: AlertColor;
    text: string;
    onClose: () => void;
    title?: string;
};

export const Alert = ({severity, text, onClose, title}: AlertProps) => {
    const finalTitle = title || getTitle(severity);

    return (
        <MuiAlert
            severity={severity}
            onClose={onClose}
            sx={{width: '100%'}}
            style={{whiteSpace: 'pre-wrap'}}
        >
            {finalTitle && <AlertTitle>{finalTitle}</AlertTitle>}
            {text}
        </MuiAlert>
    );
};
