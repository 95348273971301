import {AccountDataWithPassword} from '../types';

export const createTxtContent = (data: AccountDataWithPassword): string => {
    return `Данные пользователя:
ID: ${data.id}
Имя пользователя: ${data.username}
Пароль: ${data.password}
Организация: ${data.organization}
Телефон: ${data.phoneNumber}
Электронная почта: ${data.email}
    `;
};
