import {useTheme} from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

export type DeviceInfo = Record<string, boolean>;

export const useDeviceInfo = (): DeviceInfo => {
    const theme = useTheme();
    const isTablet = useMediaQuery(theme.breakpoints.down('xl'));
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    return {
        isTablet,
        isMobile,
    };
};
