import {ReactNode} from 'react';
import {Routes} from '../../constants/routes';

export const makeSideMenuItem = (label: string, route: string, icon: ReactNode) => ({
    label,
    icon,
    route: `${Routes.Administration}${route}`,
});

export const makeAdminRoutes = (path: Routes, element: ReactNode) => ({
    key: path,
    path: `${Routes.Administration}${path}`,
    element,
});
