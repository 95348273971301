import {
    red,
    pink,
    purple,
    deepPurple,
    indigo,
    blue,
    lightBlue,
    cyan,
    teal,
    green,
    lightGreen,
    lime,
    yellow,
    amber,
    orange,
    deepOrange,
    brown,
    grey,
} from '@mui/material/colors';

export const colorsList = [
    red,
    deepPurple,
    lightBlue,
    green,
    yellow,
    deepOrange,
    pink,
    indigo,
    cyan,
    lightGreen,
    amber,
    brown,
    purple,
    blue,
    teal,
    lime,
    orange,
    grey,
];
