import {Box, Card, Paper, Stack} from '@mui/material';
import DeleteFilledIcon from '@mui/icons-material/Delete';
import {FileCell} from 'features/files/FileCell';
import {ButtonWithConfirm} from 'components/ButtonWithConfirm/ButtonWithConfirm';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import {FileEntity} from 'pages/FilesPage';
import {useCallback} from 'react';

type FileCardProps = {
    file: FileEntity;
    onDelete?: (fileId: string) => Promise<void>;
    width?: string;
};
export const FileCard: React.FC<FileCardProps> = ({file, onDelete, width = 'calc(50% - 10px)'}) => {
    const handleDelete = useCallback(() => {
        onDelete?.(file.id);
    }, [file.id, onDelete]);
    return (
        <Card sx={{width}}>
            <Paper
                elevation={0}
                sx={{height: '100%', padding: '12px 16px'}}
            >
                <Stack
                    direction='row'
                    alignItems='center'
                    justifyContent='space-between'
                >
                    <Stack
                        direction='row'
                        alignItems='center'
                        gap={1}
                    >
                        <InsertDriveFileOutlinedIcon color='primary' />
                        <Box component='div'>
                            <FileCell record={file} />
                        </Box>
                    </Stack>
                    {Boolean(onDelete) && (
                        <ButtonWithConfirm
                            icon={
                                <DeleteFilledIcon
                                    color='action'
                                    sx={{opacity: '.56'}}
                                />
                            }
                            onConfirm={handleDelete}
                            title={`Удалить файл ${file.originalFilename}`}
                        />
                    )}
                </Stack>
            </Paper>
        </Card>
    );
};
