import {DocumentStatus, NpvFileType, NpvStatus} from './types';

export const npvFileTypeNames: {[key in NpvFileType]: string} = {
    [NpvFileType.ACT_INCIDENT_START]: 'Акт о начале инцидента',
    [NpvFileType.ACT_NPV]: 'Акт',
    [NpvFileType.ACT_TECHNICAL_INVISTIGATION]: 'Акт технического расследования',
    [NpvFileType.ACT_MTR_CONSUMPTION]: 'Акты на расход МТР',
    [NpvFileType.PHOTO]: 'Фотографии',
    [NpvFileType.OTHER]: 'Другое',
};

export const npvStatusNames: {[key in NpvStatus]: string} = {
    [NpvStatus.OPEN]: 'Открыт',
    [NpvStatus.CLOSE]: 'Закрыт',
};

export const documentStatusNames: {[key in DocumentStatus]: string} = {
    [DocumentStatus.WITHOUT_ACT]: 'Без акта',
    [DocumentStatus.WITHOUT_DOC]: 'Документы отсутствуют',
    [DocumentStatus.WITH_DOC]: 'Документы приложены',
};

export const colors = [
    'rgb(245, 124, 0)',
    'rgb(124, 77, 255)',
    'rgb(30, 136, 229)',
    'rgb(89, 115, 247)',
    'rgb(0, 200, 83)',
    'rgb(0, 184, 212)',
    'rgb(255, 213, 79)',
    'rgb(255, 87, 34)',
    'rgb(68, 138, 255)',
    'rgb(240, 98, 146)',
    'rgb(130, 177, 255)',
    'rgb(171, 71, 188)',
    'rgb(132, 255, 255)',
    'rgb(105, 240, 174)',
    'rgb(2, 119, 189)',
    'rgb(205, 220, 57)',
    'rgb(0, 150, 136)',
    'rgb(103, 58, 183)',
    'rgb(255, 158, 128)',
    'rgb(170, 0, 255)',
    'rgb(77, 182, 172)',
    'rgb(176, 190, 197)',
    'rgb(149, 117, 205)',
    'rgb(41, 98, 255)',
    'rgb(74, 20, 140)',
    'rgb(56, 142, 60)',
    'rgb(13, 71, 161)',
    'rgb(191, 54, 12)',
    'rgb(249, 168, 37)',
    'rgb(97, 97, 97)',
    'rgb(0, 96, 100)',
    'rgb(0, 87, 178)',
    'rgb(102, 187, 106)',
    'rgb(5, 255, 210 )',
    'rgb(41, 182, 246)',
    'rgb(98, 0, 234)',
    'rgb(255, 177, 98)',
    'rgb(0, 149, 172)',
    'rgb(255, 236, 179)',
    'rgb(216, 27, 96)',
    'rgb(253, 121, 79)',
    'rgb(156, 39, 176)',
    'rgb(92, 107, 192)',
    'rgb(77, 208, 225)',
    'rgb(46, 9, 147)',
    'rgb(165, 214, 167)',
    'rgb(209, 196, 233)',
    'rgb(254, 205, 210)',
    'rgb(185, 246, 202)',
    'rgb(136, 14, 79)',
    'rgb(255, 196, 0)',
    'rgb(106, 27, 154)',
    'rgb(238, 255, 65)',
];

export const npvStatusVariant: {[key in NpvStatus]: any} = {
    [NpvStatus.OPEN]: 'warning',
    [NpvStatus.CLOSE]: 'success',
};

export const npvStatusColor: {[key in NpvStatus]: any} = {
    [NpvStatus.OPEN]: 'var(--mui-palette-warning-main)',
    [NpvStatus.CLOSE]: 'var(--mui-palette-success-main)',
};
