import {Chip, IconButton, Stack, Typography} from '@mui/material';
import React from 'react';
import AccordionSummary from '@mui/material/AccordionSummary';
import styles from '../styles.module.css';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {colors} from '../../constants';

type CommonAccordionSummaryProps = {
    idx: number;
    summary: {
        durationSumPercent: number;
        durationSumDays: number;
        title: string;
    };
};
export const CommonAccordionSummary: React.FC<CommonAccordionSummaryProps> = ({idx, summary}) => (
    <AccordionSummary
        expandIcon={
            <IconButton size='small'>
                <ExpandMoreIcon fontSize='small' />
            </IconButton>
        }
        sx={{
            '& .MuiAccordionSummary-content': {
                margin: 0,
            },
            borderLeft: `5px solid ${colors[idx]}`,
            minHeight: 0,
        }}
        className={styles.listNodeRow}
    >
        <Stack
            direction='row'
            justifyContent='space-between'
            width='100%'
            alignItems='center'
        >
            <Stack
                direction='row'
                gap='4px'
                alignItems='center'
                padding='2px 0'
            >
                <Chip
                    sx={{
                        color: 'rgb(211, 47, 47)',
                        borderRadius: '12px',
                        width: '48px',
                        flexShrink: 0,
                        '& .MuiChip-label': {
                            paddingLeft: '5px',
                            paddingRight: '5px',
                        },
                    }}
                    label={`${Math.round(summary.durationSumPercent * 10) / 10}%`}
                    size='small'
                    color='error'
                    variant='outlined'
                />
                <Typography
                    variant='subtitle1'
                    sx={{lineHeight: '20px'}}
                >
                    {summary.title}
                </Typography>
            </Stack>
            {Boolean(summary.durationSumDays) && (
                <Typography
                    variant='body2'
                    sx={{opacity: '.88'}}
                >{`${Math.ceil(summary.durationSumDays * 100) / 100}\u00A0сут`}</Typography>
            )}
        </Stack>
    </AccordionSummary>
);
