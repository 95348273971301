import api from 'utils/api';
import {
    BuildingPlanPad,
    BuildingPlanPadSteps,
    BuildingPlanTreePad,
    BuildingTree,
} from '@features/BuildingPlanning/types';
import {ParamsType} from 'utils';
import {Row} from '@ui/NewTable/types';

const ROOT_URL = '/drilling-plan';

const deleteDrillingPlan = (id: string | number) => api.delete(`${ROOT_URL}/${id}`);

const deleteDrillingPlanField = (id: string | number) => api.delete(`${ROOT_URL}/fields/${id}`);

const deleteDrillingPlanPad = (id: string | number) => api.delete(`${ROOT_URL}/pads/${id}`);

const deleteDrillingPlanRevision = (id: string | number) =>
    api.delete(`${ROOT_URL}/revisions/${id}`);

const getDrillingPlanTree = () => api.get<BuildingTree[]>(`${ROOT_URL}/tree`);

const createDrillingPlan = (data: {title: string; revisionTitle?: string}) =>
    api.post(`drilling-plan`, data);

const editDrillingPlan = (title: string, programId: string) =>
    api.put(`${ROOT_URL}/${programId}`, {
        title,
    });

const createDrillingPlanField = (params: ParamsType, revisionId: string) =>
    api.post(`${ROOT_URL}/fields?planRevisionId=${revisionId}`, params);

const editDrillingPlanField = (params: ParamsType, fieldId: number) =>
    api.put(`${ROOT_URL}/fields/${fieldId}`, params);

const getDrillingPlanPad = (padId: number) =>
    api.get<BuildingPlanPad>(`${ROOT_URL}/pads/${padId}/settings`);

const createDrillingPlanPad = (fieldId: number) =>
    api.post<BuildingPlanTreePad>(`${ROOT_URL}/pads?fieldId=${fieldId}`);

const editDrillingPlanPad = (params: BuildingPlanPad, padId: number) =>
    api.put<BuildingPlanPad>(`${ROOT_URL}/pads/${padId}/settings`, params);

const createDrillingPlanRevision = (data: {title: string}, planId: string) =>
    api.post(`${ROOT_URL}/revisions?planId=${planId}`, data);

const editDrillingPlanRevision = (data: {title: string}, planId: string) =>
    api.put(`${ROOT_URL}/revisions/${planId}`, data);

const getKgsBuildingStatusData = (params: ParamsType) =>
    api.get<Row[]>(`${ROOT_URL}/pads/kgs-building-status`, params);

const getDrillingPlanSteps = (padId: number) =>
    api.get<BuildingPlanPadSteps>(`${ROOT_URL}/pads/${padId}/steps`);

const getDrillingTemplates = (params: ParamsType) =>
    api.get(`${ROOT_URL}/templates/well-drilling`, params);

const getPadsTemplates = () => api.get(`${ROOT_URL}/templates/pad`);

const getWellCompletionTemplates = (params: ParamsType) =>
    api.get(`${ROOT_URL}/templates/well-completion`, {wellType: params.key});

export const DrillingPlanService = {
    deleteDrillingPlan,
    deleteDrillingPlanField,
    deleteDrillingPlanPad,
    deleteDrillingPlanRevision,
    getDrillingPlanTree,
    createDrillingPlan,
    createDrillingPlanField,
    editDrillingPlanField,
    createDrillingPlanPad,
    editDrillingPlanPad,
    editDrillingPlanRevision,
    createDrillingPlanRevision,
    editDrillingPlan,
    getDrillingPlanPad,
    getKgsBuildingStatusData,
    getDrillingPlanSteps,
    getDrillingTemplates,
    getPadsTemplates,
    getWellCompletionTemplates,
};
