import {useState} from 'react';
import {useMenuItems} from './useMenuItems';
import {RouteParams} from '../../../types/common';
import {useMediaQuery, useTheme} from '@mui/material';
import classNames from 'classnames';

import styles from './Menu.module.scss';
import {useParams} from 'react-router-dom';
import {firtsElorValue} from 'utils';
import {MobileMenu} from './MobileMenu';
import {MenuItemLink} from './MenuLink';

const Menu = () => {
    const menuItems = useMenuItems();
    const {wellId} = useParams<RouteParams>();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('xl'));

    const [isHovered, setIsHovered] = useState(false);

    const handleMouseEnter = () => {
        setIsHovered(true);
    };

    const handleMouseLeave = () => {
        setIsHovered(false);
    };

    if (isMobile) {
        return (
            <MobileMenu
                menuItems={menuItems}
                wellId={wellId}
            />
        );
    }

    return (
        <section
            className={classNames(styles.wrapper, {
                [styles.hovered]: isHovered,
            })}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            id='menu-section'
        >
            {menuItems.map((menuItem) => (
                <MenuItemLink
                    key={menuItem.code || firtsElorValue(menuItem.key)}
                    item={menuItem}
                    wellId={wellId ?? ''}
                    onItemClick={handleMouseLeave}
                    showTitle
                />
            ))}
        </section>
    );
};

export default Menu;
