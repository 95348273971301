import {Typography} from '@mui/material';
import React from 'react';
import {DT_FORMATS, formatDate} from 'utils';
import {LightTooltip} from '@ui/LightTooltip/LightTooltip';
import {Link} from 'react-router-dom';
import {Routes} from 'constants/routes';

type IncidentDetailsDateProps = {
    withDateLink: boolean;
    date: string;
    wellId: string | number;
};

export const IncidentDetailsDate = ({withDateLink, date, wellId}: IncidentDetailsDateProps) => {
    if (!withDateLink) {
        return <Typography variant='body1'>{formatDate(date)}</Typography>;
    }

    const currentDay = formatDate(date, DT_FORMATS.utcDate);
    const link = `${Routes.Tables}/${wellId}${Routes.OperationCalendar}?day=${currentDay}`;

    return (
        <LightTooltip title='Нажмите, чтобы перейти на страницу Операции'>
            <Link
                target='_blank'
                to={link}
                style={{textDecoration: 'unset'}}
            >
                <Typography
                    variant='body1'
                    color='var(--mui-palette-text-primary)'
                >
                    {formatDate(date)}
                </Typography>
            </Link>
        </LightTooltip>
    );
};
