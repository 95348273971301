import {BarChartProps, ChartCode, ChartItem} from '../types';
import {BarConfig, getCanvasPattern} from '@ant-design/plots';

import {Annotation} from '@antv/g2plot';
import {deepMix} from '@antv/util';
import {SupportedColorScheme} from '@mui/material';
import {computeFill, getTooltip, splitStringToLines} from './common';

type Accumulator = {
    [key: string]: number;
};

const getAnnotations = (
    data: BarChartProps,
    code: ChartCode,
    colorScheme?: SupportedColorScheme,
) => {
    if (code === ChartCode.BUILDING_DATES_BY_STAGE) {
        const array = Object.entries(
            data.reduce((acc: Accumulator, cur) => {
                //groupField - план/факт/проект
                //x - обсадная колонна
                const x = splitStringToLines(cur.x);
                const key = `${x}-${cur.groupField}`;
                if (!acc[key]) acc[key] = 0;
                acc[key] += cur.y;
                return acc;
            }, {} as Accumulator),
        );
        const res = array.map(([key, total], index) => {
            const [, groupField] = key.split('-');
            return {
                type: 'text',
                content: `${groupField}: ${total.toFixed(2)}`,
                position: (_xScales, yScales) => {
                    const rowsPerSection = 3; // Каждая секция состоит из 3 строк: План, Факт и Проект
                    const totalHeight = 100; // 100% высота графика
                    const numberOfSections = array.length / rowsPerSection; // количество секций
                    const totalRows = numberOfSections * rowsPerSection; // всего строк
                    const totalGaps = numberOfSections * 2; // всего промежутков;

                    // высота промежутка относительно строки
                    const gapHeight = 0.8;
                    // высота строки
                    const elementHeight = totalHeight / (totalRows + totalGaps * gapHeight);
                    // высота секции
                    const sectionHeight =
                        elementHeight * rowsPerSection + elementHeight * gapHeight * 2;

                    function calculateYPosition(sectionIndex: number, groupField: string) {
                        // Промежутки перед текущей секцией
                        const gapCountBeforeSection = sectionIndex * sectionHeight;
                        // // Смещение внутри элемента на основе типа
                        let subelementOffset = 0;
                        switch (groupField) {
                            case 'Факт':
                                subelementOffset = 1;
                                break;
                            case 'План':
                                subelementOffset = 2;
                                break;
                            case 'Проект':
                                subelementOffset = 3;
                                break;
                        }
                        const position =
                            elementHeight * subelementOffset + gapCountBeforeSection + gapHeight;
                        return position;
                    }

                    const yPos = calculateYPosition(Math.floor(index / 3), groupField);

                    // 0,045 - смещение текста по x, 0.6 - смещение текста по y
                    return [
                        // @ts-ignore
                        `${(total / yScales.y.max + 0.045) * 100}%`, // потому что для этого типа графика меняется местами x и y
                        `${yPos + 0.6}%`,
                    ];
                },
                style: {
                    textAlign: 'center',
                    fontSize: 13,
                    fill: computeFill(colorScheme),
                },
            } as Annotation;
        });

        const maxValue = data.reduce((acc: number, cur) => {
            return cur.y > acc ? cur.y : acc;
        }, 0);

        return {
            xAxis: {
                max: maxValue + 20,
                grid: {line: {style: {strokeOpacity: 0.4}}},
            },
            annotations: res,
        };
    }
};

const getPatternConfig = (code: ChartCode, colorScheme?: SupportedColorScheme) => {
    if (code === ChartCode.COST_EXPENSE_STRUCTURE_BY_CASING_COLUMN) {
        return {
            План: null,
            Факт: {
                type: 'line',
                cfg: {
                    size: 5,
                    padding: 2,
                    rotation: 145,
                    isStagger: false,
                    stroke: colorScheme === 'light' ? '#000000' : '#FFFFFF',
                },
            },
        };
    }
    return {
        Проект: {
            type: 'line',
            cfg: {
                padding: 4,
            },
        },
        План: {
            type: 'square',
            cfg: {
                size: 5,
                padding: 2,
                rotation: 45,
                isStagger: false,
            },
        },
    };
};

type patternProps = {
    groupField?: 'План' | 'Проект';
};

export const pattern = (
    item: patternProps,
    color: string,
    code: ChartCode,
    colorScheme?: SupportedColorScheme,
) => {
    const patternOptions = deepMix(
        {},
        getPatternConfig(code, colorScheme)[item.groupField ?? 'План'],
        {
            cfg: {
                backgroundColor: color,
            },
        },
    );
    return getCanvasPattern(patternOptions) ?? patternOptions;
};

const getFields = (settings: ChartItem['settings'], code: ChartCode) => {
    if (
        code === ChartCode.BUILDING_DATES_BY_STAGE ||
        code === ChartCode.MTR_CONSUMPTIONS ||
        code === ChartCode.DRILLING_CHEMICAL_REAGENTS_CONSUMPTIONS ||
        code === ChartCode.FASTENING_CHEMICAL_REAGENTS_CONSUMPTIONS ||
        code === ChartCode.DEVELOPMENT_CHEMICAL_REAGENTS_CONSUMPTIONS
    ) {
        return {
            rawFields: ['valueType', 'groupField'],
            isStack: settings?.isStack,
            groupField: 'groupField',
            pattern: (item: patternProps, color: string) => pattern(item, color, code),
        };
    }

    return {};
};

const getLabel = (code: ChartCode): BarConfig['label'] => {
    if (code === ChartCode.MTR_CONSUMPTIONS) {
        return {
            position: 'middle',
            layout: [
                {
                    type: 'adjust-color',
                },
            ],
        };
    }

    return false;
};

export const getBarConfig = (
    {settings, code}: ChartItem,
    colorScheme: SupportedColorScheme | undefined,
    data: BarChartProps,
): Omit<BarConfig, 'data'> => {
    const config: Omit<BarConfig, 'data'> = {
        isGroup: settings?.isGroup,
        xField: 'y',
        yField: 'x',
        seriesField: 'valueType',
        legend: {
            position: 'top-left',
            offsetY: -8,
            itemName: {
                style: {
                    fill: computeFill(colorScheme),
                },
            },
        },
        xAxis: {
            grid: {line: {style: {strokeOpacity: 0.4}}},
        },
    };

    const annotations = getAnnotations(data, code, colorScheme);
    const fields = getFields(settings, code);
    const label = getLabel(code);
    const tooltip = getTooltip(code);

    return {
        ...config,
        ...annotations,
        ...fields,
        tooltip,
        label,
    };
};
