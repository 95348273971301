import {useEffect} from 'react';
import {RouteParams, invokeAsync} from '../../utils';
import {useDispatch} from 'react-redux';
import {setDictionary, deleteDictionary} from 'slices/dictionariesSlice';
import {createDictionaryParams, getDictionary} from './usePageSchema';
import {useParams} from 'react-router';
import {useSelector} from 'react-redux';
import {selectDictionaries} from 'slices';

type UseDictionaryProps = {
    code?: string;
    key?: string;
    need?: boolean;
    fromStore?: boolean;
    isDictNameWithKey?: boolean;
};

export const useDictionary = ({
    code,
    key,
    need = true,
    fromStore = false,
    isDictNameWithKey = false,
}: UseDictionaryProps) => {
    const params = useParams<RouteParams>();
    const dispatch = useDispatch();
    const dictionaries = useSelector(selectDictionaries);
    // Необходимо для того, чтобы хранить словарь не только по code, но и по code + key
    const dictCode = isDictNameWithKey ? `${code}${key ? `-${key}` : ''}` : code;
    const dictionary = dictCode ? dictionaries[dictCode] : [];

    useEffect(() => {
        if (need && code && dictCode) {
            if (fromStore && dictionary && !!dictionary.length) {
                return;
            }
            invokeAsync(async () => {
                dispatch(deleteDictionary({code: dictCode}));
                const dictionaryParams = createDictionaryParams(params);
                const data = await getDictionary({
                    code,
                    params: {key, ...dictionaryParams},
                });
                dispatch(setDictionary({[dictCode]: data}));
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch, need, key, params, fromStore, dictCode, code]);
};
