import {ComponentType, Suspense} from 'react';
import {Loader} from '@ui/Loader/Loader';

export default function withSuspense<P>(Component: ComponentType & any, props?: P) {
    return (
        <Suspense fallback={<Loader />}>
            <Component {...props} />
        </Suspense>
    );
}
