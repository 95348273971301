import {Typography, Stack} from '@mui/material';
import {npvFileTypeNames} from '../constants';
import {FileCard} from '@features/files/FileCard';
import {getFileIcon} from '../utils';
import {IncidentFile, NpvFileType} from '../types';

export type IncidentFileRowProps = {
    type: NpvFileType;
    files?: IncidentFile[] | null;
};
export const IncidentFileRow = ({type, files = []}: IncidentFileRowProps) => {
    return (
        <Stack
            gap='16px'
            sx={{marginBottom: '8px'}}
        >
            <Stack
                direction='row'
                gap='8px'
            >
                {getFileIcon(type, files || [])}
                <Typography variant='body1'>{npvFileTypeNames[type]}</Typography>
            </Stack>
            <Stack
                direction='column'
                gap='16px'
            >
                {(files || [])
                    .filter((file) => file.type === type)
                    .map((file) => (
                        <FileCard
                            key={file.id}
                            file={{
                                ...file,
                                originalFilename: file.filename || '',
                                _links: {
                                    'Скачать файл': {
                                        href: `files/${file.id}/download`,
                                    },
                                    'Удалить файл': {href: ''},
                                    self: {href: ''},
                                },
                            }}
                            width='100%'
                        />
                    ))}
            </Stack>
        </Stack>
    );
};
