import {LightTooltip} from '@ui/LightTooltip/LightTooltip';

type TableCellTooltipProps = {
    value: string | number | null;
    columnWidth?: number;
    children?: React.ReactElement;
};

const TableCellTooltip = ({value, columnWidth, children}: TableCellTooltipProps) => {
    if (value === undefined || value === null) {
        return null;
    }

    if (!columnWidth) {
        return children || value;
    }

    if (String(value).length < columnWidth / 10) {
        return children || value;
    }

    return (
        <LightTooltip
            title={value}
            followCursor={false}
            offset={[-3, -25]}
            componentsProps={{
                tooltip: {
                    sx: {
                        fontSize: ' 0.875rem',
                        fontWeight: 400,
                        width: columnWidth - 10,
                        whiteSpace: 'normal',
                    },
                },
            }}
        >
            {children || <span>{value}</span>}
        </LightTooltip>
    );
};

export default TableCellTooltip;
