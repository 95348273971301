import React, {MouseEventHandler, useCallback} from 'react';
import {FileEntity} from '../../pages/FilesPage';
import {FilesService} from '../../services/Files/FilesService';
import {Button, Link} from '@mui/material';
import {downloadFile} from 'utils/api/file';

type FileCellProps = {
    record: FileEntity;
};

export const downloadFieldName = 'Скачать файл';
const service = FilesService.getInstance();

export const FileCell = ({record}: FileCellProps) => {
    const linkClickHandle: MouseEventHandler = useCallback(
        async (event) => {
            event.preventDefault();

            const link = record._links[downloadFieldName].href.replace(/.*api/, '');
            const data = await service.downloadFileFromTable(link);

            downloadFile(data.data, record.originalFilename);
        },
        [record],
    );

    return (
        <Button
            color='inherit'
            LinkComponent={Link}
            href={record._links[downloadFieldName].href}
            onClick={linkClickHandle}
        >
            {record.originalFilename}
        </Button>
    );
};
