import {useLocalStorage} from '@core/utils/hooks';
import React, {useCallback} from 'react';
import {createPortal} from 'react-dom';
import Draggable, {DraggableEventHandler} from 'react-draggable';

type DraggableModalProps = {
    children: React.ReactNode;
    id: string;
    defaultPosition?: {x: number; y: number};
};

export const DraggableModal = ({id, children, defaultPosition}: DraggableModalProps) => {
    const [coords, setCoords] = useLocalStorage(id);
    const onDragStop: DraggableEventHandler = useCallback(
        (_, data) => {
            setCoords(
                JSON.stringify({
                    x: data.x,
                    y: data.y,
                }),
            );
        },
        [setCoords],
    );
    return (
        <>
            {createPortal(
                <Draggable
                    defaultPosition={
                        coords ? JSON.parse(coords) : defaultPosition || {x: 200, y: 200}
                    }
                    onStop={onDragStop}
                    bounds='parent'
                    handle='.dnd_handle'
                >
                    {children}
                </Draggable>,
                document.body,
            )}
        </>
    );
};
