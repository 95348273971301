import {npvStatusColor, npvStatusNames, npvStatusVariant} from '@features/incident/constants';
import {NpvStatus} from '@features/incident/types';
import {Chip} from '@mui/material';

type StatusChipProps = {
    status: NpvStatus;
};

export const StatusChip = ({status}: StatusChipProps) => {
    return (
        <Chip
            sx={{
                color: npvStatusColor[status],
                borderRadius: '16px',
            }}
            label={npvStatusNames[status]}
            color={npvStatusVariant[status]}
            size='medium'
            variant='outlined'
        />
    );
};
