import {DonutValues} from '../types';
import {getExtendedValues} from '../utils';
import {Stack, Box, Typography, Divider, StackProps} from '@mui/material';
import styles from './DonutLegend.module.css';
import {getIndexColor} from '../../../features/wellDesign/DashboardItems/utils';
import {useMemo} from 'react';

type DonutLegendProps = {
    unit: string;
    values: DonutValues;
    size: 'small' | 'medium' | 'large';
    headerProps?: StackProps;
    hasTotal?: boolean;
} & StackProps;
export const DonutLegend = ({
    values,
    unit,
    size,
    hasTotal = false,
    headerProps,
    ...otherProps
}: DonutLegendProps) => {
    const extendedValues = getExtendedValues(values);

    const fontSize = useMemo(() => {
        if (size === 'small') return 12;
        if (size === 'medium') return 14;
        return 20;
    }, [size]);
    const minWidth = size === 'small' ? 30 : 50;

    const totalVal = useMemo(() => {
        if (!hasTotal) return;

        return extendedValues
            .reduce((acc, i) => {
                acc += i.value;
                return acc;
            }, 0)
            .toFixed(1);
    }, [extendedValues, hasTotal]);

    const totalPercent = useMemo(() => {
        if (!hasTotal) return;

        return extendedValues
            .reduce((acc, i) => {
                acc += i.percent;
                return acc;
            }, 0)
            .toFixed();
    }, [extendedValues, hasTotal]);

    return (
        <Stack
            direction='column'
            gap={1}
            maxHeight='142px'
            overflow='hidden'
            paddingRight='10px'
            sx={{overflowY: 'auto'}}
            {...otherProps}
        >
            <Stack
                direction='row'
                gap={1}
                alignItems='center'
                justifyContent='flex-start'
                position='sticky'
                sx={{backgroundColor: 'var(--mui-palette-background-default)'}}
                top={0}
                zIndex={100}
                {...headerProps}
            >
                <Box
                    flexGrow={2}
                    component='div'
                ></Box>
                <Typography
                    variant='body2'
                    minWidth={minWidth}
                    textAlign='right'
                    color='text.primary'
                    fontSize={fontSize}
                >
                    {unit}
                </Typography>
                <Typography
                    variant='body2'
                    minWidth={minWidth}
                    textAlign='right'
                    color='text.primary'
                    fontSize={fontSize}
                >
                    %
                </Typography>
            </Stack>
            <Divider flexItem={true} />
            {extendedValues.map((v, i) => {
                return (
                    <Stack
                        key={v.name}
                        direction='row'
                        gap={1}
                        alignItems='center'
                        justifyContent='flex-start'
                    >
                        <Box
                            flexShrink={0}
                            flexBasis={12}
                            width={12}
                            style={{backgroundColor: v.color || getIndexColor(i)}}
                            className={styles.icon}
                            component='div'
                        ></Box>
                        <Typography
                            variant='body2'
                            flexGrow={2}
                            color='text.primary'
                            fontSize={fontSize}
                        >
                            {v?.name}
                        </Typography>
                        <Typography
                            variant='regular2'
                            minWidth={minWidth}
                            textAlign='right'
                            fontSize={fontSize}
                        >
                            {Math.round(v.value * 10) / 10}
                        </Typography>
                        <Typography
                            variant='regular2'
                            minWidth={minWidth}
                            textAlign='right'
                            fontSize={fontSize}
                        >
                            {Math.round(v.percent * 10) / 10}
                        </Typography>
                    </Stack>
                );
            })}
            {hasTotal && (
                <>
                    <Divider flexItem={true} />
                    <Stack
                        direction='row'
                        gap={1}
                        alignItems='center'
                        justifyContent='flex-start'
                    >
                        <Typography
                            variant='body2'
                            flexGrow={2}
                            color='text.primary'
                            fontSize={fontSize}
                        >
                            ОБЩЕЕ
                        </Typography>
                        <Typography
                            variant='regular2'
                            minWidth={minWidth}
                            textAlign='right'
                            fontSize={fontSize}
                        >
                            {totalVal}
                        </Typography>
                        <Typography
                            variant='regular2'
                            minWidth={minWidth}
                            textAlign='right'
                            fontSize={fontSize}
                        >
                            {totalPercent}
                        </Typography>
                    </Stack>
                </>
            )}
        </Stack>
    );
};
