import React, {useMemo} from 'react';
import {Box} from '@mui/material';
import {TimeBalanceAnalyticProps} from '../types';
import IndicatorPanel from './IndicatorPanel';
import ParametersList from './ParametersList';
import {useTimeBalanceData} from '../utils/useTimeBalanceData';
import {FlexLoader} from '@ui/Loader/FlexLoader';

const TimeBalanceAnalyticPanel = (props: TimeBalanceAnalyticProps) => {
    const [data, loading] = useTimeBalanceData(props.selectedDateItem.id);

    const [resultTitle, nptTitle] = useMemo(() => {
        return [`Итог за ${props.selectedDateItem.date}`, `НПВ за ${props.selectedDateItem.date}`];
    }, [props.selectedDateItem.date]);

    if (loading) {
        return <FlexLoader />;
    }

    const hasMiddleLine = data?.drilling?.length || data?.fastening?.length;

    return (
        <>
            <Box
                sx={{
                    marginBottom: 2,
                    display: 'flex',
                    flexDirection: 'column',
                    flex: hasMiddleLine ? 0 : 1,
                }}
                component="div"
            >
                <IndicatorPanel
                    items={data?.result || []}
                    title={resultTitle}
                />
            </Box>
            {hasMiddleLine ? (
                <Box sx={{flex: 1, marginBottom: 2, display: 'flex'}} component="div">
                    <ParametersList
                        items={data?.drilling || []}
                        title='Бурение'
                    />
                    <ParametersList
                        items={data?.fastening || []}
                        title='Крепление'
                    />
                </Box>
            ) : null}
            <Box sx={{display: 'flex', flexDirection: 'column', flex: hasMiddleLine ? 0 : 1}} component="div">
                <IndicatorPanel
                    items={data?.npt || []}
                    title={nptTitle}
                />
            </Box>
        </>
    );
};

export default React.memo(TimeBalanceAnalyticPanel);
