import {Button, Stack} from '@mui/material';
import React from 'react';
import {FlexLoader} from '@ui/Loader/FlexLoader';
import KeyboardArrowRightOutlinedIcon from '@mui/icons-material/KeyboardArrowRightOutlined';
import {TripDetails} from '@features/wellDesign/DashboardItems/Trips/types';
import {ValueWithLabel} from './ValueWithLabel';
import {useNavigate, useParams} from 'react-router-dom';
import {Routes} from 'constants/routes';
import {RouteParams} from 'utils';

export const TripKnbkInfo = ({
    isLoading,
    tripDetails,
}: {
    isLoading: boolean;
    tripDetails?: TripDetails;
}) => {
    const navigate = useNavigate();
    const params = useParams<RouteParams>();
    return (
        <Stack
            direction='row'
            alignItems='center'
            gap='39px'
        >
            {isLoading ? (
                <FlexLoader />
            ) : !!tripDetails?.knbkFactId ? (
                <Button
                    onClick={() =>
                        navigate(
                            `${Routes.Knbk}/${
                                params.wellId || tripDetails?.wellId
                            }${Routes.KnbkDrilling}/fact/${tripDetails?.knbkFactId}`,
                        )
                    }
                    size='medium'
                    variant='contained'
                    color='inherit'
                    endIcon={<KeyboardArrowRightOutlinedIcon />}
                    sx={{
                        fontSize: 16,
                        fontWeight: 500,
                        lineHeight: 1.6,
                        height: '40px',
                        width: '150px',
                        paddingRight: 0,
                        justifyContent: 'center',
                        '&.Mui-disabled': {
                            color: 'var(--mui-palette-action)',
                        },
                        backgroundColor: 'var(--mui-palette-action-hover)',
                        color: 'var(--mui-palette-text-primary)',
                    }}
                >
                    {tripDetails?.knbkFactTitle?.replace('.0', '')}
                </Button>
            ) : null}
            <ValueWithLabel
                label='Цель рейса'
                isLoading={isLoading}
            >
                {tripDetails?.knbkGoal}
            </ValueWithLabel>
        </Stack>
    );
};
