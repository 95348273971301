import {IconButton, Stack, Typography} from '@mui/material';
import React, {useCallback, useEffect, useMemo, useState} from 'react';
import styles from '../styles.module.css';
import {CloseOutlined, DragIndicator} from '@mui/icons-material';
import cn from 'classnames';
import Draggable, {DraggableEventHandler} from 'react-draggable';
import {useLocalStorage} from '@core/utils/hooks';
import {createPortal} from 'react-dom';
import {IncidentList} from './ListPopupData';
import {IncidentType, ListPopupSummary, ListPopupSummaryGrouped} from '../../types';
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import {ToggleIncidentsTypes} from '@features/incident/components/ToggleIncidentsTypes/ToggleIncidentsTypes';
import {isOtPbOosSummaryType} from '@features/incident/listPopups/utils';
import {OtPbOosByWellResponse} from '@services/OtPbOosService/types';
import OtPbOosList from '@features/OtPbOos/OtPbOosList';

export type ListPopupType = 'incidents' | 'additional_work' | 'PNV_list' | 'breach';

type ListPopupProps = {
    popupType: ListPopupType;
    filters: React.ReactNode;
    className?: string;
    data: ListPopupSummaryGrouped[] | ListPopupSummary[] | OtPbOosByWellResponse;
    refetch: () => void;
    isLoading?: boolean;
    page: string;
    onClose: () => void;
    onDownloadReportClick?: () => void;
    incidentType?: IncidentType;
    setIncidentType?: (incident: IncidentType) => void;
};

const DEFAULT_COORDS = {x: 50, y: 50};

export const ListPopup = ({
    popupType,
    className,
    filters,
    data,
    refetch,
    isLoading,
    page,
    onClose,
    onDownloadReportClick,
    incidentType,
    setIncidentType,
}: ListPopupProps) => {
    const [localCoords, setLocalCoords] = useState(DEFAULT_COORDS);
    const [storageCoords, setStorageCoords] = useLocalStorage(
        `incident_popup_list_position_${page}`,
    );

    const onDragStop: DraggableEventHandler = useCallback(
        (_, data) => {
            if (popupType === 'breach') setLocalCoords({x: data.x, y: data.y});
            else setStorageCoords(JSON.stringify({x: data.x, y: data.y}));
        },
        [popupType, setStorageCoords],
    );

    useEffect(() => {
        popupType === 'breach' && setLocalCoords(DEFAULT_COORDS);
    }, [popupType, data]);

    const position = useMemo(() => {
        if (popupType === 'breach') return localCoords;
        return storageCoords ? JSON.parse(storageCoords) : undefined;
    }, [popupType, storageCoords, localCoords]);

    const PopupHeader = useMemo(() => {
        if (incidentType && setIncidentType && popupType === 'incidents') {
            return (
                <ToggleIncidentsTypes
                    incidentType={incidentType}
                    setIncidentType={setIncidentType}
                />
            );
        }

        let title = '';
        if (popupType === 'PNV_list') title = 'Список ПНВ';
        if (popupType === 'additional_work') title = 'Список дополнительных работ';
        if (popupType === 'incidents') title = 'Список инцидентов';
        if (popupType === 'breach') title = 'Список нарушений';
        return (
            <Typography
                variant='h6'
                color='var(--mui-palette-text-primary)'
            >
                {title}
            </Typography>
        );
    }, [popupType, incidentType, setIncidentType]);

    return (
        <>
            {createPortal(
                <Draggable
                    defaultPosition={
                        popupType !== 'breach' && storageCoords
                            ? JSON.parse(storageCoords)
                            : DEFAULT_COORDS
                    }
                    onStop={onDragStop}
                    bounds='parent'
                    handle='.dnd_handle'
                    position={popupType === 'breach' ? position : undefined}
                >
                    <div className={cn(styles.incidents, className)}>
                        <Stack
                            direction='column'
                            gap='10px'
                            sx={{maxHeight: '100%'}}
                        >
                            <Stack
                                direction='row'
                                alignItems='center'
                            >
                                <IconButton
                                    className='dnd_handle'
                                    sx={{cursor: 'move', left: '-10px'}}
                                >
                                    <DragIndicator />
                                </IconButton>
                                {PopupHeader}
                                <Stack
                                    direction='row'
                                    sx={{marginLeft: 'auto'}}
                                >
                                    {popupType !== 'breach' &&
                                        popupType === 'incidents' &&
                                        onDownloadReportClick && (
                                            <IconButton onClick={onDownloadReportClick}>
                                                <DownloadOutlinedIcon />
                                            </IconButton>
                                        )}
                                    <IconButton onClick={onClose}>
                                        <CloseOutlined />
                                    </IconButton>
                                </Stack>
                            </Stack>
                            {filters}
                            {isOtPbOosSummaryType(data) ? (
                                <OtPbOosList
                                    data={data}
                                    refetch={refetch}
                                    isLoading={isLoading}
                                />
                            ) : (
                                <IncidentList
                                    data={data}
                                    refetch={refetch}
                                    isLoading={isLoading}
                                />
                            )}
                        </Stack>
                    </div>
                </Draggable>,
                document.body,
            )}
        </>
    );
};
