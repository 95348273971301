import {Dispatch, SetStateAction} from 'react';

type asyncFunction = (...args: any) => Promise<any | void>;

export const invokeAsync = async (
    funcAsync: asyncFunction,
    setLoading?: Dispatch<SetStateAction<boolean>>,
) => {
    setLoading?.(true);
    try {
        return await funcAsync();
    } catch (error) {
        return error;
    } finally {
        setLoading?.(false);
    }
};
