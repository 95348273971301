/**
 * Гарантированно возвращает массив нужного типа
 *
 * @param arr Входящие данные
 * @param def Значение по-умолчанию
 *
 * @returns Если входящие данные - массив будут возвращены они, иначе значение из `def`
 */
export function arrayOrDefault<T>(arr: T[], def?: T[]): T[];
export function arrayOrDefault<T>(arr: unknown, def?: T[]): T[];
export function arrayOrDefault<T>(arr: unknown, def: T[] = []): T[] {
    return arr instanceof Array ? (arr as T[]) : def;
}

/**
 * Функция аналогична `Array.prototype.map`,
 * однако при возвращаемом значении `undefined` не включает элемент в итоговый массив
 *
 * @param arr Исходный массив
 * @param callback Метод, аналогичный соответствущему в `Array.prototype.map`, но может вернуть undefined
 * @returns Итоговый массив
 * @example
 * ```ts
 * const a = [1, 2, 3, 4, 5]
 * const b = mapOnly(a, (n) => {
 *     if (n < 3) return
 *
 *     return n * 2
 * })
 * console.log(b) // b = [6, 8, 10]
 * ```
 */
export const mapOnly = <Input, Output>(
    arr: Input[] | null | undefined,
    callback: (elem: Input, index: number, arr: Input[]) => Output | undefined,
): Output[] => {
    if (!arr || arr.length <= 0) return [];
    const out: Output[] = [];
    for (let i = 0; i < arr.length; i++) {
        const result = callback(arr[i], i, arr);
        if (result !== undefined) out.push(result);
    }

    return out;
};

export const firtsElorValue = <T>(data: T | T[]): T => {
    return Array.isArray(data) ? data[0] : data;
};

export type SortField<T> = `${Extract<keyof T, string>}:${'asc' | 'desc'}`;

/**
 * Создает функцию для сортировки массива объектов по нескольким полям.
 *
 * @template T
 * @param {SortField<T>[]} fields - Массив строковых полей с указанием порядка сортировки. Например, ['age:asc', 'name:desc'].
 * @returns {(a: T, b: T) => number} - Функция сравнения для использования в Array.prototype.sort.
 *
 * @example
 * const data = [
 *   { name: 'John', age: 25 },
 *   { name: 'Jane', age: 22 },
 *   { name: 'John', age: 20 },
 *   { name: 'Jane', age: 20 },
 * ];
 * data.sort(multiFieldSort(['age:asc', 'name:desc']));
 * console.log(data);
 */
export const multiFieldSort =
    <T extends Record<string, any>>(fields: SortField<T>[]): ((a: T, b: T) => number) =>
    (a, b) => {
        for (let i = 0; i < fields.length; i++) {
            const field = fields[i];
            const [key, order = 'asc'] = field.split(':');
            const orderMultiplier = order === 'asc' ? 1 : -1;

            if (a[key] < b[key]) {
                return -1 * orderMultiplier;
            }
            if (a[key] > b[key]) {
                return 1 * orderMultiplier;
            }
        }
        return 0;
    };

/**
 * Проверяет, что хотя бы один из элементов коллекции collection1 есть в коллекции collection2.
 *
 * @param {Set<number>} [collection1] - Первая коллекция (множество).
 * @param {number[]} [collection2] - Вторая коллекция (массив).
 * @returns {boolean} - true, если хотя бы один элемент из collection2 есть в collection1, иначе
 * false.
 */
export const hasCommonElement = (collection1?: Set<number>, collection2?: number[]) => {
    if (!collection1 || !collection2) return false;
    return collection2.some(element => collection1.has(element));
}
