import {colors} from '@features/incident/constants';
import {isListPopupSummaryGrouped} from '@features/incident/listPopups/utils';
import {ListPopupSummary, ListPopupSummaryGrouped} from '@features/incident/types';
import {Paper, Stack, Typography} from '@mui/material';
import {LightTooltip} from '@ui/LightTooltip/LightTooltip';
import {useMemo} from 'react';

type TooltipContentProps = {
    data: ListPopupSummary[];
};
const TooltipContent = ({data}: TooltipContentProps) => {
    return (
        <Paper sx={{padding: '12px', overflow: 'visible', background: 'none', boxShadow: 'none'}}>
            {data.map((d, i) => (
                <Stack
                    key={d.title}
                    direction='row'
                    justifyContent='space-between'
                    gap='8px'
                >
                    <Stack
                        direction='row'
                        gap='4px'
                        alignItems='center'
                    >
                        <div
                            style={{
                                background: colors[i % colors.length],
                                width: '8px',
                                height: '8px',
                                flexShrink: 0,
                            }}
                        />
                        {/* @ts-ignore */}
                        <Typography variant='tooltipBody'>{d.title}</Typography>
                    </Stack>
                    {/* @ts-ignore */}
                    <Typography variant='tooltipBody'>
                        {Math.round(d.durationSumPercent * 100) / 100}%
                    </Typography>
                </Stack>
            ))}
        </Paper>
    );
};

type StackedBarProps = {
    data: ListPopupSummaryGrouped[] | ListPopupSummary[];
};
export const StackedBarWithTooltip = ({data}: StackedBarProps) => {
    const formatedData = useMemo(() => {
        if (isListPopupSummaryGrouped(data)) {
            return data.flatMap((i) => i.summaries);
        }
        return data;
    }, [data]);

    return (
        <LightTooltip
            title={<TooltipContent data={formatedData} />}
            offset={[20, 0]}
            placement='right-start'
        >
            <Stack
                direction='row'
                gap='2px'
                sx={{cursor: 'help'}}
            >
                {formatedData.map((stat, idx) => (
                    <div
                        key={stat.title}
                        style={{
                            flexGrow: stat.durationSumPercent,
                            height: '16px',
                            background: colors[idx % colors.length],
                        }}
                    ></div>
                ))}
            </Stack>
        </LightTooltip>
    );
};
