import {Row, TableSimpleRowProps} from './types';
import {TableSimpleRowActions} from './TableSimpleRowActions';
import {NewTableCell} from './NewTableCell';

const TableSimpleRow = <T extends Row>({
    columns,
    row,
    tableConfig,
    onEditClick,
    handleSelect,
    isItemSelected,
    disableAction = false,
    onDelete,
    onDeleteClick,
    draggableProvided,
    confirmDeletingDescription,
    renderAdditionalRowActions,
}: TableSimpleRowProps<T>) => {
    return (
        <>
            <TableSimpleRowActions<T>
                tableConfig={tableConfig}
                disableAction={disableAction}
                isItemSelected={isItemSelected}
                handleSelect={handleSelect}
                onEditClick={onEditClick}
                onDelete={onDelete}
                onDeleteClick={onDeleteClick}
                row={row}
                draggableProvided={draggableProvided}
                confirmDeletingDescription={confirmDeletingDescription}
                renderAdditionalRowActions={renderAdditionalRowActions}
            />
            {Object.values(columns).map((column) => {
                return (
                    <NewTableCell
                        column={column}
                        row={row}
                        key={`${row.id}_${column.id}`}
                    />
                );
            })}
        </>
    );
};

export default TableSimpleRow;
