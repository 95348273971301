import {Button, Stack} from '@mui/material';

type CancelActionButtonsProps = {
    cancelText?: string;
    actionText?: string;
    isActionBtnDisabled?: boolean;
    handleClose: () => void;
};

export const CancelActionButtons = ({
    cancelText = 'Отмена',
    actionText = 'Скачать',
    handleClose,
    isActionBtnDisabled = false,
}: CancelActionButtonsProps) => {
    return (
        <Stack
            direction='row'
            justifyContent='flex-end'
            gap={1}
        >
            <Button
                onClick={handleClose}
                variant='outlined'
            >
                {cancelText}
            </Button>
            <Button
                variant='contained'
                type='submit'
                disabled={isActionBtnDisabled}
            >
                {actionText}
            </Button>
        </Stack>
    );
};
