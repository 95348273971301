import {useCallback} from 'react';
import {useQuery} from '@tanstack/react-query';
import {TableSchemaService} from '@services/Table';

type useFilterOptionsProps = {
    baseEntityId: string;
    entityName: string;
    fieldName: string;
};

export const useFilterOptions = ({baseEntityId, entityName, fieldName}: useFilterOptionsProps) => {
    const service = TableSchemaService.getInstance();

    const fetchFilteredOptions = useCallback(async () => {
        try {
            const data = await service.getFilteredOptions({baseEntityId, entityName, fieldName});
            return data || [];
        } catch (error) {
            console.error('Error fetching filtered options:', error);
            throw error;
        }
    }, [baseEntityId, entityName, fieldName, service]);

    const {data, isError, isLoading} = useQuery({
        queryKey: ['filteredOptions', baseEntityId, entityName, fieldName],
        queryFn: fetchFilteredOptions,
        enabled: !!entityName && !!fieldName, // у нас есть таблица пользователей без baseEntityId
    });

    return {data: data || [], isLoading, isError};
};
