import React, {useCallback, useRef} from 'react';
import Fab from '@mui/material/Fab';
import {Avatar, Menu, MenuItem} from '@mui/material';
import {UserAction, UserActionType, UserAvatarProps} from './types';
import {useModal} from '../../utils';
import {userActions} from './constants';
import {AuthService} from '../../services/AuthService';

const UserAvatar = (props: UserAvatarProps) => {
    const ref = useRef(null);
    const contextMenu = useModal();

    const userProfile = AuthService.getUserProfile();
    const avatarLetter = userProfile?.preferred_username ? userProfile?.preferred_username[0] : 'U';

    const menuItemClickHandle = useCallback((item: UserAction) => {
        if (item.value === UserActionType.Logout) {
            AuthService.logout();
        }
    }, []);

    return (
        <>
            <Fab
                title={userProfile?.preferred_username}
                onClick={contextMenu.show}
                ref={ref}
                sx={props.style}
                size='small'
            >
                <Avatar sx={{background: '#007FFF'}}>{avatarLetter}</Avatar>
            </Fab>
            <Menu
                anchorEl={ref.current}
                open={contextMenu.isVisible}
                onClose={contextMenu.hide}
            >
                {userActions.map((item) => (
                    <MenuItem
                        sx={{width: '150px'}}
                        key={item.value}
                        onClick={() => menuItemClickHandle(item)}
                    >
                        {item.label}
                    </MenuItem>
                ))}
            </Menu>
        </>
    );
};

export default UserAvatar;
