export const getChartContainerStyles = (isFullscreen: boolean) => {
    if (isFullscreen) {
        return {
            position: 'absolute',
            width: 'calc(100vw - 8px)',
            height: '100vh',
            left: 0,
            top: 0,
            background: 'var(--mui-palette-background-default)',
            zIndex: 'var(--mui-zIndex-appBar)',
            padding: '24px',
        };
    }
    return {
        position: 'relative',
    };
};
