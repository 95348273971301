import {PropsWithChildren, memo} from 'react';
import {Header} from '../Header/Header';
import styles from './Layout.module.scss';
import Menu from './Menu/Menu';
import {LayoutProps} from './types';
import {Outlet} from 'react-router-dom';
import {Popovers} from '@features/popovers/Popovers';

const _Layout = ({hasSidebar, children}: PropsWithChildren<LayoutProps>) => {
    return (
        <>
            <div className={styles.wrapper}>
                <div id='app-header'>
                    <Header />
                </div>
                {hasSidebar && <Menu />}
                <main
                    id='app-content'
                    className={styles.content}
                >
                    {children ? children : <Outlet />}
                </main>
            </div>

            <Popovers />
        </>
    );
};

export default memo(_Layout);
