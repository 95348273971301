import {DashboardService} from '../../../../services/Dashboard/DashboardService';
import {useEffect, useMemo, useState} from 'react';
import {TimeBalanceTableResponse} from '../types';
import {useParams} from 'react-router-dom';
import {RouteParams, invokeAsync} from '../../../../utils';
import {getSortString, mapTimeBalanceTableItem} from './utils';
import {GridSortModel} from '@mui/x-data-grid';

const service = DashboardService.getInstance();
const size = 10;

export const useTimeBalanceTable = (
    page = 1,
    sort?: GridSortModel,
): [TimeBalanceTableResponse | undefined] => {
    const {wellId} = useParams<RouteParams>();
    const [data, setData] = useState<TimeBalanceTableResponse | undefined>();

    const sortString = useMemo(() => {
        const defaultSort = 'date,asc';

        if (sort) {
            return getSortString(sort) || defaultSort;
        }

        return defaultSort;
    }, [sort]);

    useEffect(() => {
        void invokeAsync(
            async () => {
                const response = await service.getTimeBalanceTable(
                    Number(wellId),
                    page,
                    size,
                    sortString,
                );

                response.content = mapTimeBalanceTableItem(response.content);

                setData(response);
            }
        );
    }, [sortString, page, wellId]);

    return useMemo(() => [data], [data]);
};
