import {Stack, Typography} from '@mui/material';
import React from 'react';
import {FlexLoader} from '@ui/Loader/FlexLoader';

export const ValueWithLabel = ({
    label,
    isLoading = false,
    children,
}: {
    label: string;
    isLoading?: boolean;
    children: React.ReactNode;
}) => {
    if (isLoading) {
        return <FlexLoader />;
    }
    return (
        <Stack gap='4px'>
            <Typography
                variant='regular3'
                color='var(--mui-palette-text-secondary)'
            >
                {label}
            </Typography>
            <Typography variant='regular1'>{children || 'N/A'}</Typography>
        </Stack>
    );
};
