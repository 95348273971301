import {NavLink} from './types';
import {Routes} from '../../constants/routes';

export const getNavLinks = (wellId?: string): NavLink[] => {
    const tablesLink = wellId
        ? `${Routes.Tables}/${wellId}${Routes.CommonReportInformations}`
        : Routes.Wells;

    const dashboardLink = wellId ? `${Routes.Dashboard}/${wellId}` : Routes.Dashboard;

    return [
        {
            name: 'Таблицы',
            link: tablesLink,
        },
        {
            name: 'Дашборд',
            link: dashboardLink,
        },
    ];
};
