import {Checkbox, FormControlLabel, Stack, Typography} from '@mui/material';
import {ReportType, reportsOptionsTitleMap} from './constants';

type FinalReportBlockProps = {
    title: string;
    allReports: Set<ReportType>;
    updateSelectedReports: (newValue: ReportType) => void;
    reports: ReportType[];
};
export const FinalReportBlock = ({
    title,
    allReports,
    reports,
    updateSelectedReports,
}: FinalReportBlockProps) => {
    return (
        <Stack>
            <Typography variant='subtitle2'>{title}</Typography>
            {reports.map((report) => (
                <FormControlLabel
                    sx={{
                        height: '20px',
                        fontSize: '14px',
                    }}
                    key={report}
                    label={
                        <Typography
                            variant='regular1'
                            fontSize='14px'
                            fontWeight={400}
                        >
                            {reportsOptionsTitleMap[report]}
                        </Typography>
                    }
                    control={
                        <Checkbox
                            id={report}
                            checked={allReports.has(report)}
                            onChange={() => updateSelectedReports(report)}
                            inputProps={{'aria-label': 'controlled'}}
                            sx={{
                                padding: 0,
                                marginRight: '8px',
                                marginLeft: '16px',
                            }}
                        />
                    }
                />
            ))}
        </Stack>
    );
};
