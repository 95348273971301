import {useEffect, useMemo, useState} from 'react';
import {MenuItem, MenuItemType} from '../../../types/common';
import {Box, Button, SvgIcon, Typography} from '@mui/material';
import classNames from 'classnames';
import styles from './Menu.module.scss';
import {useLocation} from 'react-router-dom';
import {getChildUrlsForMenuItem, getUrlForMenuItem} from './utils';
import {NavLink} from 'react-router-dom';
import {iconByMenuItemCode} from '../../../slices/menu/consts';
import {firtsElorValue} from 'utils';

export const MenuItemLink = ({
    item,
    wellId,
    onItemClick,
    isMobile,
    showTitle,
}: {
    item: MenuItem;
    wellId: string;
    onItemClick: () => void;
    isMobile?: boolean;
    showTitle?: boolean;
}) => {
    const {pathname} = useLocation();
    const url = getUrlForMenuItem(item, wellId);
    const childUrls = getChildUrlsForMenuItem(item, wellId);

    // В режиме эмуляции не смотря на то, что флаг isActive, не обновляется класс.
    // В обычном режиме браузера на компьютере все ок, проблема именно на планшетах.
    // isActive = true, но пока не навести мышкой - не обновляется класс menuActive, поэтому добавляем принудительное обновление
    const [forceKey, setForceKey] = useState(0);

    const icon = useMemo(() => (item.code ? iconByMenuItemCode[item.code] : null), [item.code]);
    const isActive =
        item.type === MenuItemType.Trajectory
            ? childUrls?.some((i) => pathname.includes(i as string))
            : childUrls?.includes(pathname);

    useEffect(() => {
        setForceKey((prev) => prev + 1);
    }, [isActive]);

    return (
        <Box
            className={classNames({
                [styles.menuItem]: !isMobile || showTitle,
                [styles.menuItemMobile]: isMobile && showTitle,
                [styles.menuActive]: isActive,
            })}
            onClick={onItemClick}
            component={NavLink}
            to={firtsElorValue(url)}
            key={forceKey}
        >
            <Button
                color='inherit'
                size='small'
                sx={{
                    color: 'var(--mui-palette-action-active)',
                    width: 'min-content',
                }}
            >
                {icon ? (
                    <SvgIcon
                        component={icon}
                        inheritViewBox
                        sx={{
                            height: '20px',
                            width: '20px',
                        }}
                    />
                ) : (
                    item.label
                )}
            </Button>

            {showTitle && (
                <Typography
                    variant='body2'
                    fontSize={14}
                    className={classNames({
                        [styles.tooltip]: !isMobile,
                        [styles.tooltipMobile]: isMobile,
                    })}
                >
                    {item.label}
                </Typography>
            )}
        </Box>
    );
};
