import React, {memo, useCallback} from 'react';
import {Alert, Box, CardContent, Tabs, Tab} from '@mui/material';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import {RouteParams} from '../../../utils';
import Form from '../../../components/Form';
import {Routes} from '../../../constants/routes';
import {DashboardFilterValues} from '../types';

import styles from '../Dashboard.module.scss';
import {WellDesignPath} from '../../../features/wellDesign/route';

const submitStub = () => {};
const timeBalancePath = 'time-balance';
const wellDesignPath = WellDesignPath;

const getTabHref = (wellId: string | undefined, subPage?: string) => {
    if (!wellId) {
        return Routes.Dashboard;
    }
    return [Routes.Dashboard, wellId, subPage].filter(Boolean).join('/');
};

export const Filter = memo(() => {
    const {wellId} = useParams<RouteParams>();
    const location = useLocation();
    const navigate = useNavigate();

    const tabChangeHandleGetter = useCallback(
        (value: string) => (event: React.SyntheticEvent) => {
            event.preventDefault();
            navigate(value);
        },
        [navigate],
    );

    return (
        <Box className={styles.filter} component="div">
            <CardContent sx={{padding: 1, paddingBottom: '8px !important'}}>
                <Form<DashboardFilterValues>
                    onSubmit={submitStub}
                    initialValues={{wellId: wellId ? Number(wellId) : undefined}}
                >
                    <Box sx={{display: 'flex', alignItems: 'center'}} component="div">
                        <Tabs
                            sx={{marginLeft: 2}}
                            value={location.pathname}
                        >
                            <Tab
                                onClick={tabChangeHandleGetter(getTabHref(wellId))}
                                disabled={!wellId}
                                value={getTabHref(wellId)}
                                component='a'
                                href={getTabHref(wellId)}
                                label='Диаграммы'
                            />
                            <Tab
                                onClick={tabChangeHandleGetter(getTabHref(wellId, timeBalancePath))}
                                disabled={!wellId}
                                value={getTabHref(wellId, timeBalancePath)}
                                component='a'
                                href={getTabHref(wellId, timeBalancePath)}
                                label='Баланс времени'
                            />
                            <Tab
                                onClick={tabChangeHandleGetter(getTabHref(wellId, wellDesignPath))}
                                disabled={!wellId}
                                value={getTabHref(wellId, wellDesignPath)}
                                component='a'
                                href={getTabHref(wellId, wellDesignPath)}
                                label='Дизайн скважины'
                            />
                        </Tabs>
                        {!wellId && (
                            <Alert
                                sx={{marginLeft: 3}}
                                severity='info'
                            >
                                Для отображения графиков выберите скважину
                            </Alert>
                        )}
                    </Box>
                </Form>
            </CardContent>
        </Box>
    );
});
