import {AutocompleteRenderInputParams, TextField} from '@mui/material';
import {getRequiredAdornment} from '../../../utils/functions/getRequiredAdornment';

type AutocompleteRenderInputProps = {
    params: AutocompleteRenderInputParams;
    value: unknown;
    required: boolean;
};

export const AutocompleteRenderInput = ({
    params,
    value,
    required,
}: AutocompleteRenderInputProps) => {
    const additionalProps = getRequiredAdornment({value, required});
    return (
        <TextField
            {...params}
            variant='standard'
            InputProps={{
                ...params.InputProps,
                ...additionalProps,
            }}
        />
    );
};
