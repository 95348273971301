import {PayloadAction, createSlice} from '@reduxjs/toolkit';
import {RootState} from '../utils';

const initialState: RootState['dictionaries'] = {data: {}};

const dictionariesSlice = createSlice({
    name: 'dictionaries',
    initialState,
    reducers: {
        setDictionary: (state, action) => {
            state.data = {...state.data, ...action.payload};
        },
        deleteDictionary: (state, action: PayloadAction<{code: string}>) => {
            const {code} = action.payload;
            const newData = {...state.data};
            delete newData[code];
            state.data = newData;
        },
    },
});

export const {deleteDictionary, setDictionary} = dictionariesSlice.actions;

export default dictionariesSlice.reducer;
