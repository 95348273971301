export enum Routes {
    Main = '/',
    Projects = '/projects',
    Dashboard = '/dashboard',
    Wells = '/wells',
    WellsAnalytics = '/wells-analytics',
    StageAnalytics = '/stage-analytics',
    Files = '/files',
    Calendar = '/calendar',
    Tables = '/tables',
    Knbk = '/knbk',
    Ccdp = '/ccdp',
    Prices = '/prices',
    RatesAndPrices = '/rates-and-prices',
    CommonInfo = '/common-info',
    Monitoring = '/monitoring',
    KnbkGis = '/knbk-gis',
    KnbkProject = '/knbk-project',
    KnbkDrilling = '/knbkDrilling',
    LessonsDatabase = '/lessons-database',
    Ggd = '/ggd',
    Trajectory = '/trajectory',
    SMBO = '/smbo',
    AutoDrilling = '/auto-drilling',
    DrillingCarpet = '/drilling-carpet',
    CommonReportInformations = '/commonReportInformations',
    OperationCalendar = '/operationCalendar',
    Administration = '/administration',
    Accounts = '/accounts',
}
