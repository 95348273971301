import {AxiosError} from 'axios';

export type BusinessError = {
  exceptionDescription: string;
  isBusinessException: boolean;
  message?: string;
};

export const getErrorText = (err: AxiosError): string | undefined => {
  const axiosError = err as AxiosError<Partial<BusinessError>>;

  const getDefaultError = () => {
     if (axiosError.response?.status === 403) {
          return 'Недостаточно прав доступа'
     }
     switch(err.config?.method) {
          case 'get': 
              return 'Не удалось получить данные'
          case 'delete': 
              return 'Не удалось удалить данные'
          case 'put':
              return 'Не удалось изменить данные'
          default: 
              return 'Не удалось выполнить запрос'
      }
  };

  const userText = axiosError?.config?.errorMessage;

  const errorMessage = userText || getDefaultError();

  if (axiosError?.response?.data?.isBusinessException) {
      const businessError = axiosError.response.data.exceptionDescription;

      return errorMessage ? `${errorMessage}. \n${businessError}` : businessError;
  }

  return errorMessage || axiosError?.response?.data?.message || axiosError.message;
};
