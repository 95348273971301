import {useQuery} from '@tanstack/react-query';
import {useEffect, useState} from 'react';
import axios from 'axios';

const apiEndpoints = {
    company: (theme: 'LIGHT' | 'DARK') => ({
        url: `/api/public/files/logo/company-logo`,
        params: {theme},
    }),
    'pro-report': (theme: 'LIGHT' | 'DARK') => ({
        url: `/api/public/files/logo/pro-report-logo`,
        params: {theme},
    }),
};

const apiRequest = async (config: {url: string; params: Record<string, any>}) => {
    const response = await axios.get(config.url, {params: config.params});
    return response.data; // SVG данные
};

const useLogoCache = (colorScheme: 'light' | 'dark', logoType: 'company' | 'pro-report') => {
    const currentTheme = colorScheme.toUpperCase() as 'LIGHT' | 'DARK';
    const [logoCache, setLogoCache] = useState<{LIGHT?: string; DARK?: string}>({});
    const [logoSvg, setLogoSvg] = useState<string | null>(null);
    const apiConfig = apiEndpoints[logoType](currentTheme);

    const {data: logo} = useQuery({
        queryKey: [logoType, currentTheme],
        queryFn: () => apiRequest(apiConfig),
        staleTime: 60 * 60 * 1000, // Срок жизни данных 60 минут
        retry: 1, // Одна попытка перезапроса при ошибке
        enabled: !logoCache[currentTheme], // Запрос выполняется, если логотип не в кэше
    });

    useEffect(() => {
        if (logo) {
            setLogoCache((prevCache) => ({
                ...prevCache,
                [currentTheme]: logo,
            }));
        }
    }, [logo, currentTheme]);

    useEffect(() => {
        setLogoSvg(logoCache[currentTheme] || null);
    }, [currentTheme, logoCache]);

    return {logoSvg};
};

export default useLogoCache;
