import {GridColDef, GridSortModel} from '@mui/x-data-grid';
import {DifferenceStatus, TimeBalanceTableItem} from '../types';
import {DT_FORMATS, formatDate} from '../../../../utils';
import {ChipProps} from '@mui/material';
import {colorsList} from '../../constants';

export const getColumns = (commonWidth: number): GridColDef<TimeBalanceTableItem>[] => {
    const width = (commonWidth - 30) / 6;

    return [
        {
            field: 'date',
            headerName: 'Дата',
            width,
            disableColumnMenu: true,
        },
        {
            field: 'slaughter',
            headerName: 'Забой, м',
            width,
            disableColumnMenu: true,
            sortable: false,
        },
        {field: 'penetration', headerName: 'Проходка, м', width, disableColumnMenu: true},
        {
            field: 'accumulateTime',
            headerName: 'Накопленное время, сут',
            width,
            disableColumnMenu: true,
            sortable: false,
        },
        {field: 'pt', headerName: 'ПВ, ч', width, disableColumnMenu: true, sortable: false},
        {field: 'npt', headerName: 'НПВ, ч', width, disableColumnMenu: true, sortable: false},
    ];
};

export const getColorByDifferenceStatus = (
    differenceStatus: DifferenceStatus,
): ChipProps['color'] => {
    switch (differenceStatus) {
        case DifferenceStatus.Normal:
            return 'success';
        case DifferenceStatus.Warning:
            return 'warning';
        case DifferenceStatus.Alert:
            return 'error';
        default:
            return 'info';
    }
};

export const getColorByIndex = (index: number) => {
    const colorsLength = colorsList.length;
    const colorField = 500;

    if (index < colorsLength) {
        return colorsList[index][colorField];
    }

    const calculatedIndex = index % colorsLength;

    return colorsList[calculatedIndex][colorField];
};

export const mapTimeBalanceTableItem = (items: TimeBalanceTableItem[]): TimeBalanceTableItem[] => {
    return items.map((item) => ({
        ...item,
        id: item.date,
        npt: Number(item.npt.toFixed(3)),
        pt: Number(item.pt.toFixed(3)),
        date: formatDate(item.date, DT_FORMATS.defaultDate) as string,
    }));
};

export const getSortString = (sort: GridSortModel) => {
    const sortStringList = sort.map((item) => {
        if (item.field && item.sort) {
            return `${item.field},${item.sort}`;
        }

        return undefined;
    });

    if (sortStringList.length) {
        return sortStringList.join();
    }
};
