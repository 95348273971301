import {createContext, useContext} from 'react';
import * as typedi from 'typedi';
import {useDidMount, useRefFactory} from '@core/utils/hooks';

export function useInjected<T>(type: typedi.Constructable<T>, transient?: boolean): T;
export function useInjected<T>(id: string, transient?: boolean): T;
export function useInjected<T>(id: typedi.Token<T>, transient?: boolean): T;
export function useInjected<T>(id: typedi.ServiceIdentifier<T>, transient?: boolean): T;
export function useInjected(
    type: typedi.Constructable<unknown> | string | typedi.ServiceIdentifier,
    transient: boolean = false,
) {
    const scope = useContext(scopeContext);
    useDidMount(() => () => transient && scope.remove(type));
    return useRefFactory(() => scope.get(type));
}

export function useGlobalInjected<T>(type: typedi.Constructable<T>): T;
export function useGlobalInjected<T>(id: string): T;
export function useGlobalInjected<T>(id: typedi.Token<T>): T;
export function useGlobalInjected<T>(id: typedi.ServiceIdentifier<T>): T;
export function useGlobalInjected<T extends typedi.Constructable<unknown>>(type: T) {
    return typedi.Container.get(type);
}

const scopeContext = createContext<typedi.ContainerInstance>(typedi.Container.of());
export const ScopeProvider = scopeContext.Provider;

export function useFactoryInjected<T, R>(type: typedi.Constructable<T>, getter: (instance: T) => R): R {
    const factory = useInjected(type);
    return getter(factory);
}

export interface Destructible {
    destroy(): void;
}
