import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {MenuItem, RootState} from '../../utils';
import {Group} from '../../services/Table/types';

const initialState: RootState['menu'] = {
    groups: [],
    items: [],
    loading: [],
    menuLoading: false,
};

const menuSlice = createSlice({
    name: 'menu',
    initialState,
    reducers: {
        setGroups: (state: RootState['menu'], action: PayloadAction<Group[]>) => {
            state.groups = action.payload;
        },
        setItems: (state: RootState['menu'], action: PayloadAction<MenuItem[]>) => {
            state.items = action.payload;
        },
        setLoading: (state: RootState['menu'], action: PayloadAction<[string, boolean]>) => {
            const [code, loading] = action.payload;

            state.loading = loading
                ? [...state.loading, code]
                : state.loading.filter((item) => item !== code);
        },
        setMenuLoading: (state: RootState['menu'], action: PayloadAction<boolean>) => {
            state.menuLoading = action.payload;
        },
    },
});

export const {setItems, setLoading, setMenuLoading} = menuSlice.actions;

export default menuSlice.reducer;
