import {PayloadAction, createSlice} from '@reduxjs/toolkit';
import {NotificationType, RootState} from 'utils';

const initialState: RootState['notifications'] = {data: null};

const notificationsSlice = createSlice({
    name: 'notifications',
    initialState,
    reducers: {
        setNotification: (state, {payload}: PayloadAction<NotificationType>) => {
            state.data = payload;
        },
    },
});

export const {setNotification} = notificationsSlice.actions;

export default notificationsSlice.reducer;
