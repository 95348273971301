import {createSlice} from '@reduxjs/toolkit';

const initialState = {data: ''};

const wellNumberSlice = createSlice({
    name: 'wellNumber',
    initialState,
    reducers: {
        setWellNumber: (state, action) => {
            state.data = action.payload;
        },
    },
});

export const {setWellNumber} = wellNumberSlice.actions;

export default wellNumberSlice.reducer;
