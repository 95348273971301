import {ErrorOutline} from '@mui/icons-material';
import {InputAdornment} from '@mui/material';
import {LightTooltip} from '@ui/LightTooltip/LightTooltip';
import {isNil} from 'lodash';

type getRequiredAdornmentProps = {
    value: unknown;
    required: boolean;
    title?: string;
    styles?: React.CSSProperties;
};

export const getRequiredAdornment = ({
    value,
    required,
    styles,
    title = 'Обязательное поле',
}: getRequiredAdornmentProps) => {
    const validValue =
        !isNil(value) &&
        value !== '' &&
        value !== false &&
        (Array.isArray(value) ? value.length > 0 : true);

    return required && !validValue
        ? {
              startAdornment: (
                  <InputAdornment
                      position='start'
                      sx={styles}
                  >
                      <LightTooltip title={title}>
                          <ErrorOutline color='primary' />
                      </LightTooltip>
                  </InputAdornment>
              ),
          }
        : {};
};
