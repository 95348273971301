import {ChartCode} from '../types';
import {SupportedColorScheme} from '@mui/material';
import {getBarTooltip} from '../BarTooltip';

export const getTooltip = (code: ChartCode, colorScheme?: SupportedColorScheme) => {
    if (
        code === ChartCode.BUILDING_DATES_BY_STAGE ||
        code === ChartCode.MTR_CONSUMPTIONS ||
        code === ChartCode.DRILLING_CHEMICAL_REAGENTS_CONSUMPTIONS ||
        code === ChartCode.FASTENING_CHEMICAL_REAGENTS_CONSUMPTIONS ||
        code === ChartCode.DEVELOPMENT_CHEMICAL_REAGENTS_CONSUMPTIONS ||
        code === ChartCode.COST_EXPENSE_STRUCTURE_BY_CASING_COLUMN
    ) {
        return {
            shared: false,
            customContent: (title: string, items: unknown[]) =>
                getBarTooltip(title, items, code, colorScheme),
        };
    }

    return undefined;
};

export const splitStringToLines = (str: string, maxLength = 18) => {
    const parts = str.split(' ');
    const res = [];
    let accum = parts[0];
    for (let i = 1; i < parts.length; i++) {
        const word = parts[i];
        if (accum.length + word.length + 1 <= maxLength) {
            accum += ' ' + word;
        } else {
            res.push(accum);
            accum = word;
        }
    }
    if (accum) {
        res.push(accum);
    }
    return res.join(' \n');
};

export const computeFill = (colorScheme: SupportedColorScheme | undefined): string => {
    if (colorScheme) {
        return colorScheme === 'dark' ? 'rgba(255, 255, 255, 0.70)' : 'rgba(0, 0, 0, 0.60)';
    }
    return 'rgba(0, 0, 0, 0.60)';
};

export const computeAxisLineColor = (colorScheme: SupportedColorScheme | undefined): string => {
    if (colorScheme === 'dark') return 'rgba(255, 255, 255, 0.70)';
    return 'rgba(0, 0, 0, 0.10)';
};

export const computeStrokeOpacity = (colorScheme: SupportedColorScheme | undefined): number => {
    if (colorScheme === 'dark') return 0.1;
    return 0.4;
};

export const computeTextColor = (colorScheme: SupportedColorScheme | undefined): string => {
    if (colorScheme === 'dark') return '#FFFFFF';
    return '#000000';
};
