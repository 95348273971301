import {TableCell, TableRow} from '@mui/material';
import styles from './NewTable.module.scss';

const NoDataRow = () => {
    return (
        <TableRow>
            <TableCell
                colSpan={12}
                className={styles.noDataText}
            >
                Нет данных
            </TableCell>
        </TableRow>
    );
};

export default NoDataRow;
