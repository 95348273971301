import React from 'react';

interface HeaderLogoProps {
    logoSvg: string | null;
    width?: string;
    height?: string;
}

const HeaderLogo: React.FC<HeaderLogoProps> = ({logoSvg, width = '100px', height = '43px'}) => {
    return (
        <div style={{width, height}}>
            {logoSvg ? (
                <img
                    src={`data:image/svg+xml;base64,${btoa(logoSvg)}`}
                    alt=''
                    style={{width, height}}
                />
            ) : (
                <div style={{width, height}} /> // Пустой контейнер если лого нет
            )}
        </div>
    );
};

export default HeaderLogo;
