import {
    Autocomplete,
    AutocompleteRenderOptionState,
    Checkbox,
    Chip,
    createFilterOptions,
    FilterOptionsState,
} from '@mui/material';
import {GetFormFieldProps} from '../types';
import {AutocompleteRenderInput} from './AutocompleteRenderInput';
import {useKeepOpen} from 'utils/hooks/useKeepOpen';
import {useTableAutocomplete} from './utils';

type TableAutocompleteProps = Omit<GetFormFieldProps, 'CustomComponent' | 'customProps'>;

const filter = createFilterOptions<string>();

export const TableMultipleAutocomplete = ({
    valueKey,
    disabled,
    dictionary,
    setValue,
    required,
    values,
    column,
}: TableAutocompleteProps) => {
    const value = values[valueKey];

    const {finalOptions, getOptionLabel} = useTableAutocomplete({column, dictionary, values});

    const filterSelectOptions = (options: string[], params: FilterOptionsState<string>) => {
        const filtered = filter(options, params);

        if (params.inputValue !== '' && !filtered.includes(params.inputValue)) {
            filtered.push(params.inputValue);
        }

        return filtered;
    };

    const handleChangeValue = (newValue: string[] | null) => {
        if (!newValue) {
            setValue(valueKey, null);
            return;
        }
        setValue(valueKey, newValue);
    };

    const additionalProps = column?.dictIsChangeable ? {filterOptions: filterSelectOptions} : {};

    const renderTags = (value: string[]) => {
        if (!value.length) {
            return null;
        }
        const label = value.length;
        return (
            <Chip
                variant='outlined'
                label={label}
                sx={{height: 'auto', marginRight: '8px'}}
            />
        );
    };

    const renderOption = (
        props: React.HTMLAttributes<HTMLLIElement>,
        option: string,
        state: AutocompleteRenderOptionState,
    ) => (
        <li
            {...props}
            style={{padding: 0}}
        >
            <Checkbox checked={state.selected} />
            {getOptionLabel(option)}
        </li>
    );

    return (
        <Autocomplete
            fullWidth
            multiple
            {...useKeepOpen()}
            sx={{minWidth: '150px', '& .MuiInputBase-root': {paddingBottom: '6px'}}}
            disabled={disabled}
            options={finalOptions}
            getOptionLabel={getOptionLabel}
            isOptionEqualToValue={(option, value) => {
                return String(option) === String(value);
            }}
            value={value || []}
            onChange={(_event: any, newValue: string[] | null) => {
                handleChangeValue(newValue);
            }}
            renderOption={renderOption}
            renderInput={(params) => (
                <AutocompleteRenderInput
                    params={params}
                    value={value}
                    required={required}
                />
            )}
            renderTags={renderTags}
            {...additionalProps}
        />
    );
};
