import React from 'react';
import {ChartAutocompleteProps} from './types';
import {Chip, Checkbox, TextField, FilterOptionsState} from '@mui/material';
import Autocomplete, {
    AutocompleteRenderInputParams,
    AutocompleteRenderOptionState,
    createFilterOptions,
} from '@mui/material/Autocomplete';

import styles from './Chart.module.scss';

const checkAllText = 'Все';

const ChartAutocomplete = (props: ChartAutocompleteProps) => {
    const {filter, open, onOpen, setOpen, value, setValue, isSingleChoice} = props;
    const {code, possibleValues, title} = filter;

    const [inputValue, setInputValue] = React.useState('');

    // Вместо отображения выбранных значений отображаем Chip с их количеством
    const renderTags = (value: string[]) => {
        if (!value.length) {
            return null;
        }
        const label = value.length === possibleValues.length ? 'Все' : value.length;
        return (
            <Chip
                variant='outlined'
                label={label}
                className={styles.chip}
            />
        );
    };

    // Не Закрываем дропдаун на выбор элементов
    const onClose = (_event: React.SyntheticEvent, reason: string) => {
        if (reason === 'blur' || reason === 'escape' || reason === 'toggleInput') {
            setOpen(false);
        }
    };

    const onInputChange = (_event: React.SyntheticEvent, newInputValue: string) => {
        setInputValue(newInputValue);
    };

    // Кастомное отображение элементов списка с чекбоксами
    const renderOptions = (
        props: React.HTMLAttributes<HTMLLIElement>,
        option: string,
        state: AutocompleteRenderOptionState,
    ) => (
        <li {...props}>
            <Checkbox
                style={{marginRight: 8}}
                checked={
                    option === checkAllText
                        ? !!(value.length === possibleValues.length)
                        : state.selected
                }
            />
            {option}
        </li>
    );

    // Регулируем отображение чекбокса выбора Всех элементов
    const filterOptions = (options: string[], params: FilterOptionsState<string>) => {
        const filter = createFilterOptions<string>();
        const filtered = filter(options, params);

        if (!params.inputValue && options.length && !isSingleChoice) {
            return [checkAllText, ...filtered];
        }
        return filtered;
    };

    const onChange = (event: React.SyntheticEvent, newValue: string | string[] | null) => {
        if (typeof newValue === 'string') {
            setValue([newValue]);
        }
        if (Array.isArray(newValue)) {
            if (newValue.find((option) => option === checkAllText)) {
                return setValue(value.length === possibleValues.length ? [] : possibleValues);
            }
            setValue(newValue);
        }
    };

    const renderInput = (params: AutocompleteRenderInputParams) => (
        <TextField
            {...params}
            variant='standard'
            label={title}
            placeholder={value.length ? 'Выбрано' : 'Выберите'}
        />
    );

    return (
        <>
            <Autocomplete
                multiple={!isSingleChoice}
                id={code}
                open={open}
                onOpen={onOpen}
                options={possibleValues}
                getOptionLabel={(option: string | string[]) => {
                    if (Array.isArray(option)) {
                        return option[0];
                    }

                    return option;
                }}
                isOptionEqualToValue={(option, value) =>
                    option === value ||
                    (Array.isArray(option) && option[0] === value) ||
                    (Array.isArray(value) && value[0] === option) ||
                    (Array.isArray(option) && Array.isArray(value) && option[0] === value[0])
                }
                onClose={onClose}
                renderOption={isSingleChoice ? undefined : renderOptions}
                filterOptions={filterOptions}
                value={value}
                onChange={onChange}
                inputValue={inputValue}
                onInputChange={onInputChange}
                renderTags={isSingleChoice ? undefined : renderTags}
                renderInput={renderInput}
                sx={{
                    '& .MuiInputLabel-root': {
                        fontSize: '14px',
                        lineHeight: '16px',
                    },
                }}
            />
        </>
    );
};

export default ChartAutocomplete;
