import {ColumnFormats, ColumnRecord, ColumnTypes} from 'components/NewTable/types';
import {Item} from './CostContractTable/types';

export const costColumns: ColumnRecord = {
    emptyColumn: {
        id: 'emptyColumn',
        type: ColumnTypes.STRING,
        readOnly: true,
        title: '',
    },
    sumPlan: {
        id: 'sumPlan',
        type: ColumnTypes.STRING,
        readOnly: true,
        title: 'Затраты ПЛАН',
        format: ColumnFormats.CURRENCY,
        width: 160,
    },
    sumFact: {
        id: 'sumFact',
        type: ColumnTypes.STRING,
        readOnly: true,
        title: 'Затраты ФАКТ',
        format: ColumnFormats.CURRENCY,
        width: 160,
    },
    sumDeviation: {
        id: 'sumDeviation',
        type: ColumnTypes.STRING,
        readOnly: true,
        title: 'Отклонение от плана',
        format: ColumnFormats.CURRENCY,
        width: 160,
    },
    pvFact: {
        id: 'pvFact',
        type: ColumnTypes.STRING,
        readOnly: true,
        title: 'ПВ ФАКТ',
        format: ColumnFormats.CURRENCY,
        width: 160,
    },
    npvFact: {
        id: 'npvFact',
        type: ColumnTypes.STRING,
        readOnly: true,
        title: 'НПВ ФАКТ',
        format: ColumnFormats.CURRENCY,
        width: 160,
    },
    pnvFact: {
        id: 'pnvFact',
        type: ColumnTypes.STRING,
        readOnly: true,
        title: 'ПНВ ФАКТ',
        format: ColumnFormats.CURRENCY,
        width: 160,
    },
    additionalWorkFact: {
        id: 'additionalWorkFact',
        type: ColumnTypes.STRING,
        readOnly: true,
        title: 'Доп.работы ФАКТ',
        format: ColumnFormats.CURRENCY,
        width: 160,
    },
};

export const costContractColumns: ColumnRecord = {
    emptyColumn: {
        id: 'emptyColumn',
        type: ColumnTypes.STRING,
        readOnly: true,
        title: '',
    },

    plan: {
        id: 'plan',
        type: ColumnTypes.STRING,
        readOnly: true,
        title: 'ПЛАНОВЫЕ ЗАТРАТЫ',
        format: ColumnFormats.CURRENCY,
        width: 160,
        subColumns: [],
    },

    pvFact: {
        id: 'pvFact',
        type: ColumnTypes.STRING,
        readOnly: true,
        title: 'ПВ ФАКТ',
        format: ColumnFormats.CURRENCY,
        width: 160,
        subColumns: [],
    },
    npvFact: {
        id: 'npvFact',
        type: ColumnTypes.STRING,
        readOnly: true,
        title: 'НПВ ФАКТ',
        format: ColumnFormats.CURRENCY,
        width: 160,
        subColumns: [],
    },
    pnvFact: {
        id: 'pnvFact',
        type: ColumnTypes.STRING,
        readOnly: true,
        title: 'ПНВ ФАКТ',
        format: ColumnFormats.CURRENCY,
        width: 160,
        subColumns: [],
    },
    additionalWorkFact: {
        id: 'additionalWorkFact',
        type: ColumnTypes.STRING,
        readOnly: true,
        title: 'Доп.работы ФАКТ',
        format: ColumnFormats.CURRENCY,
        width: 160,
        subColumns: [],
    },
};

export const deteilesCostColumns: ColumnRecord = {
    casingColumns: {
        id: 'casingColumns',
        type: ColumnTypes.STRING,
        readOnly: true,
        title: 'Этап',
        width: 230,
    },
    price: {
        id: 'price',
        type: ColumnTypes.STRING,
        readOnly: true,
        title: 'Стоимость, руб',
        format: ColumnFormats.CURRENCY,
        width: 125,
    },
    pv: {
        id: 'pv',
        type: ColumnTypes.STRING,
        readOnly: true,
        title: 'ПВ, руб',
        format: ColumnFormats.CURRENCY,
        width: 92,
    },
    npv: {
        id: 'npv',
        type: ColumnTypes.STRING,
        readOnly: true,
        title: 'НПВ, руб',
        format: ColumnFormats.CURRENCY,
        width: 92,
    },
    pnv: {
        id: 'pnv',
        type: ColumnTypes.STRING,
        readOnly: true,
        title: 'ПНВ, руб',
        format: ColumnFormats.CURRENCY,
        width: 92,
    },
};

export const deteilesCostByDateColumns: ColumnRecord = {
    priceCategoryCode: {
        id: 'priceCategoryCode',
        type: ColumnTypes.STRING,
        readOnly: true,
        title: 'Наименование',
        width: 270,
    },
    unit: {
        id: 'unit',
        type: ColumnTypes.STRING,
        readOnly: true,
        title: 'Ед. изм.',
        width: 80,
    },
    count: {
        id: 'count',
        type: ColumnTypes.STRING,
        readOnly: true,
        title: 'Кол-во',
        width: 80,
    },
    price: {
        id: 'price',
        type: ColumnTypes.STRING,
        readOnly: true,
        title: 'Цена, руб',
        format: ColumnFormats.CURRENCY,
        width: 100,
    },
    sum: {
        id: 'sum',
        type: ColumnTypes.STRING,
        readOnly: true,
        title: 'Сумма, руб',
        format: ColumnFormats.CURRENCY,
        width: 116,
    },
}

export const costByServiceColors = [
    'rgba(0, 150, 136, 1)',
    'rgba(116, 175, 119, 1)',
    'rgba(101, 31, 255, 1)',
    'rgba(41, 109, 223, 1)',
    'rgba(0, 188, 212, 1)',
    'rgba(255, 241, 118, 1)',
    'rgba(255, 138, 101, 1)',
    'rgba(149, 117, 205, 1)',
    'rgba(225, 116, 4, 1)',
    'rgb(105, 240, 174)',
    'rgb(2, 119, 189)',
    'rgb(205, 220, 57)',
    'rgb(0, 150, 136)',
    'rgb(103, 58, 183)',
    'rgb(255, 158, 128)',
    'rgb(170, 0, 255)',
    'rgb(77, 182, 172)',
    'rgb(176, 190, 197)',
    'rgb(149, 117, 205)',
    'rgb(41, 98, 255)',
    'rgb(74, 20, 140)',
    'rgb(56, 142, 60)',
    'rgb(13, 71, 161)',
    'rgb(191, 54, 12)',
    'rgb(249, 168, 37)',
    'rgb(97, 97, 97)',
    'rgb(0, 96, 100)',
    'rgb(0, 87, 178)',
    'rgb(102, 187, 106)',
    'rgb(5, 255, 210 )',
    'rgb(41, 182, 246)',
    'rgb(98, 0, 234)',
    'rgb(255, 177, 98)',
    'rgb(0, 149, 172)',
    'rgb(255, 236, 179)',
    'rgb(216, 27, 96)',
    'rgb(253, 121, 79)',
    'rgb(156, 39, 176)',
    'rgb(92, 107, 192)',
    'rgb(77, 208, 225)',
    'rgb(46, 9, 147)',
    'rgb(165, 214, 167)',
    'rgb(209, 196, 233)',
    'rgb(254, 205, 210)',
    'rgb(185, 246, 202)',
    'rgb(136, 14, 79)',
    'rgb(255, 196, 0)',
    'rgb(106, 27, 154)',
    'rgb(238, 255, 65)',
];

export const commonCellStyles = {
    borderLeft: '1px solid var(--mui-palette-TableCell-border)',
    borderRight: '1px solid var(--mui-palette-TableCell-border)',
    textAlign: 'right' as const,
    padding: '0 4px',
    height: '28px',
};

export const commonContractCellStyles = {
    borderLeft: '1px solid var(--mui-palette-TableCell-border)',
    borderRight: '1px solid var(--mui-palette-TableCell-border)',
    textAlign: 'center' as const,
    padding: '0 4px',
    height: '28px',
};

export const additionalWorkFactStyles = {textAlign: 'right' as const, padding: '0 4px'};

export const cellConfig: {
    key: keyof Pick<Item, 'plan' | 'pvFact' | 'npvFact' | 'pnvFact' | 'additionalWorkFact'>;
    columnKey: keyof ColumnRecord;
}[] = [
    {key: 'plan', columnKey: 'plan'},
    {key: 'pvFact', columnKey: 'pvFact'},
    {key: 'npvFact', columnKey: 'npvFact'},
    {key: 'pnvFact', columnKey: 'pnvFact'},
    {key: 'additionalWorkFact', columnKey: 'additionalWorkFact'},
];

export const columnKeys = [
    'sumPlan',
    'sumFact',
    'sumDeviation',
    'pvFact',
    'npvFact',
    'pnvFact',
] as const;

export const costByDayChartColors = [
    '#296DDF',
    '#26A69A',
    '#FF8A65',
    '#7C6CF7',
    '#CC8BF8',
    '#7FC44F',
    '#00C0D8',
    '#E18504',
    '#5796FE',
    '#FFF176',
    '#EF8DCA',
    '#4183A1',
    '#61C77E',
    '#B1BE3E',
    '#B786F5',
];
