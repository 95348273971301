import {TripPressureAndMspInfo} from './TripPressureAndMspInfo';
import {TripDatesInfo} from './TripDatesInfo';
import {TripDurationsInfo} from './TripDurationsInfo';
import {TripKnbkInfo} from './TripKnbkInfo';
import {TripsLiquorsInfo} from './TripsLiquorsInfo';
import {TripNndAndConsumptionInfo} from './TripNndAndConsumptionInfo';
import {TripChiselInfo} from './TripChiselInfo';
import {useTripDetailsData} from './hooks/useTripDetailsData';

export const TripInfo = ({tripId}: {tripId: number}) => {
    const {data: tripDetails, isLoading} = useTripDetailsData(tripId);
    return (
        <>
            <TripDatesInfo
                isLoading={isLoading}
                tripDetails={tripDetails}
            />
            <TripDurationsInfo
                isLoading={isLoading}
                tripDetails={tripDetails}
            />
            <TripKnbkInfo
                isLoading={isLoading}
                tripDetails={tripDetails}
            />
            <TripChiselInfo
                isLoading={isLoading}
                tripDetails={tripDetails}
            />
            <TripNndAndConsumptionInfo
                isLoading={isLoading}
                tripDetails={tripDetails}
            />
            <TripsLiquorsInfo
                isLoading={isLoading}
                tripDetails={tripDetails}
            />
            <TripPressureAndMspInfo
                isLoading={isLoading}
                tripDetails={tripDetails}
            />
        </>
    );
};
