import {Stack} from '@mui/material';
import {formatDate} from 'utils';
import {TripDetails} from '@features/wellDesign/DashboardItems/Trips/types';
import {ValueWithLabel} from './ValueWithLabel';

export const TripDatesInfo = ({
    isLoading,
    tripDetails,
}: {
    isLoading: boolean;
    tripDetails?: TripDetails;
}) => {
    return (
        <Stack
            direction='row'
            sx={{
                display: 'grid',
                gridTemplateColumns: '1fr 1fr 1fr',
            }}
        >
            <ValueWithLabel
                label='Начало'
                isLoading={isLoading}
            >
                {formatDate(tripDetails?.timeFrom)}
            </ValueWithLabel>
            <ValueWithLabel
                label='Окончание'
                isLoading={isLoading}
            >
                {formatDate(tripDetails?.timeTo)}
            </ValueWithLabel>
            <ValueWithLabel label='Интервал, м'>
                {tripDetails?.intervalFrom !== null && tripDetails?.intervalFrom !== undefined && <>от {tripDetails.intervalFrom}</>}
                {tripDetails?.intervalTo !== null && tripDetails?.intervalTo !== undefined && <> до {tripDetails.intervalTo}</>}
            </ValueWithLabel>
        </Stack>
    );
};
