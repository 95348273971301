import {Incident} from '../types';
import {IncidentInfoMedium} from './IncidentInfoMedium';
import {IncidentInfoSmall} from './IncidentInfoSmall';

export type IncidentDetailsProps = {
    data: Incident;
    size?: 'small' | 'medium';
    withDateLink?: boolean;
    wellId: string | number;
    openTripModal: (id: number) => void;
};

export const IncidentInfo = (props: IncidentDetailsProps) => {
    if (props.size === 'small') {
        return <IncidentInfoSmall {...props} />;
    }
    return <IncidentInfoMedium {...props} />;
};
