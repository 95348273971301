export enum OperationStatus {
    SUCCESS = 'SUCCESS',
    PROCESSING = 'PROCESSING',
    ERROR = 'ERROR',
}

export enum UserStatus {
    ACTIVE = 'ACTIVE',
    BLOCKED = 'BLOCKED',
}

export type AccountDataPermission = {
    code: string;
    content?: Record<string, any>; // в зависимости от кода тут будет разный контент
};

export type AccountData = {
    id?: string;
    username: string;
    fullPermissions?: PermissionItem[]; // На фронте тут храним полные объекты permissions
    permissions: AccountDataPermission[];
    organization: string;
    phoneNumber: string | null;
    email: string | null;
    operationStatus?: OperationStatus | null;
};

export type AccountDataWithPassword = AccountData & {
    password: string | null;
    passwordConfirm: string | null;
};

export type OpenModal = {open: boolean; id?: string} | null;

export type PermissionItem = Pick<AccountDataPermission, 'content'> & {
    code: string;
    title: string;
    description: string | null;
    linkedCode: string | null;
    groupTitle: string;
};
