import {IncidentsByFieldSummary, ListPopupSummary, ListPopupSummaryGrouped} from '../types';
import {OtPbOosByWellResponse, OtPbOosSummaryGrouped} from '@services/OtPbOosService/types';

export const accordionStyles = {
    background: 'none',
    boxShadow: 'none',
    '& .Mui-expanded': {
        // Порядок стилей почему-то постоянно сбивается и это свойство переопределяется
        minHeight: '0px !important',
        margin: 0,
    },
    '& .MuiAccordionSummary-content.Mui-expanded': {
        minHeight: '0px !important',
        margin: 0,
    },
    '& .MuiPaper-root-MuiAccordion-root .Mui-expanded': {
        minHeight: '0px !important',
        margin: 0,
    },
    '& .MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded': {
        minHeight: '0px !important',
        margin: 0,
    },
    '& .MuiAccordionSummary-gutters': {
        paddingRight: 0,
    },
    marginRight: 0,
};

export const isWellSummaryType = (
    summary: ListPopupSummary | IncidentsByFieldSummary,
): summary is ListPopupSummary => {
    return (summary as IncidentsByFieldSummary).incidentsByWell === undefined;
};

export const isListPopupSummaryGrouped = (
    data: ListPopupSummaryGrouped[] | ListPopupSummary[] | IncidentsByFieldSummary[],
): data is ListPopupSummaryGrouped[] => {
    return data.every((item) => 'title' in item && 'summaries' in item);
};

export const isOtPbOosSummaryType = (
    data:  ListPopupSummaryGrouped[] | ListPopupSummary[] | OtPbOosByWellResponse,
): data is OtPbOosByWellResponse => {
    return (data[0] as OtPbOosSummaryGrouped)?.count !== undefined;
}
