import {WellTreeType} from '@domain/well/types';
import {WellEntity} from '@pages/WellsPage/types';

export const getPadsTree = (wells: WellEntity[]): WellTreeType => {
    const fields = Array.from(new Set(wells.map((w) => w.fieldTitle || '')));
    fields.sort();
    return fields.map((field) => {
        const pads: WellEntity[] = wells
            .filter((w) => w.fieldTitle === field)
            .filter((w) => !!w.padId && !!w.padTitle)
            .map((w) => ({
                ...w,
                padTitle: w.padTitle || '',
            }));
        const uniqueItems = pads.reduce((accumulator, current) => {
            // Проверяем, есть ли уже объект с таким padId в аккумуляторе
            if (!accumulator.some((item) => item?.padId === current.padId)) {
                accumulator.push(current);
            }
            return accumulator;
        }, [] as WellEntity[]);
        uniqueItems.sort((a, b) => a.padTitle!.localeCompare(b.padTitle!));
        return {
            name: field,
            padsId: uniqueItems.map((w) => w.padId!),
            pads: uniqueItems.map((pad) => {
                return {
                    id: pad.padId!,
                    name: 'Куст ' + pad.padTitle,
                };
            }),
        };
    });
};
