import DeleteIcon from '@mui/icons-material/DeleteOutlineOutlined';
import {IconButton, SvgIconOwnProps} from '@mui/material';
import {DeletePopover} from '@ui/DeletePopover/DeletePopover';
import {LightTooltip} from '@ui/LightTooltip/LightTooltip';
import React, {ReactNode} from 'react';
import {invokeAsync} from 'utils';

type Props = {
    icon?: React.ReactNode;
    onConfirm: () => void;
    title?: string;
    description?: ReactNode;
    disabled?: boolean;
    confirmText?: string;
    fontSize?: SvgIconOwnProps['fontSize'];
    onClick?: () => void;
    tooltipTitle?: string;
    buttonStyle?: object;
};
export const ButtonWithConfirm = ({
    icon,
    onConfirm,
    title = 'Вы уверены, что хотите удалить запись?',
    description,
    disabled = false,
    confirmText = 'Удалить',
    fontSize = 'small',
    onClick,
    buttonStyle,
    tooltipTitle,
}: Props) => {
    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

    const handleClickOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
        if (onClick) {
            onClick();
        }
    };

    const handleClose = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        event.stopPropagation();
        setAnchorEl(null);
    };

    const onConfirmation = (event: React.MouseEvent<HTMLButtonElement>) => {
        void invokeAsync(async () => {
            handleClose(event);
            await onConfirm();
        });
    };

    const open = Boolean(anchorEl);

    const id = open ? 'popover' : undefined;

    return (
        <>
            <LightTooltip
                title={tooltipTitle}
                enterDelay={1000}
            >
                <span>
                    <IconButton
                        aria-describedby={id}
                        onClick={handleClickOpen}
                        disabled={disabled}
                        size={fontSize === 'small' ? 'small' : 'medium'}
                        color='inherit'
                        sx={{
                            pointerEvents: 'all',
                            ...buttonStyle,
                        }}
                    >
                        {icon ? icon : <DeleteIcon fontSize={fontSize} />}
                    </IconButton>
                </span>
            </LightTooltip>
            <DeletePopover
                onConfirmation={onConfirmation}
                onClose={handleClose}
                anchorEl={anchorEl}
                title={title}
                description={description}
                confirmText={confirmText}
            />
        </>
    );
};
