import {AlertColor} from '@mui/material';
import {Alert} from '@ui/Alert/Alert';
import {CustomContentProps, SnackbarContent, useSnackbar} from 'notistack';
import {forwardRef} from 'react';

interface ReportCompleteProps extends CustomContentProps {
    title?: string;
}

export const CustomNotification = forwardRef<HTMLDivElement, ReportCompleteProps>((props, ref) => {
    const {closeSnackbar} = useSnackbar();

    const onClose = () => {
        closeSnackbar(props.id);
    };

    return (
        <SnackbarContent
            ref={ref}
            style={{width: '350px'}}
            className={props.className}
        >
            <Alert
                title={props?.title}
                text={props.message as string}
                severity={props.variant as AlertColor}
                onClose={onClose}
            />
        </SnackbarContent>
    );
});
