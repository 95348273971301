import {useEffect, useState} from 'react';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import {FormControl, MenuItem, Select as MaterialSelect, SelectChangeEvent} from '@mui/material';
import {MenuItemType, NestedTab, RouteParams} from 'utils';
import {Routes} from 'constants/routes';

type KnbkSelectProps = {
    nestedTab: NestedTab;
};

export const NestedTabSelect = ({nestedTab}: KnbkSelectProps) => {
    const navigate = useNavigate();
    const location = useLocation();
    const {pageId, wellId} = useParams<RouteParams>();
    const [page, setPage] = useState<string>(pageId || '');

    const handleChange = (event: SelectChangeEvent) => {
        if (nestedTab.isNested) {
            const child = nestedTab.children?.find((c) => c.key === event.target.value);
            if (child && child.type === MenuItemType.LiquorActualCalendar) {
                navigate(`${Routes.Calendar}/${wellId}/${pageId}`);
                return;
            }
        }
        setPage(event.target.value);
    };

    useEffect(() => {
        if (pageId) {
            const newPath = location.pathname.replace(pageId, page).concat(location.search);
            navigate(newPath);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page]);

    return (
        <FormControl
            sx={{minWidth: 86}}
            size='small'
        >
            <MaterialSelect
                value={page}
                onChange={handleChange}
                displayEmpty
                inputProps={{'aria-label': 'Without label'}}
            >
                {nestedTab.children?.map((item) => {
                    return (
                        <MenuItem
                            key={item.code}
                            value={item.code}
                        >
                            {item.label}
                        </MenuItem>
                    );
                })}
            </MaterialSelect>
        </FormControl>
    );
};
