import {memo, SyntheticEvent, useCallback, useMemo, useState} from 'react';
import {Stack, Typography} from '@mui/material';

import {OtPbOosByWellResponse} from '@services/OtPbOosService/types';
import {StackedBarWithTooltip} from '@features/incident/components/StackedBar/StackedBar';
import OtPbOosPopupSummaryAccordion from '@features/OtPbOos/OtPbOosSummaryAccordion';

type OtPbOosListProps = {
    data: OtPbOosByWellResponse;
    refetch: () => void;
    isLoading?: boolean;
};

const OtPbOosList = memo(({data, isLoading = false}: OtPbOosListProps) => {
    const formatData = useMemo(() => {
        const sumCount = data.reduce((p, c) => p + c.count, 0);

        return data.map(({title, count}) => ({
            title,
            durationSumDays: count,
            durationSumPercent: (count / sumCount) * 100,
            incidents: [],
        }));
    }, [data]);

    const [expanded, setExpanded] = useState<number| undefined>();
    const handleOnExpand = useCallback((idx: number) => (event: SyntheticEvent, isExpanded: boolean) => {
            setExpanded((prev) => {
                if (prev === undefined)
                    return idx;

                return isExpanded ? idx : undefined;
            });
    }, []);

    return (
        <>
            <StackedBarWithTooltip data={formatData} />
            <Stack
                direction='column'
                sx={{maxHeight: '100%', overflowY: 'auto', overflowX: 'hidden'}}
            >
                {data.length === 0 && !isLoading && (
                    <Typography
                        variant='body1'
                        color='var(--mui-palette-text-primary)'
                    >
                        По вашему запросу ничего не найдено. Попробуйте изменить запрос.
                    </Typography>
                )}
                {data.map((i, idx) => (
                    <OtPbOosPopupSummaryAccordion
                        key={`${i.title}_${idx}`}
                        data={i}
                        idx={idx}
                        expanded={expanded}
                        onExpand={handleOnExpand}
                    />
                ))}
            </Stack>
        </>
    );
});

export default OtPbOosList;
