import {useCallback, useState} from 'react';

export const useLoading = (defaultValue?: boolean) => {
    const [loading, setLoading] = useState(defaultValue || false);

    const loadingOn = useCallback(() => setLoading(true), []);
    const loadingOff = useCallback(() => setLoading(false), []);

    return {
        loading,
        loadingOn,
        loadingOff,
        setLoading,
    };
};
