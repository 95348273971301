import {AccountDataWithPassword, PermissionItem} from '@features/accounts/types';
import {ExpandMore, InfoOutlined} from '@mui/icons-material';
import {Checkbox, FormControlLabel, Stack, Typography} from '@mui/material';
import {useQueryClient} from '@tanstack/react-query';
import {LightTooltip} from '@ui/LightTooltip/LightTooltip';
import {UseFormSetValue} from 'react-hook-form';
import {getLinkedObjects} from '../utils';

type CommonPermissionsTreeProps = {
    watchFullPermissions: PermissionItem[];
    setValue: UseFormSetValue<AccountDataWithPassword>;
    editedPermissionCode: string;
};

export const CommonPermissionsTree = ({
    watchFullPermissions,
    setValue,
    editedPermissionCode,
}: CommonPermissionsTreeProps) => {
    const queryClient = useQueryClient();
    const permissionsList = (queryClient.getQueryData(['getPermissionsList']) ||
        []) as PermissionItem[];
    const selectedPermissionsCodes: string[] =
        watchFullPermissions.find((el) => el.code === editedPermissionCode)?.content
            ?.permissionCodes || [];

    const handleChangeAll = () => {
        if (selectedPermissionsCodes.length === permissionsList.length) {
            const newPermissions = watchFullPermissions.map((item) => {
                if (item.code === editedPermissionCode) {
                    return {
                        ...item,
                        content: {...item.content, permissionCodes: []},
                    };
                }
                return item;
            });
            setValue('fullPermissions', newPermissions);
            return;
        }
        const newPermissions = watchFullPermissions.map((item) => {
            if (item.code === editedPermissionCode) {
                return {
                    ...item,
                    content: {
                        ...item.content,
                        permissionCodes: permissionsList.map((item) => item.code),
                    },
                };
            }
            return item;
        });
        setValue('fullPermissions', newPermissions);
    };

    const handleChangeCheckbox = (el: PermissionItem) => {
        // Если уже было выбрано - удаляем
        if (selectedPermissionsCodes.includes(el.code)) {
            const newPermissions = watchFullPermissions.map((item) => {
                if (item.code === editedPermissionCode) {
                    return {
                        ...item,
                        content: {
                            ...item.content,
                            permissionCodes: item.content?.permissionCodes.filter(
                                (code: string) => code !== el.code,
                            ),
                        },
                    };
                }
                return item;
            });
            setValue('fullPermissions', newPermissions);
            return;
        }
        const newSubPermissions = getLinkedObjects(permissionsList, el);
        const newUniqueSubPermissions = newSubPermissions.filter(
            (el) => !selectedPermissionsCodes.includes(el.code),
        );
        const newUniqueSubPermissionsCodes = newUniqueSubPermissions.map((item) => item.code);
        const newPermissions = watchFullPermissions.map((item) => {
            if (item.code === editedPermissionCode) {
                return {
                    ...item,
                    content: {
                        ...item.content,
                        permissionCodes: [
                            ...selectedPermissionsCodes,
                            ...newUniqueSubPermissionsCodes,
                        ],
                    },
                };
            }
            return item;
        });
        setValue('fullPermissions', newPermissions);
    };

    const checkDisabled = (el: PermissionItem) => {
        // Если выбрано значение, у которого linkedCode равен текущему коду, то надо блокировать
        const parentPermissions = permissionsList.filter((item) => item.linkedCode === el.code);
        const parentPermissionsCodes = parentPermissions.map((item) => item.code);
        return selectedPermissionsCodes.some((code) => parentPermissionsCodes.includes(code));
    };

    if (permissionsList.length === 0) return null;

    return (
        <Stack gap={1.5}>
            <Typography variant='h4'>
                Возможность изменения доступа учетной записи к модулям
            </Typography>

            <Stack
                height='480px'
                overflow='auto'
            >
                <Stack
                    direction='row'
                    alignItems='center'
                    gap={1}
                >
                    <ExpandMore />
                    <FormControlLabel
                        label='Все'
                        sx={{flexGrow: 1}}
                        control={
                            <Checkbox
                                checked={selectedPermissionsCodes.length === permissionsList.length}
                                onChange={handleChangeAll}
                                inputProps={{'aria-label': 'controlled'}}
                                sx={{padding: '6px'}}
                            />
                        }
                    />
                </Stack>
                {permissionsList.map((item) => (
                    <Stack
                        direction='row'
                        justifyContent='space-between'
                        alignItems='center'
                        key={item.code}
                        sx={{margin: '0 8px 0 48px'}}
                    >
                        <FormControlLabel
                            label={item.title}
                            sx={{flexGrow: 1}}
                            control={
                                <Checkbox
                                    checked={selectedPermissionsCodes.includes(item.code)}
                                    onChange={() => handleChangeCheckbox(item)}
                                    inputProps={{'aria-label': 'controlled'}}
                                    sx={{padding: '6px'}}
                                    disabled={checkDisabled(item)}
                                />
                            }
                        />
                        {!!item.description && (
                            <LightTooltip title={item.description}>
                                <InfoOutlined color='action' />
                            </LightTooltip>
                        )}
                    </Stack>
                ))}
            </Stack>
        </Stack>
    );
};
