import {useCallback, useMemo, useState} from 'react';
import {UseModalState} from './types';

export function useModal<Type>(init = false): UseModalState<Type> {
    const [isVisible, setIsVisible] = useState(init);
    const [data, setData] = useState<Type>();

    const show = useCallback((newData?: Type) => {
        if (newData !== undefined) {
            setData(newData);
        }

        setIsVisible(true);
    }, []);

    const hide = useCallback((newData?: Type) => {
        if (newData !== undefined) {
            setData(newData);
        }

        setIsVisible(false);
    }, []);

    const toggle = useCallback((newData?: Type) => {
        if (newData !== undefined) {
            setData(newData);
        }

        setIsVisible((prevVisible) => !prevVisible);
    }, []);

    return useMemo(
        () => ({isVisible, show, hide, toggle, data}),
        [show, hide, toggle, isVisible, data],
    );
}
