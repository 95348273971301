import {firtsElorValue, MenuItem} from 'utils';
import styles from './Menu.module.scss';
import {MenuItemLink} from './MenuLink';
import {useState} from 'react';
import {IconButton} from '@mui/material';
import {MenuOpenOutlined} from '@mui/icons-material';

type MobileMenuProps = {menuItems: MenuItem[]; wellId?: string};

export const MobileMenu = ({menuItems, wellId}: MobileMenuProps) => {
    const [isOpen, setIsOpen] = useState(false);
    const onItemClick = () => {
        setIsOpen(false);
    };

    const handleOpenClick = () => {
        setIsOpen((prev) => !prev);
    };
    return (
        <section
            className={styles.mobileWrapper}
            id='menu-section'
        >
            <IconButton
                onClick={handleOpenClick}
                color='primary'
                size='small'
                sx={{
                    alignSelf: 'flex-end',
                    margin: '0 8px',
                    border: '1px solid rgba(var(--mui-palette-primary-mainChannel) / 0.5)',
                    borderRadius: '4px',
                }}
            >
                <MenuOpenOutlined
                    sx={{
                        transition: '.5s ease-in-out',
                        transform: isOpen ? 'rotate(0deg)' : 'rotate(180deg)',
                    }}
                />
            </IconButton>
            {menuItems.map((menuItem) => (
                <MenuItemLink
                    key={menuItem.code || firtsElorValue(menuItem.key)}
                    item={menuItem}
                    wellId={wellId ?? ''}
                    onItemClick={onItemClick}
                    isMobile
                    showTitle={isOpen}
                />
            ))}
        </section>
    );
};
