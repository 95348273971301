import {useCallback, useEffect, useRef, useState} from 'react';

export const useLocalStorage = (key: string) => {
    const [currentValue, setCurrentValue] = useState<string | null>(localStorage.getItem(key));

    const setValue = useCallback(
        (value: string) => {
            localStorage.setItem(key, value);
            // https://stackoverflow.com/questions/35865481/storage-event-not-firing
            window.dispatchEvent(
                new StorageEvent('storage', {
                    key,
                    newValue: value,
                    oldValue: currentValue,
                    storageArea: localStorage,
                }),
            );
        },
        [currentValue, key],
    );

    useEffect(() => {
        const handler = (e: StorageEvent) => {
            if (e.storageArea === localStorage && e.key === key) {
                setCurrentValue(e.newValue);
            }
        };

        window.addEventListener('storage', handler);

        return () => {
            window.removeEventListener('storage', handler);
        };
    }, [key]);

    return [currentValue, setValue] as const;
};

export const usePrevious = <T>(value: T, defaultValue?: T, filter?: (value: T) => boolean): T | undefined => {
    // See: https://reactjs.org/docs/hooks-faq.html#how-to-get-the-previous-props-or-state
    const ref = useRef<T | undefined>(defaultValue);
    useEffect(() => {
        if (!filter || filter(value)) {
            ref.current = value;
        }
    }, [value, filter]);
    return ref.current;
};

export const useEffectThrottle = (fn: () => void, deps: Array<any>, delay: number) => {
    // TODO react 18 заменить на useDeferredValue
    useEffect(() => {
        const to = setTimeout(fn, delay);
        return () => clearTimeout(to);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [delay, ...deps]);
};

export const useDidMount = (callback: () => void) => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(callback, []);
};

export const useUnmount = (callback: VoidFunction) => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => callback, []);
};

export const useDidUpdate = (callback: () => void, deps: unknown[]) => {
    const hasMountedRef = useRef(false);

    useEffect(() => {
        if (hasMountedRef.current) {
            return callback();
        } else {
            hasMountedRef.current = true;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, deps);
};

export function useRefFactory<T>(factory: () => T) {
    const ref = useRef<T>();
    if (!ref.current) {
        ref.current = factory();
    }
    return ref.current;
}
