import {Typography, Stack} from '@mui/material';
import {Incident} from '../types';
import {IncidentDetails} from './IncidentDetails';
import {IncidentFiles} from './IncidentFiles';
import {useParams} from 'react-router';
import {RouteParams} from 'utils';

export type IncidentDetailsViewProps = {
    data: Incident;
    withDateLink?: boolean;
};

export const IncidentDetailsView = ({data, withDateLink = false}: IncidentDetailsViewProps) => {
    const params = useParams<RouteParams>();

    return (
        <Stack direction='column'>
            <IncidentDetails
                data={data}
                withDateLink={withDateLink}
                wellId={data.wellId || params.wellId!}
            />
            <div style={{margin: '16px 0 8px'}}>
                <Typography variant='subtitle1'>Документы</Typography>
            </div>
            <IncidentFiles data={data} />
        </Stack>
    );
};
