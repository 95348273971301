import {useCallback, useEffect, useState} from 'react';
import {AuthService} from '../../services/AuthService';
import {KeycloakConfig} from 'keycloak-js';

export const useAuth = () => {
    const [authInfo, setAuthInfo] = useState<KeycloakConfig>();
    const [isLogin, setIsLogin] = useState<boolean>(false);

    const setAuthInfoHandle = useCallback(async () => {
        const data = await AuthService.getAuthInfo();

        if (data) {
            setAuthInfo({...data, url: data.authServerUrl});
        }
    }, []);

    const setIsLoginHandle = useCallback(async () => {
        if (authInfo) {
            const isLogin = await AuthService.init(authInfo);

            if (isLogin) {
                setIsLogin(true);
            }
        }
    }, [authInfo]);

    useEffect(() => {
        void setAuthInfoHandle();
    }, [setAuthInfoHandle]);

    useEffect(() => {
        void setIsLoginHandle();
    }, [setIsLoginHandle]);

    return isLogin;
};
