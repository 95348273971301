import {WellsService} from '@services/Wells/WellsService';
import {useQuery} from '@tanstack/react-query';

type GetInstanceParams = {
    fieldId?: number;
    page?: number;
    size?: number;
};

const wellService = WellsService.getInstance();

export const useWells = (params?: GetInstanceParams) => {
    const {page = 0, size = 1000} = params || {};

    const loadWells = () => {
        return wellService.getWells({...params, page, size});
    };

    const {data: wells} = useQuery({
        queryKey: ['/api/wells', params],
        queryFn: loadWells,
        gcTime: 0,
    });

    return {wells};
};
