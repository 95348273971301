import {AxiosResponse} from 'axios';
import FileSaver from 'file-saver';

export const getFileNameAndExtension = (data: string): [string, string] => {
    const name = data.replace(/.*=utf-8''/, '');
    const decodeName = decodeURI(name);
    const extension = name.replace(/.*\./, '');

    return [decodeName, extension];
};

export const getMimeTypeByExtension = (extension?: string) => {
    switch (extension) {
        case 'xlsx':
            return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
        case 'pdf':
            return 'application/pdf';
    }
};

export const downloadFile = (file: string, fileName: string, extension?: string) => {
    const type = getMimeTypeByExtension(extension);

    const blob = new Blob([file], {type});

    FileSaver.saveAs(blob, fileName);
};

export const downloadAndExtractFileExtension = (
    response: AxiosResponse<string, any>,
    fileName?: string,
) => {
    const [fileNameFromResponse, extension] = getFileNameAndExtension(
        response.headers['content-disposition'] || '',
    );

    downloadFile(response.data, fileName ? fileName : fileNameFromResponse, extension);
};
