import {QueryCache, QueryClient} from '@tanstack/react-query';
import {Container} from 'typedi';
import {clientToken} from '@core/di/tokens';

// const API_ENDPOINT = 'http://81.94.159.222:8080';
export const client = new QueryClient({
    defaultOptions: {
        queries: {
            retry: false,
            staleTime: 5 * 60 * 1000,
            refetchOnWindowFocus: false,
            gcTime: 0,
        },
    },

    queryCache: new QueryCache({
        onError: (error, query) => {
            // TODO сделать общий обработчик ошибок на 401 ошибку
            // @ts-ignore
            console.error('smth goes wrong', error?.request?.status, error);
        },
    }),
});

Container.set(clientToken, client);
