import React, {useState, useMemo} from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    Button,
    DialogActions,
    Checkbox,
    FormControlLabel,
} from '@mui/material';
import {ChartParams} from './types';
import styles from './Chart.module.scss';
import {SearchInput} from '@ui/SearchInput/SearchInput';

export type ModalSelectProps = {
    filter: ChartParams;
    open: boolean;
    setValue: React.Dispatch<React.SetStateAction<string[]>>;
    setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
    value: string[];
};

const checkAllText = 'Все';

const ChartModalFilter = ({filter, open, setOpenModal, setValue, value}: ModalSelectProps) => {
    const {possibleValues, title} = filter;
    const [search, setSearch] = useState('');

    const data = useMemo(() => {
        return possibleValues?.filter((value) => {
            return (
                value?.toString().startsWith(search) ||
                value?.toLowerCase().includes(search.toLowerCase())
            );
        });
    }, [possibleValues, search]);

    const handleChangeAll = () => {
        if (possibleValues.length === value.length) {
            setValue([]);
            return;
        }
        setValue(possibleValues);
    };

    const All = () => {
        if (possibleValues.length !== data.length) {
            return null;
        }

        return (
            <li className={styles.modalItem}>
                <FormControlLabel
                    label={checkAllText}
                    control={
                        <Checkbox
                            style={{marginRight: 8}}
                            checked={value.length === possibleValues.length}
                            onChange={handleChangeAll}
                            inputProps={{'aria-label': 'controlled'}}
                        />
                    }
                />
            </li>
        );
    };

    const handleChangeCheckbox = (el: string) => {
        const index = value.indexOf(el);
        if (index !== -1) {
            const arr = [...value];
            arr.splice(index, 1);
            setValue(arr);
            return;
        }
        setValue((_prev) => [..._prev, el]);
    };

    const handleUnsetAll = () => {
        setValue([]);
        return;
    };

    const handleClose = () => {
        setOpenModal(false);
    };

    const handleChoose = () => {
        setOpenModal(false);
        setValue(value);
    };

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            fullScreen
            aria-labelledby='dialog-title'
            aria-describedby='dialog-description'
        >
            <DialogTitle id='dialog-title'>{title}</DialogTitle>
            <DialogContent>
                <>
                    <div className={styles.modalActions}>
                        <SearchInput
                            value={search}
                            onInput={setSearch}
                        />
                        <Button
                            variant='text'
                            onClick={handleUnsetAll}
                            disabled={!value.length}
                        >
                            Сбросить все
                        </Button>
                    </div>
                    <div className={styles.modalListContainer}>
                        <ul className={styles.modalList}>
                            <All />
                            {data.map((el) => (
                                <li
                                    key={el}
                                    className={styles.modalItem}
                                >
                                    <FormControlLabel
                                        label={el}
                                        control={
                                            <Checkbox
                                                style={{marginRight: 8}}
                                                checked={value.includes(el)}
                                                inputProps={{'aria-label': 'controlled'}}
                                                onChange={() => handleChangeCheckbox(el)}
                                            />
                                        }
                                    />
                                </li>
                            ))}
                        </ul>
                    </div>
                </>
            </DialogContent>
            <DialogActions>
                <Button
                    variant='contained'
                    onClick={handleChoose}
                >
                    Выбрать
                </Button>
                <Button
                    variant='outlined'
                    onClick={handleClose}
                >
                    Отмена
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ChartModalFilter;
