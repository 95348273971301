import {useCallback} from 'react';
import {IncidentType, ListPopupSummary, ListPopupSummaryGrouped} from '@features/incident/types';
import {useQuery} from '@tanstack/react-query';
import {IncidentService} from '@services/IncidentService/IncidentService';
import {WellsService} from '@services/Wells/WellsService';

export const useLoadWellIncidents: (params: {
    wellId?: number | string;
    casingColumn?: string[];
    wellConstructionStage?: string[];
    wellIdList?: string[];
    incidentType?: IncidentType;
}) => {data: ListPopupSummaryGrouped[] | undefined; refetch: () => void; isLoading: boolean} = ({
    casingColumn,
    wellConstructionStage,
    wellId,
    wellIdList,
    incidentType,
}) => {
    const loadIncidentsByWell = useCallback(() => {
        if (wellId !== undefined) {
            return IncidentService.getIcindentsByWell({
                casingColumn: casingColumn,
                wellConstructionStage: wellConstructionStage,
                wellId: wellId,
                type: incidentType,
            });
        }
        return {summaries: []};
    }, [casingColumn, wellConstructionStage, wellId, incidentType]);

    const loadIncidentsByWells = useCallback(() => {
        if (wellIdList !== undefined) {
            return IncidentService.getIncidentsByWells({
                wellId: wellIdList,
                wellConstructionStage: wellConstructionStage,
                type: incidentType,
            });
        }
        return {summaries: []};
    }, [wellConstructionStage, wellIdList, incidentType]);

    const {data, refetch, isLoading} = useQuery({
        queryKey:
            wellIdList !== undefined
                ? [
                      `/dashboard/field-common-info/incidentsByWells`,
                      ...wellIdList,
                      wellConstructionStage,
                      incidentType,
                  ]
                : [
                      '/dashboard/field-common-info/incidentsByWell/',
                      wellId,
                      casingColumn,
                      wellConstructionStage,
                      incidentType,
                  ],
        queryFn: wellIdList !== undefined ? loadIncidentsByWells : loadIncidentsByWell,
        gcTime: 0,
    });
    return {data: data?.summaries || [], refetch, isLoading};
};

export const useLoadFieldIncidents: (params: {
    field: string;
    wellId?: number | string;
    wells: string[];
    wellConstructionStage: string[];
    incidentType?: IncidentType;
}) => {data: ListPopupSummary[] | undefined; refetch: () => void; isLoading: boolean} = ({
    field,
    wells,
    wellConstructionStage,
    incidentType,
}) => {
    const loadIncidentsByField = useCallback(() => {
        return IncidentService.getIcindentsByField({
            field: field,
            wellConstructionStage: wellConstructionStage,
            wellId: wells,
            type: incidentType,
        });
    }, [field, wellConstructionStage, wells, incidentType]);

    const {data, refetch, isLoading} = useQuery({
        queryKey: [
            '/dashboard/field-common-info/getIcindentsByField/',
            wells,
            field,
            wellConstructionStage,
            incidentType,
        ],
        queryFn: loadIncidentsByField,
        gcTime: 0,
    });

    return {data: data?.summaries || [], refetch, isLoading};
};

export const useLoadWells = (field: string) => {
    const loadWellsByField = () => {
        return WellsService.getInstance().getWellsByField(field);
    };
    const {data} = useQuery({
        queryKey: ['/wells/titlesByField', field],
        queryFn: loadWellsByField,
    });

    return data;
};

type UseLoadPnvProps = {
    wellId: string;
    enabled: boolean;
    casingColumn?: string[];
    wellConstructionStage?: string[];
};
export const useLoadPnv = ({
    wellId,
    casingColumn,
    wellConstructionStage,
    enabled,
}: UseLoadPnvProps) => {
    const loadPnvByWell = useCallback(() => {
        if (wellId !== undefined) {
            return IncidentService.getPnvByWell({
                casingColumn: casingColumn,
                wellConstructionStage: wellConstructionStage,
                wellId,
            });
        }
        return {summaries: []};
    }, [casingColumn, wellConstructionStage, wellId]);

    const {data, refetch, isLoading} = useQuery({
        queryKey: [
            'table/dashboard/field-common-info/pnvByWell',
            wellId,
            casingColumn,
            wellConstructionStage,
        ],
        queryFn: loadPnvByWell,
        enabled,
        gcTime: 0,
    });

    return {data: data?.summaries || [], refetch, isLoading};
};

type UseLoadAdditionalWorkProps = {
    wellId: string;
    enabled: boolean;
    casingColumn?: string[];
    wellConstructionStage?: string[];
};
export const useLoadAdditionalWork = ({
    wellId,
    casingColumn,
    wellConstructionStage,
    enabled,
}: UseLoadAdditionalWorkProps) => {
    const loadAdditionalWorkByWell = useCallback(() => {
        if (wellId !== undefined) {
            return IncidentService.getAdditionalWorkByWell({
                casingColumn: casingColumn,
                wellConstructionStage: wellConstructionStage,
                wellId,
            });
        }
        return {summaries: []};
    }, [casingColumn, wellConstructionStage, wellId]);

    const {data, refetch, isLoading} = useQuery({
        queryKey: [
            'table/dashboard/field-common-info/additionalWorksByWell',
            wellId,
            casingColumn,
            wellConstructionStage,
        ],
        queryFn: loadAdditionalWorkByWell,
        enabled,
        gcTime: 0,
    });

    return {data: data?.summaries || [], refetch, isLoading};
};
