import {IconButton, Stack, Typography} from '@mui/material';
import {IncidentFile, NpvFileType} from './types';
import React from 'react';
import {FileCard} from '@features/files/FileCard';
import {npvFileTypeNames} from './constants';
import UploadFileOutlinedIcon from '@mui/icons-material/UploadFileOutlined';
import {UploadFileCard} from '@features/files/UploadFileCard';
import {getFileIcon} from './utils';
import {FileWithProgressAndType} from './NpvFilesEdit';

export type NpvEditFileRowProps = {
    type: NpvFileType;
    files?: IncidentFile[] | null;
    filesToDelete: React.MutableRefObject<string[]>;
    filesToAdd: React.MutableRefObject<FileWithProgressAndType[]>;
    handleFileInputChange: (type: NpvFileType) => React.ChangeEventHandler<HTMLInputElement>;
    handleDelete: (file: FileWithProgressAndType) => () => void;
    onDelete: (id: string) => Promise<void>;
};

export const NpvEditFileRow = ({
    type,
    files = [],
    filesToDelete,
    filesToAdd,
    handleFileInputChange,
    onDelete,
    handleDelete,
}: NpvEditFileRowProps) => {
    return (
        <Stack sx={{marginBottom: '8px'}}>
            <Stack
                direction='row'
                gap='8px'
                justifyContent='space-between'
                alignItems='center'
            >
                <Stack
                    direction='row'
                    gap='8px'
                >
                    {getFileIcon(type, [
                        ...(files || [])
                            .filter((file) => file.type === type)
                            .filter(
                                (file) =>
                                    !filesToDelete.current.find((fileId) => file.id === fileId),
                            )
                            .map((f) => ({type: f.type})),
                        ...filesToAdd.current.map((f) => ({
                            type: f.npvType || NpvFileType.OTHER,
                        })),
                    ])}
                    <Typography variant='body1'>{npvFileTypeNames[type]}</Typography>
                </Stack>
                <IconButton sx={{marginRight: '12px'}}>
                    <UploadFileOutlinedIcon
                        color='action'
                        sx={{opacity: '.56'}}
                    />
                    <label
                        htmlFor={`fileUpload_${type}`}
                        style={{
                            position: 'absolute',
                            left: 0,
                            top: 0,
                            cursor: 'pointer',
                            width: '100%',
                            height: '100%',
                        }}
                    />
                    <input
                        multiple
                        id={`fileUpload_${type}`}
                        type='file'
                        hidden
                        onChange={handleFileInputChange(type)}
                        accept={type === NpvFileType.PHOTO ? 'image/*,.pdf,.ppt,.pptx' : undefined}
                    />
                </IconButton>
            </Stack>
            <Stack
                direction='column'
                gap='16px'
            >
                {filesToAdd.current
                    .filter((file) => file.npvType === type)
                    .map((file) => (
                        <UploadFileCard
                            key={file.uniqueId}
                            file={file}
                            onDelete={handleDelete(file)}
                            forceDelete
                            width='100%'
                        />
                    ))}
                {(files || [])
                    .filter((file) => file.type === type)
                    .filter((file) => !filesToDelete.current.find((fileId) => file.id === fileId))
                    .map((file) => (
                        <FileCard
                            key={file.id}
                            file={{
                                ...file,
                                originalFilename: file.filename || '',
                                _links: {
                                    'Скачать файл': {
                                        href: `files/${file.id}/download`,
                                    },
                                    'Удалить файл': {href: ''},
                                    self: {href: ''},
                                },
                            }}
                            width='100%'
                            onDelete={onDelete}
                        />
                    ))}
            </Stack>
        </Stack>
    );
};
