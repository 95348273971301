import {Service} from 'typedi';
import {PopoverId} from './types';
import {Atom} from 'atomar';
import React from 'react';
import {PopoverProps} from '@ui/Popover/types';
import {map} from 'rxjs';
import {withScope} from '@core/di/withScope';

@Service({global: true})
export class PopoverService {
    private items$ = Atom.create<
        Array<{
            id: PopoverId;
            Component: React.FunctionComponent<PopoverProps<any>>;
            props?: PopoverProps<any>;
        }>
    >([]);

    getItems() {
        return this.items$;
    }

    isOpened(id: PopoverId) {
        return this.items$.pipe(map((items) => items.find((i) => i.id === id)));
    }

    public open<T extends {}>(
        id: PopoverId,
        Component: React.FunctionComponent<PopoverProps<T>>,
        props: PopoverProps<T>,
    ) {
        this.items$.modify((curr) => {
            return curr
                .filter((i) => i.id !== id)
                .concat({
                    id,
                    props,
                    Component: props.scope ? withScope(props.scope, Component) : Component,
                });
        });
        return id;
    }

    public openAsModal<T extends {}>(
        id: PopoverId,
        Component: React.FunctionComponent<PopoverProps<T>>,
        props: PopoverProps<T>,
    ) {
        return this.open<T>(id, Component, {
            ...props,
            anchorOrigin: {
                horizontal: 'center',
                vertical: 'center',
            },
            transformOrigin: {
                vertical: 'center',
                horizontal: 'center',
            },
        });
    }

    public close(id: PopoverId) {
        this.items$.modify((curr) => curr.filter((i) => i.id !== id));
    }
}
