import React, {useCallback, useState} from 'react';
import {Box, Grid} from '@mui/material';
import {GridRowParams} from '@mui/x-data-grid';
import {TimeBalanceTableItem} from './types';
import TimeBalanceAnalyticPanel from './components/TimeBalanceAnalyticPanel';
import TimeBalanceTable from './components/TimeBalanceTable';

import styles from '../Dashboard.module.scss';

const TimeBalanceContent = () => {
    const [selectedTableItem, setSelectedTableItem] = useState<TimeBalanceTableItem | undefined>();

    const tableRowClickHandle = useCallback((params: GridRowParams<TimeBalanceTableItem>) => {
        setSelectedTableItem(params.row);
    }, []);

    return (
        <Grid
            container
            spacing={2}
        >
            <Grid
                item
                xs={12}
                lg={selectedTableItem ? 6 : 12}
                className={styles.contentHeight}
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                }}
            >
                <TimeBalanceTable onRowClick={tableRowClickHandle} />
            </Grid>
            {selectedTableItem && (
                <Grid
                    item
                    xs={12}
                    lg={6}
                >
                    <Box
                        className={styles.contentHeight}
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            height: '100%',
                        }}
                        component="div"
                    >
                        <TimeBalanceAnalyticPanel selectedDateItem={selectedTableItem} />
                    </Box>
                </Grid>
            )}
        </Grid>
    );
};

export default React.memo(TimeBalanceContent);
