import {Divider, Stack, Typography} from '@mui/material';
import React, {useCallback} from 'react';
import styles from '../styles.module.css';
import {ListPopupNode} from '@features/incident/types';

type ListPopupSummaryRowProps = {
    node: ListPopupNode;
    selectedNode: ListPopupNode | null;
    setSelectedNode: (incident: ListPopupNode) => void;
    summaryTitle: string;
};
export const ListPopupSummaryRow: React.FC<ListPopupSummaryRowProps> = ({
    node,
    selectedNode,
    setSelectedNode,
    summaryTitle,
}) => {
    const handleClick = useCallback(() => {
        if (!node.disableSelection) {
            setSelectedNode(node);
        }
    }, [node, setSelectedNode]);
    return (
        <>
            <Stack
                key={`${summaryTitle}_${node.title}`}
                direction='row'
                gap='4px'
                width='100%'
                justifyContent='space-between'
                alignItems='center'
                sx={{
                    cursor:
                        node.disableSelection || selectedNode?.id === node.id
                            ? undefined
                            : 'pointer',
                    padding: '2px 30px 2px 16px',
                    background:
                        selectedNode?.id === node.id
                            ? 'var(--mui-palette-action-selected)'
                            : undefined,
                }}
                onClick={handleClick}
                className={selectedNode?.id === node.id ? undefined : styles.listNodeRow}
            >
                <Typography variant='body1'>{node.title}</Typography>
                <Typography
                    variant='body2'
                    sx={{opacity: '.88'}}
                >{`${Math.ceil(node.durationHours * 100) / 100}\u00A0ч`}</Typography>
            </Stack>
            <Divider />
        </>
    );
};
