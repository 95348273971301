import {useEffect, useState} from 'react';
import {ColumnRecord, DynamicValidationConfigType} from 'components/NewTable/types';
import {cloneDeep} from 'lodash-es';

type useDynamicValidationProps<T> = {
    dynamicValidationConfig?: DynamicValidationConfigType;
    values: T;
    setValue: (key: string, newValue: any) => void;
    requiredConfig: string[];
    columns: ColumnRecord;
};

export const useDynamicValidation = <T extends {}>({
    dynamicValidationConfig,
    values,
    setValue,
    requiredConfig,
    columns,
}: useDynamicValidationProps<T>) => {
    const [currentRequiredConfig, setCurrentRequiredConfig] = useState<string[]>(requiredConfig);
    const [currentColumns, setCurrentColumns] = useState<ColumnRecord>(columns);
    const [isInitializationFinished, setIsInitializationFinished] = useState<boolean>(false);

    // значение по triggerKey
    const dynamicFieldValue = dynamicValidationConfig
        ? values[dynamicValidationConfig.triggerKey as keyof T]
        : null;

    useEffect(() => {
        if (dynamicValidationConfig && dynamicFieldValue) {
            // ищем конфигурацию для конкретного значения
            const currentConfig = dynamicValidationConfig.rules.find(
                (el) => el.key === dynamicFieldValue,
            );
            // если она отсутсвует, возвращаем дефолтную
            if (!currentConfig) {
                setCurrentColumns(columns);
                setCurrentRequiredConfig(requiredConfig);
                setIsInitializationFinished(true);
                return;
            }
            const currentRequiredFields = Object.entries(currentConfig.cellRules)
                .filter(([key, value]) => value.required)
                .flatMap(([key]) => key);

            const newColumns = cloneDeep(columns);
            const cellRules = Object.entries(currentConfig.cellRules);
            const newRequiredConfig = [
                ...currentRequiredConfig.filter((i) => currentRequiredFields.includes(i)),
            ];

            // Перебираем все поля и обновляем currentRequiredConfig и currentColumns
            cellRules.forEach(([key, value]) => {
                if (value.required) {
                    // если обязательное поле уже есть в currentRequiredConfig - пропускаем
                    if (currentRequiredConfig.includes(key)) return;
                    // если еще нет, то добавляем
                    newRequiredConfig.push(key);
                } else {
                    // если поле необязательно ищем его в конфигурации обязательных полей
                    const elToDeleteIndex = newRequiredConfig.findIndex((el) => el === key);
                    // если находим, то удаляем его из обязательных полей
                    if (elToDeleteIndex !== -1) {
                        newRequiredConfig.splice(elToDeleteIndex, 1);
                    }
                }
                // сбрасываем значение, если поле имеет ключ readOnly true
                if (value.readOnly) {
                    setValue(key, null);
                }
                newColumns[key] = {...newColumns[key], readOnly: value.readOnly};
            });

            setCurrentRequiredConfig(newRequiredConfig);
            setCurrentColumns(newColumns);
            setIsInitializationFinished(true);

            return;
        }

        setCurrentRequiredConfig(requiredConfig);
        setCurrentColumns(columns);
        setIsInitializationFinished(true);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dynamicFieldValue, dynamicValidationConfig]);

    return {
        currentRequiredConfig,
        currentColumns,
        setCurrentRequiredConfig,
        isInitializationFinished,
    };
};
