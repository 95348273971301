import {Checkbox, IconButton, TableCell} from '@mui/material';
import {CloseOutlined, Done} from '@mui/icons-material';
import styles from './NewTable.module.scss';
import {Row, TableEditRowProps} from 'components/NewTable/types';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';

type TableEditRowActionsProps<T extends Row> = Pick<
    TableEditRowProps<T>,
    'tableConfig' | 'isItemSelected' | 'onClose' | 'draggableProvided'
> & {
    onSubmit: () => void;
    isValid: boolean;
};

export const TableEditRowActions = <T extends Row>({
    tableConfig,
    isItemSelected,
    onSubmit,
    isValid,
    onClose,
    draggableProvided,
}: TableEditRowActionsProps<T>) => {
    const {canDelete, withOrders} = tableConfig;

    return (
        <>
            <TableCell
                padding='none'
                className={styles.sticky}
            >
                <span
                    {...draggableProvided?.dragHandleProps}
                    style={{
                        display: withOrders ? 'initial' : 'none',
                        verticalAlign: 'middle',
                    }}
                >
                    <DragIndicatorIcon color='disabled' />
                </span>

                {canDelete && (
                    <span>
                        <Checkbox
                            disabled
                            color='primary'
                            checked={isItemSelected}
                        />
                    </span>
                )}
                <span>
                    <IconButton
                        disabled={!isValid}
                        onClick={onSubmit}
                        title='Сохранить'
                    >
                        <Done />
                    </IconButton>
                </span>
                <span>
                    <IconButton
                        onClick={onClose}
                        title='Отменить'
                    >
                        <CloseOutlined color='error' />
                    </IconButton>
                </span>
            </TableCell>
        </>
    );
};
