import {Autocomplete, Stack, TextField} from '@mui/material';
import React from 'react';
import {useDictionary} from 'utils/hooks/useDictionary';
import {useSelector} from 'react-redux';
import {selectDictionaries} from 'slices';
import {CustomTags} from '../CustomTags';
import {useKeepOpen} from 'utils/hooks/useKeepOpen';
import {useQuery} from '@tanstack/react-query';
import {loadCasingColumns} from '@services/Npv/npvService';

type FiltersWellProps = {
    setSelectedStages: (values: string[]) => void;
    setSelectedCasingColumns: (values: string[]) => void;
    selectedCasingColumns: string[];
    selectedStages: string[];
};
export const FiltersWell: React.FC<FiltersWellProps> = ({
    setSelectedCasingColumns,
    setSelectedStages,
    selectedStages,
    selectedCasingColumns,
}) => {
    const {data: casingColumnNamesDictionary} = useQuery({
        queryKey: ['casing-columns'],
        queryFn: () => loadCasingColumns({}),
    });

    const constructionStageApi = 'WellConstructionStage';
    useDictionary({code: constructionStageApi});
    const dictionaries = useSelector(selectDictionaries);

    const casingColumnNames = casingColumnNamesDictionary?.map((el) => el.value) || [];
    const constructionStageNamesDictionary = dictionaries[constructionStageApi] || [];
    const constructionStageNames = constructionStageNamesDictionary.map((el) => el.value);

    return (
        <Stack
            direction='row'
            gap='8px'
        >
            <Autocomplete
                {...useKeepOpen()}
                multiple
                sx={{width: '50%'}}
                disableClearable
                options={constructionStageNames}
                onChange={(event, values) => {
                    setSelectedStages(values);
                }}
                renderTags={() => (
                    <CustomTags
                        dataLength={selectedStages.length}
                        onClear={() => setSelectedStages([])}
                    />
                )}
                value={selectedStages}
                renderInput={(params) => {
                    return (
                        <TextField
                            {...params}
                            label='Этап строительства'
                            variant='standard'
                        />
                    );
                }}
            />
            <Autocomplete
                {...useKeepOpen()}
                multiple
                sx={{width: '50%'}}
                disableClearable
                options={casingColumnNames}
                onChange={(event, values) => {
                    setSelectedCasingColumns(values);
                }}
                renderTags={() => (
                    <CustomTags
                        dataLength={selectedCasingColumns.length}
                        onClear={() => setSelectedCasingColumns([])}
                    />
                )}
                value={selectedCasingColumns}
                renderInput={(params) => {
                    return (
                        <TextField
                            {...params}
                            label='Обсадная колонна'
                            variant='standard'
                        />
                    );
                }}
            />
        </Stack>
    );
};
