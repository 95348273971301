import React, {memo, useMemo} from 'react';
import {useGlobalInjected} from '@core/di';
import {PopoverService} from '@services/popover/PopoverService';
import {Rx} from 'atomar';
import {PopoverId} from '@services/popover/types';
import {PopoverProps} from '@ui/Popover/types';

export const Popovers = memo(() => {
    const service = useGlobalInjected(PopoverService);
    const items$ = useMemo(() => service.getItems(), [service]);

    const createOnClose = (id: PopoverId, props?: PopoverProps) => {
        return (e: React.BaseSyntheticEvent, reason: any) => {
            id && service.close(id);
            if (props?.onClose) {
                props.onClose(e, reason);
            }
        };
    };

    return (
        <Rx value$={items$}>
            {(items) =>
                items.map(({Component, id, props}) => {
                    return (
                        <Component
                            key={id}
                            id={id}
                            {...props}
                            onClose={createOnClose(id, props)}
                            open={true}
                        />
                    );
                })
            }
        </Rx>
    );
});
