import React, {useMemo, useState} from 'react';
import {useParams} from 'react-router-dom';
import {Card, CardContent, Grid} from '@mui/material';
import {Chart, ChartCode, ChartItem} from '../../../components/Chart';
import {useChartsList} from '../useChartsList';
import {getChartSize} from '../../../components/Chart/utils';
import {RouteParams} from 'utils';
import styles from './DashboardContent.module.css';
import {IncidentListPopup} from '@features/incident/listPopups/IncidentListPopup';
import {AdditionalWorkListPopup} from '@features/incident/listPopups/AdditionalWorkListPopup';
import {PnvListPopup} from '@features/incident/listPopups/PnvListPopup';
import classNames from 'classnames';
import {getDashboardCustomConfig} from './chartsConfig';
import {GgdChart} from '@ui/GgdChart/GgdChart';

const DashboardContent = () => {
    const params = useParams<RouteParams>();
    const [chartData] = useChartsList();
    const [showIncidentsList, setShowIncidentsList] = useState<boolean>(false);
    const [showPnvList, setShowPnvList] = useState<boolean>(false);
    const [showAdditionalWorkList, setShowAdditionalWorkList] = useState<boolean>(false);
    const [fullScreenChart, setFullScreenChart] = useState<ChartItem | null>(null);

    const customChartPVNPVProps = useMemo(() => {
        return {
            style: {
                minHeight: fullScreenChart ? '65%' : '220px',
                maxWidth: fullScreenChart ? '65%' : '500px',
                minWidth: fullScreenChart ? '' : '350px',
                flex: 1,
            },
        };
    }, [fullScreenChart]);

    if (!params.wellId || !chartData.length) {
        return null;
    }

    return (
        <>
            {fullScreenChart && (
                <Card
                    className={classNames(styles.card, {
                        [styles.clickable]: fullScreenChart.code === ChartCode.TIME_NPV,
                    })}
                    style={{
                        overflow: 'visible',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100vw',
                        height: '100vh',
                        zIndex: 'var(--mui-zIndex-appBar)',
                    }}
                >
                    <CardContent className={styles.сardContent}>
                        <section className={styles.section}>
                            <Chart
                                key={fullScreenChart.code}
                                chart={fullScreenChart}
                                fullscreen={{
                                    onClick: () => {
                                        setFullScreenChart(null);
                                    },
                                    isFullscreen: true,
                                }}
                                customChartProps={
                                    fullScreenChart.code === ChartCode.WELL_OPERATIONS_PV_NPV
                                        ? customChartPVNPVProps
                                        : {}
                                }
                            />
                        </section>
                    </CardContent>
                </Card>
            )}
            <Grid
                container
                spacing={2}
            >
                {chartData.map((chart) => {
                    const chartSize = getChartSize(chart);
                    return (
                        <Grid
                            item
                            xs={12}
                            lg={chartSize.width}
                            key={chart.code}
                        >
                            <Card
                                className={classNames(styles.card, {
                                    [styles.clickable]: chart.code === ChartCode.TIME_NPV,
                                })}
                                style={{height: chartSize.height, overflow: 'visible'}}
                            >
                                <CardContent className={styles.сardContent}>
                                    {chart.code === ChartCode.GGD_FACT ? (
                                        <GgdChart
                                            chartData={chart}
                                            config={{withFullScreen: true}}
                                        />
                                    ) : (
                                        <section className={styles.section}>
                                            <Chart
                                                key={chart.code}
                                                chart={chart}
                                                customConfig={getDashboardCustomConfig({
                                                    chartCode: chart.code,
                                                    setShowAdditionalWorkList,
                                                    setShowIncidentsList,
                                                    setShowPnvList,
                                                })}
                                                fullscreen={{
                                                    onClick: () => {
                                                        setFullScreenChart(chart);
                                                    },
                                                    isFullscreen: false,
                                                }}
                                                customChartProps={
                                                    chart.code === ChartCode.WELL_OPERATIONS_PV_NPV
                                                        ? customChartPVNPVProps
                                                        : {}
                                                }
                                            />
                                        </section>
                                    )}
                                </CardContent>
                            </Card>
                        </Grid>
                    );
                })}
            </Grid>
            {showIncidentsList && (
                <IncidentListPopup
                    page='dashboard-content'
                    onClose={() => setShowIncidentsList(false)}
                />
            )}
            {showPnvList && (
                <PnvListPopup
                    page='dashboard-content-pnv'
                    onClose={() => setShowPnvList(false)}
                />
            )}
            {showAdditionalWorkList && (
                <AdditionalWorkListPopup
                    page='dashboard-content-additional-work'
                    onClose={() => setShowAdditionalWorkList(false)}
                />
            )}
        </>
    );
};

export default React.memo(DashboardContent);
