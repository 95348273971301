import {Stack, Typography} from '@mui/material';
import React, {useState} from 'react';
import {IncidentTabView} from '@features/incident/detailes/IncidentTabView';
import {
    IncidentsByFieldSummary,
    ListPopupNode,
    ListPopupSummary,
    ListPopupSummaryGrouped,
} from '@features/incident/types';
import {ListPopupSummaryAccoridon} from './ListPopupSummaryAccoridon';
import {FieldSummaryAccoridon} from '../FieldSummaryAccordion';
import {isListPopupSummaryGrouped, isWellSummaryType} from '../utils';
import {SimpleTreeView, TreeItem} from '@mui/x-tree-view';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import styles from '@pages/TrajectoryPage/TreeItem.module.css';

type ListPopupDetailsProps = {
    data: ListPopupSummary[] | IncidentsByFieldSummary[] | ListPopupSummaryGrouped[];
    refetch: () => void;
    isLoading: boolean;
};
export const ListPopupDetails: React.FC<ListPopupDetailsProps> = ({data, refetch, isLoading}) => {
    const [selectedNode, setSelectedNode] = useState<ListPopupNode | null>(null);
    return (
        <>
            {selectedNode && (
                <IncidentTabView
                    onClose={() => setSelectedNode(null)}
                    id={selectedNode.id}
                    refetch={refetch}
                    withDetailesView
                />
            )}
            <Stack
                direction='column'
                sx={{maxHeight: '100%', overflowY: 'auto', overflowX: 'hidden'}}
            >
                {data.length === 0 && !isLoading && (
                    <Typography
                        variant='body1'
                        color='var(--mui-palette-text-primary)'
                    >
                        По вашему запросу ничего не найдено. Попробуйте изменить запрос.
                    </Typography>
                )}
                {isListPopupSummaryGrouped(data)
                    ? data.map((i) => (
                        <SimpleTreeView
                            slots={{
                                collapseIcon: ExpandMoreIcon,
                                expandIcon: ChevronRightIcon,
                                endIcon: ExpandMoreIcon
                            }}
                            disableSelection={true}
                            key={i.title}
                        >
                            <TreeItem
                                itemId={i.title}
                                classes={{
                                    content: styles.treeItem,
                                }}
                                sx={{
                                    color: 'var(--mui-palette-action-active)',
                                }}
                                label={
                                    <Typography
                                        fontSize='18px'
                                        color='var(--mui-palette-text-primary)'
                                    >
                                        {i.title}
                                    </Typography>
                                }
                            >
                                {i.summaries.map((item, idx) => (
                                    <ListPopupSummaryAccoridon
                                        idx={idx}
                                        summary={item}
                                        selectedNode={selectedNode}
                                        setSelectedNode={setSelectedNode}
                                    />
                                ))}
                            </TreeItem>
                        </SimpleTreeView>
                    ))
                    : data.map((summary, idx) => {
                        return (
                            <div key={summary.title}>
                                {isWellSummaryType(summary) ? (
                                    <ListPopupSummaryAccoridon
                                        idx={idx}
                                        summary={summary}
                                        selectedNode={selectedNode}
                                        setSelectedNode={setSelectedNode}
                                    />
                                ) : (
                                    <FieldSummaryAccoridon
                                        idx={idx}
                                        summary={summary}
                                        selectedIncident={selectedNode}
                                        setSelectedIncident={setSelectedNode}
                                    />
                                )}
                            </div>
                        );
                    })}
            </Stack>
        </>
    );
};
