import {useMemo} from 'react';
import {getOptions} from '../utils';
import {ColumnData, Row} from '../types';
import {DictionaryItem} from 'utils';

type useTableAutocompleteProps = {
    column: ColumnData;
    dictionary?: DictionaryItem[] | null;
    values: Row;
};

export const useTableAutocomplete = ({column, dictionary, values}: useTableAutocompleteProps) => {
    // Отображать можем то, что лежит в value (или под ключом dictValueKey),
    // а получать/отсылать то, что будет лежить под другим ключом (например id)
    const {dictValueKey = 'value', dictIdKey = 'value'} = column;

    const options = useMemo(() => {
        return getOptions({dictionary, column, values});
    }, [dictionary, column, values]);

    const finalOptions: string[] = useMemo(() => {
        if (dictIdKey === dictValueKey) {
            // Если значения совпадают, то удаляем дубликаты и собираем данные под ключом dictValueKey
            const res = options.map((el) => String(el[dictValueKey]));
            return Array.from(new Set(res));
        }
        // Если значения не совпадают, то собираем данные под ключом dictIdKey
        return options.map((el) => String(el[dictIdKey]));
    }, [dictIdKey, dictValueKey, options]);

    const getOptionLabel = (option: string) => {
        if (dictIdKey === dictValueKey) {
            // Если значения совпадают, то отображаем данные под ключом dictValueKey
            return option;
        }
        // Иначе отображаем данные под ключом dictIdKey
        const optionObj = dictionary?.find((el) => String(el[dictIdKey]) === option);
        return optionObj?.[dictValueKey] || '';
    };
    return {finalOptions, getOptionLabel};
};
