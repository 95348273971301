import {ChartCode} from '../../../components/Chart';
import {LineOptions as G2plotConfig} from '@antv/g2plot';
import {PieConfig} from '@ant-design/plots';

type GetDashboardCustomConfigProps = {
    chartCode: ChartCode;
    setShowIncidentsList: (newValue: boolean) => void;
    setShowPnvList: (newValue: boolean) => void;
    setShowAdditionalWorkList: (newValue: boolean) => void;
};
type CustomConfig = (
    props: GetDashboardCustomConfigProps,
) => ((config: Omit<G2plotConfig, 'data' | 'tooltip'>) => G2plotConfig) | undefined;
export const getDashboardCustomConfig: CustomConfig = ({
    chartCode,
    setShowAdditionalWorkList,
    setShowIncidentsList,
    setShowPnvList,
}) => {
    if (chartCode === ChartCode.TIME_NPV) {
        return (config) => {
            (config as PieConfig).onReady = (plot) => {
                plot.chart.on('interval:click', ({event, data}: PlotIntervalClickEventData) => {
                    event.preventDefault();
                    event.stopPropagation();
                    setShowIncidentsList(true);
                });
            };

            return config as G2plotConfig;
        };
    }
    if (chartCode === ChartCode.WELL_OPERATIONS_PV_NPV) {
        return (config) => {
            (config as PieConfig).onReady = (plot) => {
                plot.chart.on(
                    'interval:click',
                    ({
                        event,
                        data: {
                            data: {name},
                        },
                    }: PlotIntervalClickEventData) => {
                        // data.name === ПВ / НПВ / ПНВ / Доп. работы
                        event.preventDefault();
                        event.stopPropagation();
                        if (name.startsWith('НПВ')) {
                            setShowIncidentsList(true);
                            setShowPnvList(false);
                            setShowAdditionalWorkList(false);
                        }
                        if (name.startsWith('ПНВ')) {
                            setShowIncidentsList(false);
                            setShowPnvList(true);
                            setShowAdditionalWorkList(false);
                        }
                        if (name.startsWith('Доп')) {
                            setShowIncidentsList(false);
                            setShowPnvList(false);
                            setShowAdditionalWorkList(true);
                        }
                    },
                );
            };

            return config as G2plotConfig;
        };
    }
    return undefined;
};

type PlotIntervalClickEventData = {
    event: MouseEvent;
    data: {
        data: {
            name: string;
            value: number;
        };
    };
};
