import {Stack, Typography} from '@mui/material';
import React from 'react';
import {TripDetails} from '@features/wellDesign/DashboardItems/Trips/types';
import {ValueWithLabel} from './ValueWithLabel';

export const TripsLiquorsInfo = ({
    isLoading,
    tripDetails,
}: {
    isLoading: boolean;
    tripDetails?: TripDetails;
}) => {
    return (
        <div>
            <Typography variant='h4'>Раствор</Typography>
            <Stack
                direction='row'
                alignItems='center'
                sx={{
                    display: 'grid',
                    gridTemplateColumns: '1fr 1fr 1fr 1fr',
                }}
            >
                <ValueWithLabel
                    label='Тип'
                    isLoading={isLoading}
                >
                    {tripDetails?.liquorType}
                </ValueWithLabel>
                <ValueWithLabel
                    label='Плотность мин, г/см³'
                    isLoading={isLoading}
                >
                    {tripDetails?.liquorDensityMin}
                </ValueWithLabel>
                <ValueWithLabel
                    label='Плотность макс, г/см³'
                    isLoading={isLoading}
                >
                    {tripDetails?.liquorDensityMax}
                </ValueWithLabel>
                <ValueWithLabel
                    label='Плотность ср., г/см³'
                    isLoading={isLoading}
                >
                    {tripDetails?.liquorDensityAvg}
                </ValueWithLabel>
            </Stack>
        </div>
    );
};
