import {TablePaginationConfig} from './types';
import {SortConfig} from 'components/NewTable/types';

export const getTableGetParams = (
    pagination: TablePaginationConfig = {
        page: 0,
        pageSize: 100,
    },
    sort: SortConfig = {},
) => {
    return {
        page: pagination.page,
        size: pagination.pageSize,
        sort: getSort(sort),
    };
};

export const getSort = (sortConfig: SortConfig): string[] => {
    if ((sortConfig && Object.keys(sortConfig).length === 0) || !sortConfig) {
        return ['id,ASC'];
    }

    const sortItems = Object.keys(sortConfig).map((key) => `${key},${sortConfig[key]}`);

    return sortItems;
};
