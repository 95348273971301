import {SnackbarProvider} from 'notistack';
import {PropsWithChildren} from 'react';
import {CustomNotification} from './CustomNotification';

export const NotificationsProvider = ({children}: PropsWithChildren) => {
    return (
        <SnackbarProvider
            maxSnack={3}
            autoHideDuration={5000}
            anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}
            Components={{
                error: CustomNotification,
                success: CustomNotification,
                warning: CustomNotification,
                info: CustomNotification,
            }}
        >
            {children}
        </SnackbarProvider>
    );
};
