import {useCallback, useEffect, useMemo, useState} from 'react';
import {AxiosError, AxiosResponse, InternalAxiosRequestConfig} from 'axios';

import {instance} from 'utils/api';

export const useAxiosLoader = () => {
    const [counter, setCounter] = useState(0);

    const inc = useCallback(() => setCounter((counter) => counter + 1), [setCounter]);
    const dec = useCallback(() => setCounter((counter) => counter - 1), [setCounter]);

    const interceptors = useMemo(
        () => ({
            request: (config: InternalAxiosRequestConfig) => {
                if (!config?.withoutGlobalLoader) {
                    inc();
                }
                return config;
            },
            response: (response: AxiosResponse) => {
                if (!response.config?.withoutGlobalLoader) {
                    dec();
                }
                return response;
            },
            error: (error: AxiosError) => {
                dec();
                return Promise.reject(error);
            },
        }),
        [inc, dec],
    );

    useEffect(() => {
        instance.interceptors.request.use(interceptors.request, interceptors.error);
        instance.interceptors.response.use(interceptors.response, interceptors.error);
    }, [interceptors]);

    return [counter > 0];
};
