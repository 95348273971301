import React, {useEffect, useState} from 'react';

import {Loader} from './Loader';
import {useAxiosLoader} from 'utils/hooks/useAxiosLoader/useAxiosLoader';

const FullScreenLoader = () => {
    const [loading] = useAxiosLoader();
    const [isOpen, setIsOpen] = useState(loading);

    useEffect(() => {
        setIsOpen(loading);
    }, [loading]);

    return <Loader show={isOpen} />;
};

export default FullScreenLoader;
