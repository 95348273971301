import {Backdrop, CircularProgress} from '@mui/material';

export const Loader = ({show = true}) => {
    return (
        <>
            {show && (
                <Backdrop
                    sx={{
                        zIndex: (theme: any) => theme.zIndex.modal + 1,
                        background: 'rgba(0, 0, 0, 0.2)',
                    }}
                    open={show}
                >
                    <CircularProgress />
                </Backdrop>
            )}
        </>
    );
};
