import {useEffect, useMemo, useState} from 'react';

export const useElementSize = <T extends Element>(): [
    React.Dispatch<React.SetStateAction<T | null>>,
    DOMRectReadOnly,
] => {
    const [ref, setRef] = useState<T | null>(null);
    const [rect, setRect] = useState<DOMRectReadOnly>(new DOMRect());

    const observer = useMemo(
        () =>
            new ResizeObserver((entries) => {
                if (entries.length <= 0) return;
                const rect = entries[0].contentRect;
                setRect(rect);
            }),
        [],
    );

    useEffect(() => {
        const lastRef = ref;
        if (!lastRef) return;
        observer.observe(lastRef);

        return () => {
            if (!lastRef) return;
            observer.unobserve(lastRef);
        };
    }, [observer, ref]);

    return [setRef, rect];
};
