import Close from '@mui/icons-material/Close';
import {
    Dialog,
    DialogActions,
    DialogTitle,
    Button,
    DialogContent,
    ButtonProps,
    SxProps,
    IconButton,
    Stack,
} from '@mui/material';
import React, {PropsWithChildren, useMemo} from 'react';

export enum SideBarDialogType {
    common = 'common',
    light = 'light',
}

type SideBarDialogProps = {
    type?: SideBarDialogType;
    open: boolean;
    onClose: () => void;
    title?: React.ReactNode;
    actions?: {
        cancel?: ButtonProps;
        submit?: ButtonProps;
        custom?: React.ReactNode;
    };
    customTitleActions?: React.ReactNode;
    customTitleBlock?: React.ReactNode;
    maxWidth?: string;
    height?: string;
    paddingBottom?: string;
    marginTop?: string;
    hideBackdrop?: boolean;
    top?: string;
    position?: 'left' | 'right';
    customStyle?: Record<string, any>;
} & PropsWithChildren;

export const SidebarDialog = ({
    open,
    onClose,
    title,
    children,
    actions,
    type = SideBarDialogType.common,
    maxWidth = '900px',
    customTitleActions,
    customTitleBlock,
    height = '100%',
    paddingBottom = '65px',
    marginTop = '140px',
    hideBackdrop = false,
    position = 'right',
    top,
    customStyle = {},
}: SideBarDialogProps) => {
    const style = useMemo(() => {
        const dialogStyle: SxProps = {
            '& .MuiDialog-container': {
                justifyContent: position === 'right' ? 'flex-end' : 'flex-start',
                '& > .MuiPaper-root': {
                    height,
                    margin: 0,
                    maxHeight: '100%',
                    borderRadius: 0,
                    marginTop,
                    maxWidth,
                    width: '100%',
                    paddingBottom,
                    top,
                    backgroundColor: 'var(--mui-palette-background-default)',
                    '& .MuiDialogContent-root': {
                        backgroundColor: 'inherit',
                    },
                    '& .MuiTypography-root': {
                        backgroundColor: 'inherit',
                    },
                    '& .MuiDialogActions-root': {
                        backgroundColor: 'inherit',
                        padding: '16px',
                    },
                },
            },
            ...customStyle,
        };
        if (type === SideBarDialogType.light) {
            dialogStyle['& .MuiBackdrop-root'] = {
                backgroundColor: 'rgba(0,0,0,0)',
            };
        }
        return dialogStyle;
    }, [position, height, marginTop, maxWidth, paddingBottom, top, customStyle, type]);
    return (
        <Dialog
            open={open}
            onClose={onClose}
            sx={style}
            scroll='paper'
            hideBackdrop={hideBackdrop}
        >
            {(title || customTitleBlock) && (
                <Stack
                    sx={{
                        backgroundColor: 'inherit',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'baseline',
                    }}
                >
                    <DialogTitle
                        variant='h2'
                        sx={{flexShrink: 1}}
                    >
                        <Stack
                            direction='row'
                            gap='16px'
                            alignItems='baseline'
                        >
                            {title || customTitleBlock}
                        </Stack>
                    </DialogTitle>
                    {customTitleActions ? (
                        customTitleActions
                    ) : (
                        <IconButton
                            onClick={onClose}
                            sx={{marginRight: '16px'}}
                        >
                            <Close />
                        </IconButton>
                    )}
                </Stack>
            )}
            <DialogContent>{children}</DialogContent>
            <DialogActions>
                {actions?.cancel && (
                    <Button
                        variant='outlined'
                        children={<>Отмена</>}
                        {...actions.cancel}
                    />
                )}
                {actions?.submit && (
                    <Button
                        children={<>Сохранить</>}
                        variant='contained'
                        color='primary'
                        {...actions.submit}
                    />
                )}
                {actions?.custom !== undefined && actions.custom}
            </DialogActions>
        </Dialog>
    );
};
