import {useCallback, useEffect, useMemo} from 'react';
import {getMenuItems} from '../../../slices/menu/helpers';
import {MenuItem as MenuItemType, RouteParams} from '../../../types/common';
import {useLocation, useParams} from 'react-router-dom';
import {Routes} from 'constants/routes';
import {useAppDispatch, useAppSelector} from 'slices/hooks';

export const useMenuItems = (): MenuItemType[] => {
    const dispatch = useAppDispatch();
    const {items} = useAppSelector((state) => state.menu);

    const params = useParams<RouteParams>();

    const location = useLocation();
    const isOnProjectsPage = useMemo(
        () =>
            location.pathname.startsWith(Routes.Projects) ||
            location.pathname.startsWith(Routes.KnbkProject),
        [location.pathname],
    );
    const getMenuType = useCallback(() => {
        if (isOnProjectsPage) {
            return 'PROJECT';
        }
        return 'WELL';
    }, [isOnProjectsPage]);
    useEffect(() => {
        dispatch(getMenuItems(getMenuType()));
    }, [dispatch, getMenuType]);

    const mappedItems = useMemo(
        () =>
            items.map((item) => ({
                ...item,
                childToRedirectTo:
                    item.children?.find((child) => params.id && child.code === params.id) ??
                    item.children?.at(0),
            })),
        [items, params.id],
    );

    return mappedItems;
};
