import api from '../../utils/api';
import {DashboardProps, FieldsCommonInfo, PadItem} from '@pages/DashboardPage/types';
import {TimeBalanceAnalyticResponse, TimeBalanceTableResponse} from '@pages/DashboardPage';
import {ChartCode, ChartFilter, ChartProps} from 'components/Chart/types';
import {BuildingWellData} from '@pages/CommonInfoPage/components/WellCommonInfo/types';
import {DictionaryItem, ParamsType} from 'utils';
import {IntroductoryData} from '@pages/CommonInfoPage/components/Intruductory/types';

export class DashboardService {
    private static instance: DashboardService;

    public static getInstance() {
        if (!DashboardService.instance) {
            DashboardService.instance = new DashboardService();
        }

        return DashboardService.instance;
    }

    private constructor() {}

    public getChartsList(wellId: number) {
        return api.get<DashboardProps>('/dashboard/chart', {wellId});
    }

    public getChartsByWellCommonInfo(wellId: number) {
        return api.get<DashboardProps>('/dashboard/common-info/chart/info', {wellId});
    }

    public getChartsForCommonInfoPage() {
        return api.get<DashboardProps>('/dashboard/field-common-info/chart/info');
    }

    public getChart(chartCode: string, filterParams: ChartFilter) {
        return api.post<ChartProps>(`/chart/${chartCode}`, filterParams, {
            withoutGlobalLoader: true,
        });
    }

    public getTimeBalanceTable(wellId: number, page: number, size = 10, sort?: string) {
        return api.get<TimeBalanceTableResponse>('/dashboard/balance/time', {
            wellId,
            size,
            sort,
            page: page - 1,
        });
    }

    public getTimeBalanceData(wellId: number, date: string) {
        return api.get<TimeBalanceAnalyticResponse>(
            '/dashboard/balance/time/detailed',
            {
                wellId,
                date,
            },
            {withoutGlobalLoader: true},
        );
    }

    public getPricesChartList(wellId: number) {
        return api.get<DashboardProps>('/dashboard/cost/chart/info', {wellId});
    }

    public getFieldsCommonInfo(): Promise<FieldsCommonInfo> {
        return api.get<FieldsCommonInfo>('/dashboard/field-common-info');
    }

    public getWellsInfoByField(params: ParamsType): Promise<PadItem[]> {
        return api.get('/dashboard/field-common-info/wellsInfoByField', params);
    }

    public getBuildingDatesInfo(wellId: string): Promise<BuildingWellData[]> {
        return api.get<BuildingWellData[]>('/dashboard/well-common-info/building-dates-info', {
            wellId,
        });
    }

    public getStageAnalyseChartList(wellId: number) {
        return api.get<DashboardProps>('/dashboard/stage-analyse/chart/info', {wellId});
    }

    public getTrajectoryChartList(wellId: number) {
        return api.get<DashboardProps>('/dashboard/trajectory/chart/info', {wellId});
    }

    public getKnbkChartList(wellId: number) {
        return api.get<DashboardProps>('/dashboard/knbk/chart/info', {wellId});
    }

    public getWellPadsByFieldId(fieldId: string) {
        return api.get<DictionaryItem[]>('/well-pads/find-by-field', {fieldId});
    }

    public getIntroductoryData(params: ParamsType) {
        return api.get<IntroductoryData[]>('/dashboard/introductory-analytics', params, {
            withoutGlobalLoader: true,
        });
    }

    public getChartsByCodes(wellId: string, ...chartCodes: ChartCode[]) {
        return api.post<DashboardProps>(`/dashboard/info`, {
            context: {
                wellId: wellId,
            },
            chartCodes: chartCodes,
        });
    }
}
