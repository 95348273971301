import {useCallback, useMemo, useState} from 'react';
import {PageDataStore, TablePaginationConfig} from 'utils/hooks/useNewPageData';
import {getTableGetParams} from 'utils/hooks/useNewPageData/utils';
import {FilterConfig, SortConfig} from 'components/NewTable/types';
import {ParamsType} from 'utils';
import {AccountData} from './types';
import {AccountsService} from '@services/Accounts/AccountsService';
import {useMutation} from '@tanstack/react-query';
import {formatDataForTables} from '@ui/NewTable/formatDataForTable';

export const useAccountsTableData = () => {
    const [store, setStore] = useState<PageDataStore<AccountData>>();

    const mutateGetAccountTableData = useMutation({
        mutationKey: ['getAccountTableData'],
        mutationFn: (params: ParamsType) => AccountsService.getData(params),
        onSuccess: (data) => {
            const formattedData = formatDataForTables<AccountData>(data);
            setStore({
                data: formattedData,
                total: formattedData?.page?.totalElements,
                page: formattedData?.page?.number,
                pageSize: formattedData?.page?.size,
            });
        },
    });

    const update = useCallback(
        (pagination?: TablePaginationConfig, sort?: SortConfig, filters?: FilterConfig) => {
            mutateGetAccountTableData.mutate({...getTableGetParams(pagination, sort), ...filters});
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    );

    return useMemo(() => [store, update] as const, [store, update]);
};
