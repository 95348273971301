import {useCallback, useMemo, useState} from 'react';
import {NewTableProps, Row, SortDirection} from 'components/NewTable/types';
import {
    getTableName,
    invokeAsync,
    normalizeSettings,
    USER_SETTINGS_TABLE_KEY,
    useUserSettings,
} from 'utils';
import {handleSelectForTable} from '@ui/NewTable/utils';
import {useLocation} from 'react-router-dom';

type useTableActionsProps<T extends Row> = Pick<
    NewTableProps<T>,
    | 'setRowsPerPage'
    | 'setUpdateDictionaries'
    | 'sortConfig'
    | 'setSortConfig'
    | 'rows'
    | 'deleteEntityHandle'
    | 'editEntityHandle'
    | 'addEntityHandle'
    | 'onUpdate'
    | 'tableName'
    | 'customEditClick'
    | 'customAddClick'
>;

export const useTableActions = <T extends Row, R = {}>({
    rows,
    deleteEntityHandle,
    editEntityHandle,
    addEntityHandle,
    onUpdate,
    sortConfig,
    setSortConfig,
    setUpdateDictionaries,
    tableName: _tableName,
    setRowsPerPage,
    customEditClick,
    customAddClick,
}: useTableActionsProps<T>) => {
    const [selected, setSelected] = useState<number[]>([]);
    const [editElement, setEditElement] = useState<T | null>(null);
    const [createMode, setCreateMode] = useState(false);
    const [openDialog, setOpenDialog] = useState<R | null>(null);

    const {pathname} = useLocation();
    const tableName = useMemo(
        () => getTableName(_tableName || '', pathname),
        [_tableName, pathname],
    );
    const {settings, changeSettings} = useUserSettings(USER_SETTINGS_TABLE_KEY);
    const tableSettings = useMemo(
        () => (tableName && settings?.[tableName]) || undefined,
        [settings, tableName],
    );

    const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            const newSelected = rows.map((n) => n.id);
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };

    const handleSelect = (_event: React.ChangeEvent<HTMLInputElement>, id: number) => {
        handleSelectForTable(id, selected, setSelected);
    };

    const handleAddRow = () => {
        if (customAddClick) {
            customAddClick();
            return;
        }
        setCreateMode(true);
    };

    const isSelected = (id: number) => selected.indexOf(id) !== -1;

    const onEditClick = (el: T | null) => {
        if (customEditClick) {
            customEditClick(el);
            return;
        }
        setEditElement(el)};

    const onDelete = (itemsToDelete: number[]) => {
        invokeAsync(async () => {
            deleteEntityHandle && (await deleteEntityHandle(itemsToDelete));

            setSelected((_prev) => {
                if (itemsToDelete.length > 1) {
                    return [];
                }
                return _prev.filter((el) => el !== itemsToDelete[0]);
            });
            onUpdate && onUpdate();
        });
    };

    const onEdit = (values: T) => {
        void invokeAsync(async () => {
            editEntityHandle && (await editEntityHandle(values));

            onUpdate && onUpdate();
            setUpdateDictionaries?.((prev) => prev + 1);
            setEditElement(null);
            setOpenDialog(null);
        });
    };

    const onCreate = (values: T) => {
        void invokeAsync(async () => {
            addEntityHandle && (await addEntityHandle(values));

            onUpdate && onUpdate();
            setUpdateDictionaries?.((prev) => prev + 1);
            setCreateMode(false);
            setOpenDialog(null);
        });
    };

    const handleOpenDialog = (data: R) => {
        setOpenDialog(data);
    };

    const handleSetRowsPerPage = useCallback(
        (rowPerPage: number) => {
            if (!setRowsPerPage) return;
            setRowsPerPage(rowPerPage);
            tableName && changeSettings({[tableName]: {...tableSettings, pageSize: rowPerPage}});
        },
        [changeSettings, setRowsPerPage, tableName, tableSettings],
    );

    const handleRequestSort = (columnId: string) => {
        if (sortConfig && setSortConfig) {
            const el = sortConfig[columnId];
            if (el) {
                if (el === SortDirection.DESC) {
                    const copy = {...sortConfig};
                    delete copy[columnId];
                    setSortConfig(copy);
                    tableName &&
                        changeSettings({
                            [tableName]: {...tableSettings, sortSettings: normalizeSettings(copy)},
                        });
                    return;
                }
                setSortConfig({...sortConfig, [columnId]: SortDirection.DESC});
                tableName &&
                    changeSettings({
                        [tableName]: {
                            ...tableSettings,
                            sortSettings: normalizeSettings({
                                ...sortConfig,
                                [columnId]: SortDirection.DESC,
                            }),
                        },
                    });
                return;
            }
            setSortConfig({...sortConfig, [columnId]: SortDirection.ASC});
            tableName &&
                changeSettings({
                    [tableName]: {
                        ...tableSettings,
                        sortSettings: normalizeSettings({
                            ...sortConfig,
                            [columnId]: SortDirection.ASC,
                        }),
                    },
                });
        }
    };

    return {
        selected,
        setSelected,
        editElement,
        setEditElement,
        createMode,
        setCreateMode,
        openDialog,
        setOpenDialog,
        handleSelectAllClick,
        handleSelect,
        handleAddRow,
        isSelected,
        onEditClick,
        onDelete,
        onEdit,
        onCreate,
        handleOpenDialog,
        handleRequestSort,
        handleSetRowsPerPage,
    };
};
