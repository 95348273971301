import {ErrorOutline} from '@mui/icons-material';
import {LightTooltip} from '@ui/LightTooltip/LightTooltip';
import {isNil} from 'lodash';

type ErrorAdornmentProps = {
    value: unknown;
    required: boolean;
    title?: string;
};

export const ErrorAdornment = ({
    value,
    required,
    title = 'Обязательное поле',
}: ErrorAdornmentProps) => {
    const validValue = !isNil(value) && value !== '' && value !== false;

    return required && !validValue ? (
        <LightTooltip title={title}>
            <ErrorOutline color='primary' />
        </LightTooltip>
    ) : null;
};
