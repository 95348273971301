import {Chip} from '@mui/material';

export const renderTags = (value: any[]) => {
    if (!value || !value.length) {
        return null;
    }
    const label = value.length;
    return (
        <Chip
            variant='outlined'
            label={label}
            sx={{
                height: 'auto',
                '& .MuiChip-label': {
                    overflow: 'visible',
                },
            }}
        />
    );
};
