import {Stack, Typography} from '@mui/material';
import React from 'react';
import {TripDetails} from '@features/wellDesign/DashboardItems/Trips/types';
import {ValueWithLabel} from './ValueWithLabel';

export const TripNndAndConsumptionInfo = ({
    isLoading,
    tripDetails,
}: {
    isLoading: boolean;
    tripDetails?: TripDetails;
}) => {
    return (
        <>
            <Stack
                direction='row'
                alignItems='center'
                sx={{
                    display: 'grid',
                    gridTemplateColumns: '1fr 1fr',
                }}
            >
                <Stack>
                    <Typography variant='h4'>ННД, тн</Typography>
                    <Stack
                        direction='row'
                        alignItems='center'
                        sx={{
                            display: 'grid',
                            gridTemplateColumns: '1fr 1fr 1fr',
                        }}
                    >
                        <ValueWithLabel
                            label='Мин'
                            isLoading={isLoading}
                        >
                            {tripDetails?.nndMin}
                        </ValueWithLabel>
                        <ValueWithLabel
                            label='Макс'
                            isLoading={isLoading}
                        >
                            {tripDetails?.nndMax}
                        </ValueWithLabel>
                        <ValueWithLabel
                            label='Среднее'
                            isLoading={isLoading}
                        >
                            {tripDetails?.nndAvg}
                        </ValueWithLabel>
                    </Stack>
                </Stack>
                <Stack>
                    <Typography variant='h4'>Расход, л/с</Typography>
                    <Stack
                        direction='row'
                        alignItems='center'
                        sx={{
                            display: 'grid',
                            gridTemplateColumns: '1fr',
                        }}
                    >
                        <ValueWithLabel
                            label='Среднее'
                            isLoading={isLoading}
                        >
                            {tripDetails?.consumptionAvg}
                        </ValueWithLabel>
                    </Stack>
                </Stack>
            </Stack>
        </>
    );
};
