import {Button, Dialog, Stack, Typography} from '@mui/material';
import {useCallback, useState} from 'react';
import {useParams} from 'react-router-dom';
import {DT_FORMATS, formatDate} from 'utils';
import {CancelActionButtons} from '@ui/CancelActionButtons/CancelActionButtons';
import dayjs, {Dayjs} from 'dayjs';
import {Warnings} from '@ui/ReportModal/Warnings';
import {downloadAndExtractFileExtension} from 'utils/api/file';
import {FinalReportBlock} from '@ui/FinalReport/FinalReportBlock';
import {FilesService} from '@services/Files/FilesService';
import {FinalReportFilters} from './FinalReportFilters';
import {ReportType} from './constants';
import {DownloadOutlined} from '@mui/icons-material';

const filesService = FilesService.getInstance();

type FinalReportDialogProps = {
    onClose: () => void;
};
export const FinalReportDialog = ({onClose}: FinalReportDialogProps) => {
    const [dateTo, setDateTo] = useState<Dayjs | null>(null);
    const [dateFrom, setDateFrom] = useState<Dayjs | null>(dayjs());
    const params = useParams();

    const [selectedStages, setSelectedStages] = useState<string[]>([]);
    const [selectedCasingColumns, setSelectedCasingColumns] = useState<string[]>([]);
    const [reports, setReports] = useState<Set<ReportType>>(new Set());
    const updateSelectedReports = useCallback(
        (newValue: ReportType) => {
            if (reports.has(newValue)) {
                reports.delete(newValue);
            } else {
                reports.add(newValue);
            }
            setReports(new Set(reports));
        },
        [reports],
    );
    const [warnings, setWarnings] = useState<string[]>([]);
    const isActionBtnDisabled =
        reports.size === 0 ||
        (reports.has(ReportType.VMR) && !dateFrom) ||
        (reports.has(ReportType.DRILLING) && !dateFrom) ||
        (reports.has(ReportType.WELL_COMPLETION) && !dateFrom);

    const onFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        let currentReportTypes = Array.from(reports);
        if (
            Array.from(reports).find(
                (item) =>
                    item === ReportType.NPV ||
                    item === ReportType.PNV ||
                    item === ReportType.ADDITIONAL_WORK,
            )
        ) {
            currentReportTypes = Array.from(reports).filter(
                (item) =>
                    item !== ReportType.NPV &&
                    item !== ReportType.PNV &&
                    item !== ReportType.ADDITIONAL_WORK,
            );

            currentReportTypes[currentReportTypes.length] = ReportType.NPV;
        }

        e.preventDefault();

        const payload = {
            datesRange: {
                dateFrom: dateFrom ? formatDate(dateFrom, `${DT_FORMATS.utcDate}T00:00:00`) : null,
                dateTo: dateTo ? formatDate(dateTo, `${DT_FORMATS.utcDate}T00:00:00`) : null,
            },
            casingColumns: selectedCasingColumns,
            wellConstructionStages: selectedStages,
            wellId: params.wellId,
            reportTypes: currentReportTypes,
            incidentTypes: Array.from(reports).filter(
                (item) =>
                    item === ReportType.NPV ||
                    item === ReportType.PNV ||
                    item === ReportType.ADDITIONAL_WORK,
            ),
        };

        filesService.getReportData('report', {wellId: params.wellId}, payload).then((result) => {
            setWarnings(result.warnings);
            filesService.downloadFile(result.id).then((file) => {
                downloadAndExtractFileExtension(file);
            });

            if (result.warnings.length === 0) {
                onClose();
            }
        });
    };

    const downloadFinalReport = () => {
        filesService.getReportData('final-report', {}, {wellId: params.wellId}).then((result) => {
            setWarnings(result.warnings);
            filesService.downloadFile(result.id).then((file) => {
                downloadAndExtractFileExtension(file);
            });

            if (result.warnings.length === 0) {
                onClose();
            }
        });
    };

    return (
        <Dialog
            open
            PaperProps={{
                sx: {
                    padding: '16px',
                    maxWidth: '700px',
                },
            }}
        >
            <Stack
                direction='row'
                alignItems='center'
                justifyContent='space-between'
                marginBottom='16px'
            >
                <Typography variant='h4'>Скачать отчет</Typography>
                <Button
                    variant='text'
                    color='primary'
                    startIcon={<DownloadOutlined />}
                    onClick={downloadFinalReport}
                >
                    Финальный отчет
                </Button>
            </Stack>
            <FinalReportFilters
                dateFrom={dateFrom}
                dateTo={dateTo}
                selectedCasingColumns={selectedCasingColumns}
                selectedStages={selectedStages}
                setDateFrom={setDateFrom}
                setDateTo={setDateTo}
                setSelectedCasingColumns={setSelectedCasingColumns}
                setSelectedStages={setSelectedStages}
            />
            <form onSubmit={onFormSubmit}>
                <Stack gap='8px'>
                    <FinalReportBlock
                        title='Суточные отчеты'
                        reports={[ReportType.VMR, ReportType.DRILLING, ReportType.WELL_COMPLETION]}
                        allReports={reports}
                        updateSelectedReports={updateSelectedReports}
                    />
                    <FinalReportBlock
                        title='Дизайн скважины'
                        reports={[
                            ReportType.WELL_CONSTRUCTION,
                            ReportType.TRAJECTORY,
                            ReportType.DRILLING_LIQUORS,
                            ReportType.CHISEL,
                            ReportType.BHA_RUN,
                        ]}
                        allReports={reports}
                        updateSelectedReports={updateSelectedReports}
                    />
                    <FinalReportBlock
                        title='Расход материалов'
                        reports={[
                            ReportType.MTR,
                            ReportType.CHEMICAL_REAGENT_CONSUMPTION,
                            ReportType.TECHNIQUE,
                        ]}
                        allReports={reports}
                        updateSelectedReports={updateSelectedReports}
                    />
                    <FinalReportBlock
                        title='Инциденты'
                        reports={[ReportType.NPV, ReportType.PNV, ReportType.ADDITIONAL_WORK]}
                        allReports={reports}
                        updateSelectedReports={updateSelectedReports}
                    />
                    <FinalReportBlock
                        title='Предписания'
                        reports={[ReportType.OT_PB]}
                        allReports={reports}
                        updateSelectedReports={updateSelectedReports}
                    />
                    <FinalReportBlock
                        title='Отчет по срокам строительства'
                        reports={[ReportType.BKV]}
                        allReports={reports}
                        updateSelectedReports={updateSelectedReports}
                    />
                </Stack>
                <Warnings warnings={warnings} />
                <CancelActionButtons
                    handleClose={onClose}
                    isActionBtnDisabled={isActionBtnDisabled}
                />
            </form>
        </Dialog>
    );
};
