import {Stack, Typography} from '@mui/material';
import React from 'react';
import {TripDetails} from '@features/wellDesign/DashboardItems/Trips/types';
import {ValueWithLabel} from './ValueWithLabel';

export const TripPressureAndMspInfo = ({
    isLoading,
    tripDetails,
}: {
    isLoading: boolean;
    tripDetails?: TripDetails;
}) => {
    return (
        <Stack
            direction='row'
            alignItems='center'
            sx={{
                display: 'grid',
                gridTemplateColumns: '1fr 1fr',
            }}
        >
            <Stack>
                <Typography variant='h4'>Давление, атм</Typography>
                <Stack
                    direction='row'
                    alignItems='center'
                    sx={{
                        display: 'grid',
                        gridTemplateColumns: '1fr 1fr',
                    }}
                >
                    <ValueWithLabel
                        label='Мин'
                        isLoading={isLoading}
                    >
                        {tripDetails?.pressureMin}
                    </ValueWithLabel>
                    <ValueWithLabel
                        label='Макс'
                        isLoading={isLoading}
                    >
                        {tripDetails?.pressureMax}
                    </ValueWithLabel>
                </Stack>
            </Stack>
            <Stack>
                <Typography variant='h4'>МСП, м/ч</Typography>
                <Stack
                    direction='row'
                    alignItems='center'
                    sx={{
                        display: 'grid',
                        gridTemplateColumns: '1fr 1fr 1fr',
                    }}
                >
                    <ValueWithLabel
                        label='Средняя'
                        isLoading={isLoading}
                    >
                        {tripDetails?.mspAvg}
                    </ValueWithLabel>
                    <ValueWithLabel
                        label='Рейсовая'
                        isLoading={isLoading}
                    >
                        {tripDetails?.mspTrip}
                    </ValueWithLabel>
                    <ValueWithLabel
                        label='Циркуляционная'
                        isLoading={isLoading}
                    >
                        {tripDetails?.mspCirculation}
                    </ValueWithLabel>
                </Stack>
            </Stack>
        </Stack>
    );
};
