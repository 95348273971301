import {DeleteSweepOutlined, EditOutlined, InfoOutlined} from '@mui/icons-material';
import {Checkbox, FormControlLabel, IconButton, Stack, Typography} from '@mui/material';
import {useQueryClient} from '@tanstack/react-query';
import {PermissionItem, AccountDataWithPassword} from '@features/accounts/types';
import {LightTooltip} from '@ui/LightTooltip/LightTooltip';
import {UseFormSetValue} from 'react-hook-form';
import {getLinkedObjects} from '../utils';
import {editedPermissionsCodes} from '../constants';

type PermissionsTreeSecondGroupProps = {
    selectedPermissions: PermissionItem[];
    setValue: UseFormSetValue<AccountDataWithPassword>;
    groupTitle: string;
    setEditedPermissionCode: React.Dispatch<React.SetStateAction<string | null>>;
    editedPermissionCode: string | null;
};

export const PermissionsTreeSecondGroup = ({
    selectedPermissions,
    setValue,
    groupTitle,
    setEditedPermissionCode,
    editedPermissionCode,
}: PermissionsTreeSecondGroupProps) => {
    const queryClient = useQueryClient();
    const permissionsList = (queryClient.getQueryData(['getPermissionsList']) ||
        []) as PermissionItem[];

    const currentPermissionsByGroupTitle = permissionsList.filter(
        (el) => el.groupTitle === groupTitle,
    );

    const handleDeselectAll = () => {
        // Удлаяем все пермишены ДАННОЙ ГРУППЫ
        const newPermissions = selectedPermissions.filter((el) => el.groupTitle !== groupTitle);
        setValue('fullPermissions', newPermissions);
        setEditedPermissionCode(null);
    };

    const handleChangeCheckbox = (permission: PermissionItem) => {
        const index = selectedPermissions.findIndex((el) => el.code === permission.code);
        // Если уже было выбрано - удаляем
        if (index !== -1) {
            const arr = [...selectedPermissions];
            arr.splice(index, 1);
            setValue('fullPermissions', arr);
            if (permission.code === editedPermissionCode) setEditedPermissionCode(null);
            return;
        }
        const newPermission =
            permission.code === 'HAS_ALLOWED_PERMISSION_TO_ASSIGN_LIMITATIONS'
                ? {
                      ...permission,
                      content: {
                          type: 'allowedPermissionToSet',
                      },
                  }
                : permission;
        const newPermissions = getLinkedObjects(permissionsList, newPermission);
        const newUniquePermissions = newPermissions.filter(
            (el) => !selectedPermissions.some((item) => item.code === el.code),
        );
        setValue('fullPermissions', [...selectedPermissions, ...newUniquePermissions]);
        if (editedPermissionsCodes.includes(permission.code)) {
            setEditedPermissionCode(permission.code);
        } else {
            setEditedPermissionCode(null);
        }
    };

    const handleEditClick = (code: string) => {
        setEditedPermissionCode(code);
    };

    if (currentPermissionsByGroupTitle.length === 0) return null;

    return (
        <Stack gap={0.5}>
            <Stack
                direction='row'
                justifyContent='space-between'
                alignItems='center'
            >
                <Typography variant='h4'>{groupTitle}</Typography>
                <Stack
                    direction='row'
                    gap={0.5}
                >
                    <IconButton onClick={handleDeselectAll}>
                        <DeleteSweepOutlined />
                    </IconButton>
                </Stack>
            </Stack>
            <Stack
                padding='0 10px'
                gap={0.5}
            >
                {currentPermissionsByGroupTitle.map((permission) => {
                    const isSelected = selectedPermissions.some(
                        (el) => el.code === permission.code,
                    );
                    return (
                        <Stack
                            direction='row'
                            key={permission.code}
                            justifyContent='space-between'
                            alignItems='center'
                        >
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={isSelected}
                                        sx={{
                                            padding: '4px',
                                            marginRight: '4px',
                                            alignSelf: 'baseline',
                                        }}
                                        inputProps={{'aria-label': 'controlled'}}
                                        onChange={() => handleChangeCheckbox(permission)}
                                        disabled={selectedPermissions.some(
                                            (el) => el.linkedCode === permission.code,
                                        )}
                                    />
                                }
                                sx={{marginLeft: 0, width: '100%'}}
                                label={<Typography variant='body1'>{permission.title}</Typography>}
                            />
                            {editedPermissionsCodes.includes(permission.code) && (
                                <IconButton
                                    onClick={() => handleEditClick(permission.code)}
                                    disabled={!isSelected}
                                    size='small'
                                    sx={{height: '32px'}}
                                >
                                    <EditOutlined />
                                </IconButton>
                            )}
                            {!!permission.description && (
                                <LightTooltip title={permission.description}>
                                    <InfoOutlined color='action' />
                                </LightTooltip>
                            )}
                        </Stack>
                    );
                })}
            </Stack>
        </Stack>
    );
};
