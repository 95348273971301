import {Visibility, VisibilityOff} from '@mui/icons-material';
import {IconButton, InputAdornment, TextField} from '@mui/material';
import {forwardRef, useState} from 'react';
import {getRequiredAdornment} from 'utils/functions/getRequiredAdornment';

export const PasswordField = forwardRef((props: any) => {
    const [showPassword, setShowPassword] = useState(false);
    const {required, ...rest} = props;

    return (
        <TextField
            {...rest}
            autoComplete='new-password'
            size='small'
            variant='outlined'
            type={showPassword ? 'text' : 'password'}
            sx={{
                '& .MuiInputBase-root': {
                    paddingRight: '4px',
                },
                flex: 1,
            }}
            InputProps={{
                endAdornment: (
                    <InputAdornment position='end'>
                        <IconButton
                            onClick={() => setShowPassword(!showPassword)}
                            onMouseDown={(e: any) => e.preventDefault()}
                        >
                            {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                    </InputAdornment>
                ),
                ...getRequiredAdornment({
                    value: props.value,
                    required: required,
                }),
            }}
        />
    );
});
