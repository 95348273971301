import DoNotDisturbOnOutlinedIcon from '@mui/icons-material/DoNotDisturbOnOutlined';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import {IncidentType, NpvFileType} from './types';

export const getFileIcon = (type: NpvFileType, files: {type: NpvFileType}[]) => {
    return (files || []).filter((file) => file.type === type).length === 0 ? (
        <DoNotDisturbOnOutlinedIcon color='warning' />
    ) : (
        <CheckCircleOutlinedIcon color='success' />
    );
};

export const incidentTypeMap: Record<string, IncidentType> = {
    НПВ: IncidentType.NPV,
    ПНВ: IncidentType.PNV,
    'Доп. работы': IncidentType.ADDITIONAL_WORK,
};

export const fullIncidentTypeMap: Record<string, IncidentType> = {
    'Непроизводительное время': IncidentType.NPV,
    ПНВ: IncidentType.PNV,
    'Дополнительные работы': IncidentType.ADDITIONAL_WORK,
};

export const incidentMap: Record<IncidentType, string> = {
    [IncidentType.NPV]: 'НПВ',
    [IncidentType.PNV]: 'ПНВ',
    [IncidentType.ADDITIONAL_WORK]: 'Доп. работы',
};

export const getIncidentType = (value: string): IncidentType | undefined => {
    return incidentTypeMap[value];
};
export const getIncidentTypeByFullValue = (value: string): IncidentType | undefined => {
    return fullIncidentTypeMap[value];
};

export const getIncidentValue = (value: IncidentType): string => {
    return incidentMap[value];
};

export const isIncidentType = (value: any): value is IncidentType => {
    return Object.values(IncidentType).includes(value);
};
