import {Stack} from '@mui/material';
import {IncidentList} from '@features/incident/listPopups/listPopup/ListPopupData';
import {ListPopupSummary} from '@features/incident/types';

type TripIncidentsProps = {
    data: ListPopupSummary[] | undefined;
    refetch: () => void;
};

export const TripIncidents = ({data, refetch}: TripIncidentsProps) => {
    if (!data) {
        return null;
    }
    return (
        <Stack
            gap='10px'
            sx={{overflow: 'hidden', height: '100%'}}
        >
            <IncidentList
                data={data}
                refetch={refetch}
            />
        </Stack>
    );
};
