import {SettingsMenuItem} from '@ui/SettingsMenu/types';
import React, {ReactNode} from 'react';
import {DraggableProvided} from 'react-beautiful-dnd';
import {DictionaryItem} from 'utils';

export type Row = Record<string, any>;

export type Rows = Row[];

export enum ColumnTypes {
    NUMBER = 'number',
    STRING = 'string',
    INTEGER = 'integer',
    FILE = 'file',
    BOOLEAN = 'boolean',
    ARRAY = 'array',
    OBJECT = 'object',
}

export enum ColumnFormats {
    DATE_TIME = 'date-time',
    DATE = 'date',
    CURRENCY = 'currency',
}

export enum RowEditType {
    INLINE = 'INLINE',
    POPUP = 'POPUP',
}

export enum ActionType {
    OPERATION_INCIDENT = 'OPERATION_INCIDENT',
    DATE_TIME_SELECT_ONLY_TIME = 'DATE_TIME_SELECT_ONLY_TIME',
}

export type ColumnData = {
    id: string;
    title: string;
    width?: number;
    readOnly?: boolean;
    type: ColumnTypes;
    dictName?: string;
    dictValueKey?: string; // что отображаем
    dictIdKey?: string; // что отправляем/получаем
    filterDictName?: string;
    isFilter?: boolean;
    dictKeyProperty?: string; // ключ поля, от которого зависим
    dictIsChangeable?: boolean; // можно ли добавлять новое значение
    format?: ColumnFormats;
    properties?: any;
    required?: string[];
    actionType?: ActionType;
    default?: string;
    description?: string;
    keyTableAttr?: string; // ключ поля, для обновления значения родителя из зависимого поля
    customDisplayFormat?: (rowId: string | number | undefined, value: any) => React.ReactNode;
    getFilterLabel?: (value: any) => string;
    withoutSort?: boolean;
    subColumns?: ColumnData[];
};

export type ColumnRecord = Record<string, ColumnData>;

export type DynamicValidationCellRules = Record<string, {readOnly: boolean; required: boolean}>;

export type DynamicValidationRules = {
    key: string;
    cellRules: DynamicValidationCellRules;
};

export type DynamicValidationConfigType = {
    triggerKey: string;
    rules: DynamicValidationRules[];
};

export type TableConfig = {
    canEdit: boolean;
    canDelete: boolean;
    canCreate: boolean;
    withPagination: boolean;
    elementsMaxLimit?: number;
    sort: {
        changeable: boolean;
        defaultSort: SortConfig;
    };
    emptyCell?: boolean;
    withOrders?: boolean;
    editType?: RowEditType;
    dynamicValidationConfig?: DynamicValidationConfigType;
};

export type TableHeadProps = {
    selected?: number[];
    onRequestSort?: (property: string) => void;
    onSelectAllClick?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    sortConfig?: SortConfig | null;
    rowCount?: number;
    columns: ColumnRecord;
    tableConfig: TableConfig;
    disableAction?: boolean;
    onDelete?: (itemsToDelete: number[]) => void;
    onDeleteClick?: (id: string[]) => void;
    confirmDeletingDescription?: ReactNode;
    onAdd?: () => void;
    totalElements?: number;
    minHeight?: string;
    cellClassName?: string;
    filterConfig?: FilterConfig;
    setFilterConfig?: React.Dispatch<React.SetStateAction<FilterConfig>>;
    renderAdditionalHeaderActions?: ({selected}: {selected: number[]}) => SettingsMenuItem[];
    filterRequestData?: Record<string, any>;
};

export enum SortDirection {
    ASC = 'ASC',
    DESC = 'DESC',
}

export type SortConfig = Record<string, SortDirection>;

export type NewTableProps<T extends Row> = {
    tableName?: string;
    tableConfig: TableConfig;
    requiredConfig?: string[];
    columns: ColumnRecord;
    rows: T[];
    addEntityHandle?: (values: any) => Promise<void> | void;
    editEntityHandle?: (values: any) => Promise<void> | void;
    deleteEntityHandle?: (ids: number[]) => Promise<void> | void;
    onUpdate?: () => void;
    setSortConfig?: React.Dispatch<React.SetStateAction<SortConfig>>;
    sortConfig?: SortConfig;
    totalElements?: number;
    setPage?: (page: number) => void;
    setRowsPerPage?: (rowsPerPage: number) => void;
    page?: number;
    rowsPerPage?: number;
    initialRow?: T;
    onChangeOrder?: (orders: Array<string | number>) => Promise<any> | void;
    hoveredElement?: number | null;
    EditRowComponent?: React.FunctionComponent<any>;
    EditDialog?: React.FunctionComponent<any>;
    dynamicValidationConfig?: DynamicValidationConfigType;
    setUpdateDictionaries?: React.Dispatch<React.SetStateAction<number>>;
    confirmDeletingDescription?: ReactNode;
    onDeleteClick?: (id: string[]) => void;
    hideNestedTab?: boolean;
    filterConfig?: FilterConfig;
    setFilterConfig?: React.Dispatch<React.SetStateAction<FilterConfig>>;
    customEditClick?: (value: any) => void;
    customAddClick?: () => void;
    AdditionalBottomRow?: React.FunctionComponent<any>;
    renderAdditionalRowActions?: ({row}: {row: T}) => SettingsMenuItem[];
    renderAdditionalHeaderActions?: ({selected}: {selected: number[]}) => SettingsMenuItem[];
    filterRequestData?: Record<string, string>;
};

export type TableRowCommonProps<T extends Row> = {
    columns: ColumnRecord;
    row: T;
    tableConfig: TableConfig;
    isItemSelected?: boolean;
    draggableProvided?: DraggableProvided;
};
export type TableSimpleRowProps<T extends Row> = TableRowCommonProps<T> & {
    disableAction?: boolean;
    confirmDeletingDescription?: ReactNode;
    onDeleteClick?: (id: string[]) => void;
    onDelete?: (itemsToDelete: number[]) => void;
    onEditClick?: (el: T | null | any) => void;
    handleSelect?: (_event: React.ChangeEvent<HTMLInputElement>, id: number) => void;
    renderAdditionalRowActions?: ({row}: {row: T}) => SettingsMenuItem[];
};

export type TableEditRowProps<T extends Row> = TableRowCommonProps<T> & {
    onEdit: (values: T) => void;
    onCreate: (values: T) => void;
    onClose: () => void;
    requiredConfig: string[];
    dynamicValidationConfig?: DynamicValidationConfigType;
};

export type GetFormFieldProps = {
    valueKey: string;
    values: Row;
    setValue: (key: string, value: any) => void;
    dictionary?: DictionaryItem[] | null;
    column: ColumnData;
    disabled?: boolean;
    CustomComponent?: any;
    customProps?: Record<string, any>;
    required: boolean;
};

export type FormatValueToDisplayProps = {
    type: ColumnTypes;
    format?: ColumnFormats;
    value: any;
    columnWidth?: number;
    actionType?: ActionType;
    customDisplayFormat?: (rowId: string | number | undefined, value: any) => React.ReactNode;
    rowId?: string | number;
};

export type TablePaginationProps = Pick<
    NewTableProps<any>,
    'totalElements' | 'page' | 'rowsPerPage' | 'setPage' | 'setRowsPerPage'
>;

export type ordersDataType = Record<number | string, number>;

export type setElementsOrderType = (orders: ordersDataType) => Promise<any> | any;

export type DayInfo = {
    temperatureMin: number | null;
    temperatureMax: number | null;
    nextDayPlan: string | null;
    comment: string | null;
};

export type FilterConfig = Record<string, any[]>;
