export const isNegative = (num: number) => {
    if (Math.sign(num) === -1) {
        return true;
    }

    return false;
};

export const formatNumber = (number: number | string) => {
    // Вставляет пробелы на каждые 000
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
};

export const changeNumberField = (
    value: string,
    onChange: (value: string) => void,
    pattern: RegExp = /^\d*$/,
) => {
    const trimValue = value.trim();
    if (!pattern.test(trimValue) && value !== '') return;

    onChange(trimValue);

    return trimValue;
};

export const formateStringAsNumber = ({
    value,
    isInteger = true,
    onlyPositive = true,
}: {
    value: string;
    isInteger?: boolean;
    onlyPositive?: boolean;
}) => {
    let result = value.replace('e', '');
    if (isInteger) {
        result = result.replace('.', '').replace(',', '');
    }
    if (onlyPositive) {
        result = result.replace('-', '');
    }
    return result;
};
