import {Box, Stack, Typography} from '@mui/material';
import {AccountModalPadsTree} from './AccountModalPadsTree';
import {AccountDataWithPassword, PermissionItem} from '../../types';
import {UseFormSetValue} from 'react-hook-form';

type PadsTreeWrapperProps = {
    watchFullPermissions: PermissionItem[];
    setValue: UseFormSetValue<AccountDataWithPassword>;
    editedPermissionCode: string;
};

export const PadsTreeWrapper = ({
    watchFullPermissions,
    setValue,
    editedPermissionCode,
}: PadsTreeWrapperProps) => {
    const permittedPadIds = watchFullPermissions.find((el) => el.code === editedPermissionCode)
        ?.content?.padIds;

    const setSelectedPads = (pads: Set<number>) => {
        // Значение для данного поля хранится ВНУТРИ permission
        const newPermissons = watchFullPermissions.map((el) => {
            if (el.code === editedPermissionCode) {
                return {...el, content: {...el.content, padIds: Array.from(pads), type: 'wellPad'}};
            }
            return el;
        });
        setValue('fullPermissions', newPermissons);
    };

    return (
        <Stack gap={1.5}>
            <Typography variant='h4'>Доступ к кустам</Typography>
            <Box
                height='500px'
                overflow='auto'
                component='div'
            >
                <AccountModalPadsTree
                    selectedPads={new Set(permittedPadIds || [])}
                    setSelectedPads={setSelectedPads}
                />
            </Box>
        </Stack>
    );
};
