import api from 'utils/api';
import {useQuery} from '@tanstack/react-query';
import {TripDetails} from '@features/wellDesign/DashboardItems/Trips/types';

export const useTripDetailsData = (id: string | number) => {
    const loadTripDetails = () =>
        api.get<TripDetails>(`/bha-runs/${id}`, {}, {withoutGlobalLoader: true});

    const {data, isLoading} = useQuery({
        queryKey: ['/bha-runs', id],
        queryFn: loadTripDetails,
    });

    return {data, isLoading};
};
