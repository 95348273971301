import * as React from 'react';
import {styled} from '@mui/material/styles';
import Tooltip, {TooltipProps, tooltipClasses} from '@mui/material/Tooltip';

type Props = {
    //maxWidth?: number;
    offset?: number[];
    followCursor?: boolean;
    tooltipClassName?: string;
} & TooltipProps;

export const LightTooltip = styled(
    ({
        className,
        /*maxWidth = 300,*/ offset,
        followCursor = true,
        tooltipClassName,
        ...props
    }: Props) => {
        return (
            <Tooltip
                followCursor={followCursor}
                placement='bottom-start'
                enterTouchDelay={0}
                slotProps={{
                    // TODO если надо будет переопределить, то надо тут spread делать по всем слотам
                    popper: {
                        modifiers: [
                            {
                                name: 'offset',
                                options: {
                                    offset: offset || [10, 10],
                                },
                            },
                        ],
                        sx: {
                            [`&.${tooltipClasses.popper}[data-popper-placement*="bottom"] .${tooltipClasses.tooltip}`]:
                                {
                                    marginTop: '4px',
                                },
                            [`&.${tooltipClasses.popper}[data-popper-placement*="top"] .${tooltipClasses.tooltip}`]:
                                {
                                    marginBottom: '4px',
                                },
                            [`&.${tooltipClasses.popper}[data-popper-placement*="right"] .${tooltipClasses.tooltip}`]:
                                {
                                    marginLeft: '4px',
                                },
                            [`&.${tooltipClasses.popper}[data-popper-placement*="left"] .${tooltipClasses.tooltip}`]:
                                {
                                    marginRight: '4px',
                                },
                        },
                    },
                }}
                /* sx={[
                (theme) => ({
                    [`& .${tooltipClasses.tooltip}`]: {
                        backgroundColor: theme.palette.common.white,
                        color: theme.palette.text.secondary,
                        boxShadow: theme.shadows[1],
                        maxWidth: maxWidth + 'px',
                    },
                }),
            ]} */
                {...props}
                classes={{popper: className, tooltip: tooltipClassName}}
            />
        );
    },
)(({theme}) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor:
            theme.palette.mode === 'light'
                ? theme.palette.common.white
                : 'var(--mui-palette-paper_elevation-16)',
        color: theme.palette.text.secondary,
        boxShadow: theme.shadows[6],
    },
}));
