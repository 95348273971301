import React, {useMemo, useState} from 'react';
import styles from './styles.module.css';
import {useParams} from 'react-router-dom';
import {RouteParams} from 'utils';
import {FiltersWell} from './filters/FiltersWell';
import {useLoadAdditionalWork} from './hooks';
import {AdditionalWorkByWellSummary, ListPopupSummary} from '../types';
import {ListPopup} from './listPopup/ListPopup';

export const transformAdditionalWorkData: (
    additionalWork: AdditionalWorkByWellSummary[],
) => ListPopupSummary[] | undefined = (additionalWork) => {
    if (!additionalWork) {
        return undefined;
    }

    return additionalWork.map((work) => ({
        title: work.workCategory,
        durationSumDays: work.durationSumDays,
        durationSumPercent: work.durationSumPercent,
        incidents: work.additionalWorks.map((w) => ({
            durationHours: w.durationHours,
            id: `${w.casingColumn} - ${w.workType}`,
            title: `${w.casingColumn} - ${w.workType}`,
            disableSelection: true,
        })),
    }));
};

export type AdditionalWorkListPopupProps = {
    onClose: () => void;
    page: string;
};
export const AdditionalWorkListPopup = ({onClose, page}: AdditionalWorkListPopupProps) => {
    const params = useParams<RouteParams>();
    const [selectedStages, setSelectedStages] = useState<string[]>([]);
    const [selectedCasingColumns, setSelectedCasingColumns] = useState<string[]>([]);
    const {
        data: additionalWork,
        refetch,
        isLoading,
    } = useLoadAdditionalWork({
        wellId: params.wellId!,
        casingColumn: selectedCasingColumns,
        wellConstructionStage: selectedStages,
        enabled: params.wellId !== undefined,
    });

    const transformedAdditionalWork: ListPopupSummary[] | undefined = useMemo(
        () => transformAdditionalWorkData(additionalWork),
        [additionalWork],
    );

    if (!transformedAdditionalWork) {
        return null;
    }

    return (
        <ListPopup
            popupType='additional_work'
            data={transformedAdditionalWork}
            refetch={refetch}
            isLoading={isLoading}
            onClose={onClose}
            page={page}
            className={styles.incidentsWell}
            filters={
                <FiltersWell
                    setSelectedStages={setSelectedStages}
                    setSelectedCasingColumns={setSelectedCasingColumns}
                    selectedStages={selectedStages}
                    selectedCasingColumns={selectedCasingColumns}
                />
            }
        />
    );
};
