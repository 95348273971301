import React from 'react';
import {Box, Card, CardContent, Chip, Typography} from '@mui/material';
import {grey} from '@mui/material/colors';
import {IndicatorPanelProps} from '../types';
import {getColorByDifferenceStatus} from '../utils/utils';

const wordWrapNone = {whiteSpace: 'nowrap'};

const IndicatorPanel = ({title, items}: IndicatorPanelProps) => {
    return (
        <Card sx={{flex: 1}}>
            <CardContent>
                <Typography
                    textTransform='uppercase'
                    sx={{marginBottom: 2}}
                    fontWeight={500}
                    component='h3'
                    variant='body1'
                >
                    {title}
                </Typography>
                <Box sx={{flexWrap: 'wrap', display: 'flex'}} component="div">
                    {items.map((item) => {
                        return (
                            <Box
                                key={item.name}
                                sx={{
                                    minWidth: '90px',
                                    marginRight: 2,
                                    marginBottom: 2,
                                    '&:last-child': {
                                        marginRight: 0,
                                    },
                                }}
                                component="div"
                            >
                                <Typography
                                    sx={wordWrapNone}
                                    component='h4'
                                    variant='body2'
                                >
                                    {item.name}
                                </Typography>
                                <Box sx={{display: 'flex', alignItems: 'center', marginY: 1}} component="div">
                                    <Typography
                                        sx={wordWrapNone}
                                        fontWeight={600}
                                        component='p'
                                        variant='h5'
                                    >
                                        {item.value}
                                    </Typography>
                                    {item.difference ? (
                                        <Chip
                                            sx={{borderRadius: 1, marginLeft: 1}}
                                            size='small'
                                            color={getColorByDifferenceStatus(item.status)}
                                            label={`${item.difference} %`}
                                        />
                                    ) : null}
                                </Box>
                                <Typography
                                    sx={{
                                        overflow: 'hidden',
                                        maxWidth: '100px',
                                        whiteSpace: 'nowrap',
                                        textOverflow: 'ellipsis',
                                    }}
                                    title={item.measure}
                                    color={grey[700]}
                                    component='p'
                                    variant='caption'
                                >
                                    {item.measure}
                                </Typography>
                            </Box>
                        );
                    })}
                </Box>
            </CardContent>
        </Card>
    );
};

export default React.memo(IndicatorPanel);
