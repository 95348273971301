import {createApi} from '@reduxjs/toolkit/query/react';
import {axiosBaseQuery} from '../../utils/api/query';
import {KnbkResponse} from './types';

export const knbkApi = createApi({
    reducerPath: 'knbk',
    baseQuery: axiosBaseQuery,
    endpoints: (builder) => ({
        knbk: builder.query<KnbkResponse, number | void>({
            query: () => ({url: '/knbk', method: 'GET'}),
        }),
    }),
});
