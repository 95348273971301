import {useColorScheme} from '@mui/material';
import {ConfigProvider, theme} from 'antd';
import {PropsWithChildren, useMemo} from 'react';

export const AntThemeOverride = ({children}: PropsWithChildren) => {
    const {colorScheme} = useColorScheme();

    const algorithm = useMemo(
        () => (colorScheme === 'dark' ? theme.darkAlgorithm : theme.defaultAlgorithm),
        [colorScheme],
    );

    return (
        <ConfigProvider
            theme={{
                algorithm,
            }}
        >
            {children}
        </ConfigProvider>
    );
};
