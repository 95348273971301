import type {ComponentType} from 'react';
import type {ContainerInstance} from 'typedi';
import {ScopeProvider} from './index';

export function withScope<P extends Record<string, unknown>>(scope: ContainerInstance, Component: ComponentType<P>) {
    const res = function WithScope(props: P) {
        return (
            <ScopeProvider value={scope}>
                <Component {...props} />
            </ScopeProvider>
        );
    };
    res.displayName = `WithScope(${Component.displayName || Component.name})`;
    return res;
}
