import React from 'react';
import {IncidentEditDetailsDialog} from './IncidentEditDetailsDialog';
import {Incident} from '../types';

type IncidentDialogProps = {
    data: Incident | null;
    setOpen: (open: boolean) => void;
    refetchTableData?: () => void;
    hideBackdrop?: boolean;
};
export const IncidentDetailsDialog: React.FC<IncidentDialogProps> = ({
    data,
    setOpen,
    refetchTableData,
    hideBackdrop = false,
}) => {
    if (!data) {
        return null;
    }

    return (
        <IncidentEditDetailsDialog
            data={data}
            setOpen={setOpen}
            refetchTableData={refetchTableData}
            hideBackdrop={hideBackdrop}
        />
    );
};
