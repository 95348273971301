import {useParams} from 'react-router';
import {DictionaryItem, RouteParams} from '../../types/common';
import {useDispatch, useSelector} from 'react-redux';
import {selectDictionaries} from '../../slices';
import {useCallback} from 'react';
import {deleteDictionary, setDictionary} from '../../slices/dictionariesSlice';
import {createDictionaryParams, getDictionary} from './usePageSchema';
import {useQuery} from '@tanstack/react-query';

type UseDictionaryProps = {
    code?: string;
    key?: string;
    need?: boolean;
    fromStore?: boolean;
};

export const useDict = ({
    code,
    key,
    need = true,
    fromStore = false,
}: UseDictionaryProps): {data: DictionaryItem[]; isLoading: boolean; isError: boolean} => {
    const params = useParams<RouteParams>();
    const dispatch = useDispatch();
    const dictionaries = useSelector(selectDictionaries);

    const handleGetDictionary = useCallback(async () => {
        if (!code || !need) return [];
        dispatch(deleteDictionary({code}));
        const dictionaryParams = createDictionaryParams(params);
        const data = await getDictionary({code, params: {key, ...dictionaryParams}});
        if (data) {
            dispatch(setDictionary({[code]: data}));
            //@ts-ignore
            return data as DictionaryItem[];
        }
        return [];
    }, [code, need, dispatch, params, key]);

    const {data, isError, isLoading} = useQuery({
        queryKey: [`getDictionary/${code}`],
        queryFn: handleGetDictionary,
        enabled: !fromStore,
        initialData: code ? dictionaries[code] : [],
    });

    return {data, isError, isLoading};
};
