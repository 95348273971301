import {ReportLink, UserAction, UserActionType} from './types';

export const reportLinks: ReportLink[] = [
    {name: 'Отчет ВМР', type: 'vmr', className: 'vmr-report'},
    {
        name: 'Отчет Бурение',
        type: 'drilling-daily-report',
        className: 'drilling-report',
        withComment: false,
    },
    {name: 'Отчет Освоение', type: 'assimilation', className: 'mastering-report'},
    {
        name: 'Отчет Траекторий',
        type: 'trajectory',
        className: 'trajectory-report',
        withoutDate: true,
    },
    {
        name: 'Отчет по Долотам',
        type: 'chisel',
        className: 'chisel-report',
        withoutDate: true,
    },
    {
        name: 'Отчет От и ПБ',
        type: 'ot-pb',
        className: 'ot-pb-report',
        withoutDate: true,
    },
    {
        name: 'Отчет по Рейсам',
        type: 'trip',
        className: 'trip-report',
        withoutDate: true,
    },
    {
        name: 'Отчет БКВ',
        type: 'bkv',
        className: 'bkv-report',
        withoutDate: true,
        withDateRange: true,
        withCasingColumn: true,
    },
    {name: 'Отчет МТР', type: 'mtr', withoutDate: true, withDateRange: true},
    {
        name: 'Отчет Хим.реагентов',
        type: 'chemical-reagent-consumption',
        withoutDate: true,
        withDateRange: true,
        withCasingColumn: true,
    },
    {
        name: 'Отчет Конструкция скважины',
        type: 'well-construction-report',
        className: 'well-construction-report',
        withComment: false,
        withoutDate: true,
    },
    {
        name: 'Отчет Техника',
        type: 'technique-report',
        className: 'technique-report',
        withComment: false,
        withoutDate: true,
    },
    {
        name: 'Отчет Буровые растворы',
        type: 'drilling-liquors-report',
        className: 'drilling-liquors-report',
        withoutDate: true,
        withDateRange: true,
        withCasingColumn: true,
    },
    {
        name: 'Отчет НПВ',
        type: 'npv-report',
        className: 'NPV',
        withCasingColumn: true,
        withoutDate: true,
        wellConstructionStage: true,
        switchableDataPeriod: true,
    },
    {name: 'Отчет за рейс', type: 'unknown', className: 'flight-report', disabled: true},
    {name: 'Финальный отчет', type: 'unknown', className: 'final-report', disabled: true},
];

export const userActions: UserAction[] = [{label: 'Выйти', value: UserActionType.Logout}];
