import {Box, Button, Stack, ToggleButton, ToggleButtonGroup} from '@mui/material';
import React, {useCallback, useState} from 'react';
import {IncidentDetails} from './IncidentDetails';
import {IncidentFiles} from './IncidentFiles';
import {IncidentService} from '@services/IncidentService/IncidentService';
import {useQuery} from '@tanstack/react-query';
import {SidebarDialog} from '@ui/SidebarDialog/SidebarDialog';
import {WellsService} from '@services/Wells/WellsService';
import {RouteParams} from 'utils';
import {useNavigate, useParams} from 'react-router';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import {IncidentDetailsDialog} from './IncidentDetailsDialog';
import DoNotDisturbOnOutlinedIcon from '@mui/icons-material/DoNotDisturbOnOutlined';
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import KeyboardArrowRightOutlinedIcon from '@mui/icons-material/KeyboardArrowRightOutlined';
import {Routes} from 'constants/routes';
import {StatusChip} from '@ui/StatusChip/StatusChip';

enum Tabs {
    ABOUT = 'about',
    FILES = 'files',
}

type IncidentTabViewProps = {
    customStyle?: Record<string, any>;
    id: number | string;
    onClose: () => void;
    refetch?: () => void;
    withDetailesView?: boolean;
};
export const IncidentTabView: React.FC<IncidentTabViewProps> = ({
    id,
    onClose,
    refetch: refetchList,
    withDetailesView = false,
    customStyle,
}) => {
    const navigate = useNavigate();
    const params = useParams<RouteParams>();
    const service = WellsService.getInstance();

    const loadIncident = useCallback(() => {
        return IncidentService.getIncident(id);
    }, [id]);

    const {data: incident} = useQuery({
        queryKey: [`incident/${id}`],
        queryFn: loadIncident,
    });

    const [tab, setTab] = useState<Tabs>(Tabs.ABOUT);
    const [detailsView, setDetailsView] = useState<boolean>(false);

    const loadWellById = useCallback(() => {
        return service.getWellById(params.wellId || incident?.wellId);
    }, [incident?.wellId, params.wellId, service]);

    const {data: well, refetch} = useQuery({
        queryKey: [`/well/${params.wellId || incident?.wellId}`],
        queryFn: loadWellById,
        enabled: (params.wellId || incident?.wellId) !== undefined,
    });

    const refetchAll = useCallback(() => {
        refetchList?.();
        refetch();
    }, [refetch, refetchList]);
    const handleTabChange: (event: React.MouseEvent<HTMLElement, MouseEvent>, value: any) => void =
        useCallback((e, v) => {
            if (v !== null) {
                setTab(v);
            }
        }, []);

    if (!incident) {
        return null;
    }

    if (detailsView) {
        return (
            <IncidentDetailsDialog
                refetchTableData={refetchAll}
                data={incident}
                setOpen={onClose}
                hideBackdrop
            />
        );
    }

    const haveSomeDocumentTypes = incident.isWithoutAct
        ? (incident.photos || []).length !== 0
        : (incident.files || []).length !== 0;

    return (
        <SidebarDialog
            open
            onClose={onClose}
            maxWidth='600px'
            height='65%'
            paddingBottom='0'
            marginTop='0'
            top='-80px'
            hideBackdrop
            customStyle={customStyle}
            actions={
                withDetailesView
                    ? {
                          custom: (
                              <Button
                                  onClick={() => setDetailsView(true)}
                                  size='small'
                                  variant='text'
                                  color='inherit'
                                  endIcon={<ChevronRightIcon />}
                              >
                                  Подробнее
                              </Button>
                          ),
                      }
                    : {}
            }
            customTitleBlock={
                <Stack
                    direction='row'
                    alignItems='center'
                    gap='16px'
                >
                    <Button
                        disabled={!!params.wellId}
                        onClick={() => navigate(`${Routes.Dashboard}/${well?.id}`)}
                        size='medium'
                        variant='contained'
                        color='inherit'
                        endIcon={!params.wellId ? <KeyboardArrowRightOutlinedIcon /> : undefined}
                        style={{color: 'var(--mui-palette-text-primary)'}}
                        sx={{
                            fontSize: 20,
                            fontWeight: 500,
                            lineHeight: 1.6,
                            '&.Mui-disabled': {
                                color: 'var(--mui-palette-action)',
                            },
                        }}
                    >
                        {well?.name}
                    </Button>
                    <Box
                        component='div'
                        sx={{flexShrink: 1, maxWidth: 300, overflow: 'hidden'}}
                    >
                        {incident.fullTitle || 'Инцидент'}
                    </Box>
                    <StatusChip status={incident.status} />
                </Stack>
            }
        >
            <ToggleButtonGroup
                size='medium'
                sx={{width: '100%', marginBottom: '16px'}}
                value={tab}
                exclusive
                onChange={handleTabChange}
            >
                <ToggleButton
                    value={Tabs.ABOUT}
                    disabled={tab === Tabs.ABOUT}
                    sx={{width: '50%', textTransform: 'unset'}}
                >
                    Об инциденте
                </ToggleButton>
                <ToggleButton
                    value={Tabs.FILES}
                    disabled={tab === Tabs.FILES}
                    sx={{width: '50%', textTransform: 'unset'}}
                >
                    Документы&nbsp;&nbsp;
                    {haveSomeDocumentTypes ? (
                        <CheckCircleOutlinedIcon color='success' />
                    ) : (
                        <DoNotDisturbOnOutlinedIcon color='warning' />
                    )}
                </ToggleButton>
            </ToggleButtonGroup>
            {tab === Tabs.ABOUT ? (
                <IncidentDetails
                    size='small'
                    data={incident}
                    withDateLink
                    wellId={params.wellId || (incident?.wellId ? String(incident.wellId) : '')}
                />
            ) : (
                <IncidentFiles data={incident} />
            )}
        </SidebarDialog>
    );
};
