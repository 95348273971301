import {Stack} from '@mui/material';
import React from 'react';
import {TripDetails} from '@features/wellDesign/DashboardItems/Trips/types';
import {ValueWithLabel} from './ValueWithLabel';

export const TripDurationsInfo = ({
    isLoading,
    tripDetails,
}: {
    isLoading: boolean;
    tripDetails?: TripDetails;
}) => {
    return (
        <Stack
            direction='row'
            sx={{
                display: 'grid',
                gridTemplateColumns: '1fr 1fr 1fr',
            }}
        >
            <ValueWithLabel
                label='Продолжительность, ч'
                isLoading={isLoading}
            >
                {tripDetails?.timeDurationHours}
            </ValueWithLabel>
            <ValueWithLabel
                label='Время бурения, ч'
                isLoading={isLoading}
            >
                {tripDetails?.durationDrillingHours}
            </ValueWithLabel>
            <ValueWithLabel
                label='Время циркуляции, ч'
                isLoading={isLoading}
            >
                {tripDetails?.durationCirculationHours}
            </ValueWithLabel>
        </Stack>
    );
};
