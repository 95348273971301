import {Loader} from '@ui/Loader/Loader';
import {Navigate, Outlet} from 'react-router-dom';

import {Permission, usePermissions} from 'utils/hooks/permissions/usePermissions';

type GuardedRouteProps = {
    permission: Permission | Permission[];
};
export const GuardedRoute: React.FC<GuardedRouteProps> = ({permission}) => {
    const {roles, isLoading} = usePermissions();

    if (isLoading) {
        return <Loader />;
    }

    if (Array.isArray(permission) && roles) {
        const isMatch = roles.filter((role) => permission.indexOf(role) !== -1).length > 0;

        return isMatch ? <Outlet /> : <Navigate to='/' />;
    }

    if (typeof permission === 'string' && roles?.includes(permission)) {
        return <Outlet />;
    }

    return <Navigate to='/' />;
};
