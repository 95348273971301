import {Box, IconButton, useTheme} from '@mui/material';
import {KeyboardArrowLeft, KeyboardArrowRight} from '@mui/icons-material';
import {TablePaginationActionsProps} from '@mui/material/TablePagination/TablePaginationActions';
import {LightTooltip} from '@ui/LightTooltip/LightTooltip';

export const TablePaginationActions = (props: TablePaginationActionsProps) => {
    const theme = useTheme();
    const {count, page, rowsPerPage, onPageChange} = props;

    const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        onPageChange(event, page + 1);
    };

    return (
        <Box
            component='div'
            sx={{flexShrink: 0, ml: 2.5}}
        >
            <LightTooltip title='Предыдущая страница'>
                <IconButton
                    onClick={handleBackButtonClick}
                    disabled={page === 0}
                    aria-label='Предыдущая страница'
                >
                    {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                </IconButton>
            </LightTooltip>

            <LightTooltip title='Следующая страница'>
                <IconButton
                    onClick={handleNextButtonClick}
                    disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                    aria-label='Следующая страница'
                >
                    {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
                </IconButton>
            </LightTooltip>
        </Box>
    );
};
