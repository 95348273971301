import {StackedBarWithTooltip} from '@features/incident/components/StackedBar/StackedBar';
import {ListPopupDetails} from './ListPopupDetails';
import {ListPopupSummary, ListPopupSummaryGrouped} from '../../types';
type IncidentListProps = {
    data: ListPopupSummaryGrouped[] | ListPopupSummary[];
    refetch: () => void;
    isLoading?: boolean;
};

export const IncidentList = ({data, refetch, isLoading = false}: IncidentListProps) => {
    return (
        <>
            <StackedBarWithTooltip data={data} />
            <ListPopupDetails
                data={data}
                refetch={refetch}
                isLoading={isLoading}
            />
        </>
    );
};
