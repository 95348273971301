import {ChartCode, ChartItem, ColumnChartProps} from '../types';
import {ColumnConfig} from '@ant-design/plots';
import {SupportedColorScheme} from '@mui/material';
import {colors} from '@features/incident/constants';
import {DeviceInfo} from 'utils/hooks/useDeviceInfo';
import {
    computeAxisLineColor,
    computeFill,
    computeStrokeOpacity,
    computeTextColor,
    getTooltip,
} from './common';

export const getColumnConfig = (
    {settings, code}: ChartItem,
    colorScheme: SupportedColorScheme | undefined,
    data: ColumnChartProps,
    deviceInfo: DeviceInfo | undefined,
): Omit<ColumnConfig, 'data'> => {
    const isTablet = deviceInfo?.isTablet;

    const config: Omit<ColumnConfig, 'data'> = {
        isGroup: settings?.isGroup,
        xField: 'x',
        yField: 'y',
        seriesField: settings?.isGroup ? 'valueType' : '',
        tooltip: {
            title: (title: string, datum: any) => {
                return datum.x.replace('\n', '');
            },
            shared: true,
        },
        legend: {
            itemName: {
                style: {
                    fill: computeFill(colorScheme),
                },
            },
        },
        meta: {
            y: {
                alias: 'Количество',
            },
        },
        yAxis: {
            grid: {line: {style: {strokeOpacity: 0.4}}},
        },
    };

    if (code === ChartCode.COST_BY_SERVICE_AS_VERTICAL_BAR_CHART) {
        // @ts-ignore
        config.dodgePadding = 3;

        // @ts-ignore
        config.legend = {
            layout: 'horizontal',
            position: 'top-left',
            itemName: {
                style: {
                    fill: computeFill(colorScheme),
                },
            },

            padding: [10, 40, 10, 20],
        };

        config.tooltip = {
            shared: false,
        };

        // @ts-ignore
        config.label = {
            position: 'top',
            style: {
                fill: computeFill(colorScheme),
                fontSize: 10,
            },
            formatter: (d: {y: number}) => {
                const {y} = d;
                const value = `${y.toFixed(1)}`;

                return value;
            },
        };

        // @ts-ignore
        config.maxColumnWidth = 27;

        // @ts-ignore
        config.minColumnWidth = 27;

        // @ts-ignore
        config.marginRatio = 0.1;

        //@ts-ignore
        config.interactions = [
            {type: 'element-highlight', enable: false},
            {type: 'element-active', enable: false},
        ];

        //@ts-ignore
        config.xAxis = {
            label: {
                autoHide: false,
                autoRotate: false,
                style: {
                    fontSize: 10,
                    fill: computeFill(colorScheme),
                },
                formatter: (text: string) => {
                    const maxLength = 25;
                    return text.length > maxLength ? `${text.slice(0, maxLength)}...` : text;
                },
            },
            line: null,
            tickLine: null,
        };

        const maxValue = Math.max(...data.map((d) => d.y));

        //@ts-ignore
        config.yAxis = {
            max: maxValue * 1.1,
            grid: {
                line: {
                    style: {
                        strokeOpacity: computeStrokeOpacity(colorScheme),
                        fill: computeAxisLineColor(colorScheme),
                        lineWidth: 0.8,
                    },
                },
            },
        };
    }

    if (code === ChartCode.WASTE_GENERATION_ACCOUNTING_PLAN_FACT) {
        // @ts-ignore
        config.label = {
            position: 'top',
            style: {
                fill: computeFill(colorScheme),
            },
            //Поворачиваем текст и немного сдвигаем его, чтобы все влезло
            rotate: 80.1,
            offsetX: 10,
            offsetY: 7,
        };
    }

    if (code === ChartCode.STAGE_DURATION) {
        // @ts-ignore
        config.label = {
            position: 'top',
            style: {
                fill: computeFill(colorScheme),
            },
            //Поворачиваем текст и немного сдвигаем его, чтобы все влезло
            rotate: 80.1,
            offsetX: 8,
            offsetY: 5,
        };
        // @ts-ignore
        config.xAxis = {
            label: {
                //Поворачиваем текст и немного сдвигаем его, чтобы все влезло
                rotate: 80.1,
                offsetX: -53,
                offsetY: -10,
            },
        };
    }

    if (code === ChartCode.COST_EXPENSE_STRUCTURE_BY_CASING_COLUMN) {
        //@ts-ignore
        config.minColumnWidth = isTablet ? 80 : 22;

        //@ts-ignore
        config.maxColumnWidth = isTablet ? 80 : 22;

        //@ts-ignore
        config.dodgePadding = isTablet ? 6 : 3;

        //@ts-ignore
        config.xAxis = {
            label: {
                autoHide: false,
                autoRotate: false,
                style: {
                    fontSize: 10,
                    fill: computeFill(colorScheme),
                },
                formatter: (text: string) => {
                    const maxLength = 9;
                    return text.length > maxLength ? `${text.slice(0, maxLength)}...` : text; //нет встроенного textOverflow
                },
            },
            line: null,
            tickLine: null,
        };

        // @ts-ignore
        config.yAxis = {
            style: {
                fill: '#FFFFFF1F',
            },
            min: 0,
            max: maxValueByGroup(data) + 5,
            tickInterval: 20,
            label: {
                style: {
                    fill: computeFill(colorScheme),
                },
            },

            line: {
                style: {
                    strokeOpacity: computeStrokeOpacity(colorScheme),
                    stroke: computeAxisLineColor(colorScheme),
                    lineWidth: 1,
                },
            },

            grid: {
                line: {
                    style: {
                        strokeOpacity: computeStrokeOpacity(colorScheme),
                        fill: computeAxisLineColor(colorScheme),
                        lineWidth: 1,
                    },
                },
            },
        };

        config.tooltip = getTooltip(code, colorScheme);

        //@ts-ignore
        config.interactions = [
            {type: 'element-highlight', enable: false},
            {type: 'element-active', enable: false},
        ];

        //@ts-ignore
        config.label = {
            position: 'top',
            style: {
                fill: computeTextColor(colorScheme),
            },
            formatter: (d: {y: number}) => {
                const {y} = d;
                const value = `${y.toFixed(1)}`;

                if (y < 4) {
                    return '';
                }

                return value;
            },

            layout: [
                {
                    type: 'interval-adjust-position',
                },
            ],
        };

        //@ts-ignore
        config.isStack = true;

        //@ts-ignore
        config.isGroup = true;

        //@ts-ignore
        config.seriesField = 'valueType';

        //@ts-ignore
        config.groupField = 'groupField';

        //@ts-ignore
        config.legend = {
            position: 'top-left',
            itemName: {
                style: {
                    fill: computeFill(colorScheme),
                },
            },
            selected: {},
            radio: null,
        };

        //@ts-ignore
        config.interactions = [
            {type: 'legend-highlight', enable: false},
            {type: 'legend-filter', enable: false},
        ];
        //@ts-ignore
        config.color = (d: {valueType: string}) => {
            const {valueType} = d;

            if (valueType.includes('НПВ')) return '#D32F2F';
            if (valueType.includes('ПВ')) return '#4DB6AC';
            if (valueType.includes('ПНВ')) return '#EF6C00 ';
            return colors[3];
        };

        //@ts-ignore
        config.annotations = getColumnAnnotations(data, code, colorScheme, isTablet);

        //@ts-ignore
        config.columnWidthRatio = 0.75;

        //@ts-ignore
        config.pattern = (d: {y: number; valueType: string}) => {
            const matchedData = data.find(
                (item) => item.y === d.y && item.valueType === d.valueType,
            );

            if (matchedData?.groupField === 'Факт') {
                return {
                    type: 'line',
                    cfg: {
                        spacing: 25,
                        stroke: computeTextColor(colorScheme),
                        lineWidth: 2,
                        rotation: 145,
                    },
                };
            }

            return {};
        };
    }

    if (code === ChartCode.PENETRATION_SPEED_INFO_BY_WELL) {
        //@ts-ignore
        config.color = colors;
    }

    if (code === ChartCode.OT_PB_OOS_BY_WELLS) {
        // @ts-ignore
        config.color = ({x}) => colors[data.findIndex((val) => val.x === x)];
        // @ts-ignore
        //задаем formatter потому, что свойство meta.alias при задании color перестает работать
        config.tooltip.formatter = (datum) => ({
            name: 'Количество',
            value: datum.y,
        });
    }

    if (code === ChartCode.DAYS_COUNT_BY_1000M || code === ChartCode.COST_BY_SERVICE) {
        // @ts-ignore
        config.tooltip = {
            shared: false,
        };
    }

    if ([ChartCode.OT_PB_OOS_BY_WELL, ChartCode.OT_PB_OOS_BY_WELLS].includes(code)) {
        // @ts-ignore
        config.label = {
            position: 'top',
            style: {
                fill: '#FFF',
                opacity: 0.7,
                font: 'Roboto',
                size: '10px',
            },
        };
        //@ts-ignore
        config.meta.y = {...config.meta.y, max: Math.max(...data.map((d) => d.y)) * 1.1};
    }

    return config;
};

const sumValuesByGroup = (data: ColumnChartProps) => {
    return data.reduce(
        (acc, cur) => {
            const key = `${cur.x}-${cur.groupField}`;
            if (!acc[key]) acc[key] = 0;
            acc[key] += cur.y;
            return acc;
        },
        {} as Record<string, number>,
    );
};

const calculateOffsetByType = (
    groupField: string,
    hasBothTypes: boolean,
    isTablet: boolean | undefined,
) => {
    if (!hasBothTypes) {
        return 0;
    }

    if (isTablet) {
        if (groupField === 'Факт') {
            return 41;
        } else if (groupField === 'План') {
            return -41;
        }
    } else {
        if (groupField === 'Факт') {
            return 12.5;
        } else if (groupField === 'План') {
            return -11;
        }
    }

    return 0;
};

const maxValueByGroup = (data: ColumnChartProps) => {
    return Math.max(...Object.values(sumValuesByGroup(data)));
};

const getDirectionTypesMap = (data: ColumnChartProps) => {
    return data.reduce(
        (acc, cur) => {
            const direction = cur.x;
            const type = cur.groupField;
            if (!acc[direction]) {
                acc[direction] = new Set();
            }
            acc[direction].add(type as string);
            return acc;
        },
        {} as Record<string, Set<string>>,
    );
};

const getColumnAnnotations = (
    data: ColumnChartProps,
    code: ChartCode,
    colorScheme?: SupportedColorScheme,
    isTablet?: boolean,
) => {
    if (code === ChartCode.COST_EXPENSE_STRUCTURE_BY_CASING_COLUMN) {
        const uniqueXValues = Array.from(new Set(data.map((d) => d.x)));
        const directionTypesMap = getDirectionTypesMap(data);
        const groupedTotals = Object.entries(sumValuesByGroup(data));

        return groupedTotals.map(([key, total]) => {
            const [x, groupField] = key.split('-');
            const xPosition = uniqueXValues.indexOf(x);

            const hasBothTypes = directionTypesMap[x] && directionTypesMap[x].size > 1;

            return {
                type: 'text',
                content: `${total.toFixed(1)}`,
                position: [xPosition, total + 1],
                style: {
                    textAlign: 'center',
                    fontSize: 12,
                    fill: computeFill(colorScheme),
                },
                offsetX: calculateOffsetByType(groupField, hasBothTypes, isTablet),
                offsetY: -10,
            };
        });
    }
    return {};
};
