import {OperationStatus, UserStatus} from './types';

export const operationStatusLabels = {
    [OperationStatus.SUCCESS]: 'Выполнен',
    [OperationStatus.PROCESSING]: 'Обрабатывается',
    [OperationStatus.ERROR]: 'Ошибка',
};

export const operationStatusColors = {
    [OperationStatus.SUCCESS]: 'text.primary',
    [OperationStatus.PROCESSING]: ' var(--mui-palette-text-disabled)',
    [OperationStatus.ERROR]: 'var(--mui-palette-error-main)',
};

export const userStatusLables = {
    [UserStatus.ACTIVE]: 'Активен',
    [UserStatus.BLOCKED]: 'Заблокирован',
};

export const userStatusColors = {
    [UserStatus.ACTIVE]: 'text.primary',
    [UserStatus.BLOCKED]: ' var(--mui-palette-error-main)',
};
