import {memo, ReactNode, SyntheticEvent} from 'react';
import {Chip, Divider, IconButton, Stack, Typography} from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import {DT_FORMATS, formatDate} from '../../utils';
import {SX_ACCORDION, SX_ACCORDION_SUMMARY} from '@features/OtPbOos/constants';
import {OtPbOos, OtPbOosByWellSummary, OtPbOosSummaryGrouped} from '@services/OtPbOosService/types';
import {colors} from '@features/incident/constants';
import {isOtPbOosSummaryType} from '@features/OtPbOos/utils';

import styles from '@pages/TrajectoryPage/TreeItem.module.css';

type OtPbOosPopupSummaryAccordionProps = {
    data: OtPbOosSummaryGrouped | OtPbOosByWellSummary;
    idx: number;
    expanded?: number;
    onExpand?: (idx: number) => (event: SyntheticEvent, extended: boolean) => void;
};

type AccordionOtPbOosSummaryProps = {
    depth?: number;
    children: ReactNode;
};

const OtPbOosAccordionSummary = ({depth = 0, children}: AccordionOtPbOosSummaryProps) => (
    <AccordionSummary
        expandIcon={
            <IconButton size='small'>
                <ExpandMoreIcon fontSize='small' />
            </IconButton>
        }
        sx={SX_ACCORDION_SUMMARY}
        className={styles.listNodeRow}
    >
        <Stack
            direction='row'
            alignItems='center'
            justifyContent='space-between'
            width='100%'
            padding={depth ? '0 0 0 8px' : ''}
        >
            {children}
        </Stack>
    </AccordionSummary>
);

const EliminatedChip = ({isEliminated}: {isEliminated: boolean}) => (
    <Chip
        sx={{
            color: isEliminated
                ? 'var(--mui-palette-success-main)'
                : 'var(--mui-palette-error-main)',
            borderRadius: '12px',
            width: 'auto',
            flexShrink: 0,
            '& .MuiChip-label': {
                paddingLeft: '5px',
                paddingRight: '5px',
            },
        }}
        label={isEliminated ? 'Устранено' : 'Не устранено'}
        size='small'
        color={isEliminated ? 'success' : 'error'}
        variant='outlined'
    />
);

const OtPbOosRow = ({otPbOos, isLastRow}: {otPbOos: OtPbOos; isLastRow: boolean}) => (
    <Stack
        gap='4px'
        pb={!isLastRow ? '4px' : '2px'}
    >
        <Stack
            direction='column'
            justifyContent='space-between'
            className={styles.listNodeRow}
        >
            <Stack
                direction='row'
                justifyContent='space-between'
                sx={{opacity: '.7'}}
            >
                <Typography variant='body2'>
                    {formatDate(otPbOos.date, DT_FORMATS.defaultDate)}
                </Typography>
                <Typography variant='body2'>{otPbOos.organization}</Typography>
            </Stack>
            <Stack
                direction='row'
                alignItems='flex-end'
                justifyContent='space-between'
            >
                <Typography variant='body1'>{otPbOos.title}</Typography>
                <EliminatedChip isEliminated={otPbOos.isEliminated} />
            </Stack>
        </Stack>
        {!isLastRow && <Divider />}
    </Stack>
);

const OtPbOosAccordion = ({title, count, summaries}: OtPbOosByWellSummary) => (
    <Accordion
        sx={{
            ...SX_ACCORDION,
            '& .MuiAccordionSummary-gutters': {
                background: 'var(--mui-palette-action-selected)',
            },
        }}
    >
        <OtPbOosAccordionSummary depth={1}>
            <Typography variant='subtitle2'>{title}</Typography>
            <Typography variant='subtitle2'>{count}</Typography>
        </OtPbOosAccordionSummary>
        <AccordionDetails sx={{padding: '2px 2px 2px 16px'}}>
            <Stack direction='column'>
                {summaries.map((otPbOos, idx) => (
                    <OtPbOosRow
                        key={otPbOos.id}
                        otPbOos={otPbOos}
                        isLastRow={idx === summaries.length - 1}
                    />
                ))}
            </Stack>
        </AccordionDetails>
    </Accordion>
);

export const OtPbOosPopupSummaryAccordion = memo(({
    data,
    idx,
    expanded,
    onExpand,
}: OtPbOosPopupSummaryAccordionProps) => {
    const {count, summaries, title} = data;
    const isSummaryType = isOtPbOosSummaryType(summaries[0]);

    return (
        <Accordion
            sx={{...SX_ACCORDION, borderLeft: `5px solid ${colors[idx % colors.length]}`}}
            expanded={idx === expanded}
            onChange={onExpand && onExpand(idx)}
        >
            <OtPbOosAccordionSummary>
                <Typography variant='subtitle1'>{title || '---'}</Typography>
                <Typography variant='subtitle2'>{count}</Typography>
            </OtPbOosAccordionSummary>
            <AccordionDetails sx={{padding: isSummaryType ? '0' : '2px 2px 2px 16px'}}>
                {expanded === idx &&
                    summaries.map((sum, idx) =>
                        isOtPbOosSummaryType(sum) ? (
                            <OtPbOosAccordion
                                key={`${sum.title}_${idx}`}
                                summaries={sum.summaries}
                                title={sum.title}
                                count={sum.count}
                            />
                        ) : (
                            <OtPbOosRow
                                key={sum.id}
                                otPbOos={sum}
                                isLastRow={idx === summaries.length - 1}
                            />
                        ),
                    )}
            </AccordionDetails>
        </Accordion>
    );
});

export default OtPbOosPopupSummaryAccordion;
