import {ChartCode, ChartProps} from '@ui/Chart';
import {DonutValues} from '../../../../../components/Donut/types';
import {getExtendedValues} from '../../../../../components/Donut/utils';
import {colors} from '@features/incident/constants';
import {costByServiceColors} from '@features/Cost/constants';

const getRow = (values: DonutValues) => {
    const extendedData = getExtendedValues(values);
    const row = extendedData.find((v) => v.name?.includes('НПВ'));
    return row;
};

const computeChartName = (name: string | undefined) => {
    if (name?.includes('НПВ')) return 'НПВ';
    if (name?.includes('ПВ')) return 'ПВ';
    if (name?.includes('ПНВ')) return 'ПНВ';
    if (name?.includes('Доп. работы')) return 'Доп. работы';
    return name;
};

const getColorsForDrillingOperationsPvNpvChart = ({
    name,
    colorScheme,
}: {
    name?: string;
    colorScheme?: string;
}): string => {
    if (name?.includes('НПВ')) return colorScheme === 'light' ? '#D32F2F ' : '#D32F2F';
    if (name?.includes('ПВ')) return colorScheme === 'light' ? '#4CAF50' : '#388E3C';
    if (name?.includes('ПНВ')) return colorScheme === 'light' ? '#F57C00' : '#EF6C00';
    return '';
};

export const convertChartData = (chart: ChartProps, code: ChartCode, colorScheme?: string) => {
    const data = (chart as DonutValues).map((v, index) => {
        const name = computeChartName(v.name);

        let color = colors[index];

        if (code === ChartCode.WELL_OPERATIONS_PV_NPV || code === ChartCode.PV_NPV_BY_WELL) {
            color = getColorsForDrillingOperationsPvNpvChart({name, colorScheme}) || colors[index];
        }

        if (code === ChartCode.COST_BY_SERVICE) {
            color = costByServiceColors[index];
        }

        return {...v, name: name, color};
    });
    const rowData = getRow(data);
    return {data, rowData};
};
