import {DarkModeOutlined, LightModeOutlined} from '@mui/icons-material';
import {IconButton, useColorScheme} from '@mui/material';
import {LightTooltip} from '@ui/LightTooltip/LightTooltip';
import {useEffect} from 'react';
import {useUserSettings} from 'utils';

export const ColorModeToggle = () => {
    const {settings: themeSettings, changeSettings} = useUserSettings('themeSettings');
    const {setMode, mode} = useColorScheme();

    useEffect(() => {
        if (!themeSettings) return;
        setMode(themeSettings.theme);
    }, [setMode, themeSettings]);

    const handleColorModeChange = () => {
        const theme = mode === 'light' ? 'dark' : 'light';
        setMode(theme);
        changeSettings({...themeSettings, theme});
    };

    return (
        <LightTooltip title={mode === 'light' ? 'Темная тема' : 'Светлая тема'}>
            <IconButton onClick={handleColorModeChange}>
                {mode === 'light' ? <DarkModeOutlined /> : <LightModeOutlined />}
            </IconButton>
        </LightTooltip>
    );
};
