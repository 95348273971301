import {useCallback, useEffect, useMemo, useState} from 'react';

import {useMutation, useQuery, useQueryClient} from '@tanstack/react-query';
import {UserSettingsService} from '@services/UserSettings/userSettingsService';
import {SortOrder, UserSettings} from '@services/UserSettings/types';
import {SortConfig} from '@ui/NewTable/types';
import {Routes as RoutesLinks} from '../../constants/routes';
import {useLocation} from 'react-router-dom';

export const USER_SETTINGS_TABLE_KEY = 'tableSettings';

export const getTableName = (pageId: string, pathname: string) => {
    switch (true) {
        case pathname.includes(RoutesLinks.Ggd, -1):
            return 'ggd';
        case pathname.includes(RoutesLinks.Trajectory, -1):
            return 'trajectory';
        default:
            return pageId;
    }
};

type SetCallback<PrevT> = (value: PrevT) => void;
export type UserSettingsReturn<T> = {
    /** Текущее значение параметра */
    settings: T;
    /**
     * Метод изменения значения параметра
     *
     * Требуется указывать в зависимостях, если ключ не статичный.
     * Рекомендуется всегда указывать в зависимости.
     */
    changeSettings: SetCallback<T>;
    /** Индикатор получения данных с сервера */
    loading: boolean;
    /** Индикатор выполнения получения данных с сервера */
    isSuccess: boolean;
    /** Индикатор ошибки при получении данных с сервера */
    isError: boolean;
};

type Settings<K extends keyof UserSettings> = UserSettings | UserSettings[K] | undefined;

export function useUserSettings(): UserSettingsReturn<UserSettings | undefined>;
export function useUserSettings<K extends keyof UserSettings>(
    key: K,
    defaultValue?: UserSettings,
): UserSettingsReturn<UserSettings[K] | undefined>;

/**
 * Сохраняет указанные данные на сервере для текущего пользователя
 * @param key Ключ сохраняемых параметров
 * @param defaultValue объект пользовательских настроек по умолчанию
 */
export function useUserSettings<K extends keyof UserSettings>(
    key?: K,
    defaultValue?: UserSettings,
): UserSettingsReturn<Settings<K>> {
    const queryClient = useQueryClient();
    const [settings, setSettings] = useState<UserSettings | undefined>();

    const {data: userSettings, isLoading, isSuccess, isError} = useQuery<UserSettings>({
        queryKey: ['getUserSettings'],
        queryFn: UserSettingsService.getUserSettings,
        staleTime: 8 * (60 * 1000), // 8 mins
        gcTime: 10 * (60 * 1000), // 10 mins
        placeholderData: defaultValue,
    });

    useEffect(() => {
        setSettings(userSettings);
    }, [userSettings]);

    const {mutate: updateUserSettings} = useMutation({
        mutationKey: ['updateUserSettings'],
        mutationFn: (data: UserSettings | undefined) =>
            UserSettingsService.updateUserSettings(data),
        onSuccess: (data: UserSettings) => {
            queryClient.setQueryData(['getUserSettings'], data);
            setSettings(data);
        },
    });

    const {pathname} = useLocation();

    const changeSettings: SetCallback<Settings<K>> = useCallback(
        (nextValue) => {
            if (!userSettings || !nextValue) return;
            let newValue = key
                ? {...userSettings, [key]: {...userSettings[key], ...nextValue}}
                : nextValue as UserSettings;

            if (key === USER_SETTINGS_TABLE_KEY) {
                const tableName = getTableName(Object.keys(nextValue || {})[0], pathname);
                if (!isNaN(Number(tableName))) return;
                newValue = {...userSettings, [key]: {...userSettings[key], [tableName]: Object.values(nextValue)[0]}};
            }

            newValue && updateUserSettings(newValue);
        },
        [key, pathname, updateUserSettings, userSettings],
    );

    const value = useMemo(() => (key ? settings?.[key] : settings), [settings, key]);

    return {settings: value, changeSettings, loading: isLoading, isSuccess, isError};
}

export const getSortConfig = (settings: SortOrder[] | undefined): SortConfig | undefined => {
    if (!settings) return;
    return Object.fromEntries(settings.map((el) => Object.values(el)));
};

export const normalizeSettings = (sortConfig: SortConfig): SortOrder[] =>
    Object.entries(sortConfig).map(([key, value]) => ({column: key, direction: value}));
