import {ColumnRecord} from 'components/NewTable/types';
import {PageSchema} from 'utils/hooks/usePageSchema';
import {PageData} from 'utils/hooks/useNewPageData';

export const prepareNewTableScheme = (scheme: PageSchema): ColumnRecord => {
    const properties = scheme.properties || {};
    const res: ColumnRecord = {};
    Object.keys(properties).forEach((key) => {
        const currentObj = properties[key];
        if (currentObj.type) {
            res[key] = {
                ...currentObj,
                id: key,
            };
        }
    });
    return res;
};

export const prepareNewTableData = <T extends Record<any, any>>(pageData: PageData<T>) => {
    if (!pageData?._embedded) return [];

    const rawData = Object.values(pageData._embedded)[0];
    const res = rawData.map((el) => {
        const id = el?.id ? el?.id : el?._links?.self?.href?.split('/').at(-1);
        return {...el, id};
    });

    return res;
};
