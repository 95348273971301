import {DashboardService} from '../../../../services/Dashboard/DashboardService';
import {useEffect, useMemo, useState} from 'react';
import {TimeBalanceAnalyticResponse} from '../types';
import {useLoading} from '../../../../utils/hooks/useLoading';
import {useParams} from 'react-router-dom';
import {RouteParams, invokeAsync} from '../../../../utils';

const service = DashboardService.getInstance();

export const useTimeBalanceData = (
    date: string,
): [TimeBalanceAnalyticResponse | undefined, boolean] => {
    const {loading, setLoading} = useLoading();
    const {wellId} = useParams<RouteParams>();
    const [data, setData] = useState<TimeBalanceAnalyticResponse | undefined>();

    useEffect(() => {
        void invokeAsync(
            async () => {
                const response = await service.getTimeBalanceData(Number(wellId), date);

                setData(response);
            },
            setLoading,
        );
        // eslint-disable-next-line
    }, [date]);

    return useMemo(() => [data, loading], [data, loading]);
};
