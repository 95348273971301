import {useCallback, useState} from 'react';
import {Row, TableEditRowProps} from './types';
import TableEditCell from './TableEditCell';
import {NewTableCell} from './NewTableCell';
import {TableEditRowActions} from './TableEditRowActions';
import {useDynamicValidation} from 'utils/hooks/useDynamicValidation';
import {checkIsTableRowValid} from 'utils/functions/checkIsTableRowValid';

const TableEditRow = <T extends Row>({
    onClose,
    columns,
    row,
    tableConfig,
    isItemSelected,
    onCreate,
    onEdit,
    requiredConfig,
    draggableProvided,
    dynamicValidationConfig,
}: TableEditRowProps<T>) => {
    const onSubmit = () => {
        if (row?.id) {
            onEdit(values);
        } else {
            onCreate(values);
        }
    };

    const [values, setValues] = useState<T>(row);

    const setValue = useCallback((key: string, newValue: any) => {
        if (Array.isArray(newValue)) {
            setValues((_prev) => ({
                ..._prev,
                [key]: newValue.filter((str) => str.trim() !== ''),
            }));
        } else {
            setValues((_prev) => ({..._prev, [key]: newValue}));
        }
    }, []);

    const {currentRequiredConfig, currentColumns} = useDynamicValidation<T>({
        dynamicValidationConfig,
        values,
        setValue,
        requiredConfig,
        columns,
    });

    const isValid = checkIsTableRowValid(currentRequiredConfig, values);

    return (
        <>
            <TableEditRowActions<T>
                tableConfig={tableConfig}
                isItemSelected={isItemSelected}
                onClose={onClose}
                onSubmit={onSubmit}
                isValid={isValid}
                draggableProvided={draggableProvided}
            />
            {Object.values(currentColumns).map((column) => {
                return (
                    <NewTableCell
                        row={values}
                        column={column}
                        key={`${row.id}_${column.id}`}
                    >
                        {!column?.readOnly && (
                            <TableEditCell
                                column={column}
                                values={values}
                                setValue={setValue}
                                requiredConfig={currentRequiredConfig}
                                initialDictKeyValue={
                                    column.dictKeyProperty &&
                                    (row as Record<string, any>)[column.dictKeyProperty]
                                }
                            />
                        )}
                    </NewTableCell>
                );
            })}
        </>
    );
};

export default TableEditRow;
