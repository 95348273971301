import {BASE_AUTH_PATH} from '../utils';
import Keycloak, {KeycloakConfig} from 'keycloak-js';
import {OuterConfig, UserProfile} from '../types/auth';
import api from '../utils/api';

export class AuthService {
    private static instance: Keycloak;

    public static init(config: KeycloakConfig): Promise<boolean> {
        AuthService.instance = new Keycloak(config);

        return AuthService.instance.init({
            onLoad: 'login-required',
        });
    }

    public static getToken(): string | undefined {
        return AuthService.instance.token;
    }

    public static isAuthenticated(): boolean | undefined {
        return Boolean(AuthService.instance?.authenticated);
    }

    public static async login(): Promise<void> {
        await AuthService.instance.login();
    }

    public static logout(): void {
        void AuthService.instance.logout();
    }

    public static getUserProfile(): UserProfile | undefined {
        return AuthService.instance.idTokenParsed as UserProfile | undefined;
    }

    public static async refreshToken(): Promise<void> {
        await AuthService.instance.updateToken(10);
    }

    public static getAuthInfo(): Promise<OuterConfig> | undefined {
        try {
            return api.get(BASE_AUTH_PATH);
        } catch (error) {
            console.error(error);
        }
    }
}
