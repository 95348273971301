import {CircularProgress, Stack} from '@mui/material';

export const FlexLoader = () => {
    return (
        <Stack
            height='100%'
            justifyContent='center'
            alignItems='center'
        >
            <CircularProgress />
        </Stack>
    );
};
