import {DonutExtendedValues, DonutValues} from './types';

export const getExtendedValues = (values: DonutValues): DonutExtendedValues => {
    const totalSum = values.reduce((res, item) => res + item.value, 0);
    const extendedValues: DonutExtendedValues = [];
    let acumPercent = 0;

    for (const val of values) {
        const percent = totalSum > 0 ? (100 / totalSum) * val.value : 0;
        extendedValues.push({
            ...val,
            percent,
            fromPercent: acumPercent,
            toPercent: acumPercent + percent,
        });
        acumPercent += percent;
    }
    return extendedValues;
};
