import {AccountDataWithPassword, PermissionItem} from '../types';

export const renderNewPassword = () => {
    const upperCaseChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
    const lowerCaseChars = 'abcdefghijklmnopqrstuvwxyz';
    const numberChars = '0123456789';
    const specialChars = '!@#$%^&*()';

    const allChars = upperCaseChars + lowerCaseChars + numberChars + specialChars;

    // Гарантируем наличие хотя бы одной заглавной, прописной, цифры и спецсимвола
    let password = '';
    password += upperCaseChars[Math.floor(Math.random() * upperCaseChars.length)];
    password += lowerCaseChars[Math.floor(Math.random() * lowerCaseChars.length)];
    password += numberChars[Math.floor(Math.random() * numberChars.length)];
    password += specialChars[Math.floor(Math.random() * specialChars.length)];

    // Добавляем случайные символы, чтобы довести длину пароля до 12 символов
    for (let i = password.length; i < 12; i++) {
        password += allChars[Math.floor(Math.random() * allChars.length)];
    }

    // Перемешиваем полученную строку, чтобы избежать предсказуемого порядка
    return password
        .split('')
        .sort(() => 0.5 - Math.random())
        .join('');
};

export const validatePassword = (value: string | null) => {
    if (!value) {
        return true;
    }
    const hasDigit = /\d/.test(value); // Проверка на наличие цифры
    const hasLowercase = /[a-z]/.test(value); // Проверка на наличие маленькой буквы
    const hasUppercase = /[A-Z]/.test(value); // Проверка на наличие заглавной буквы
    const hasSpecialChar = /[!@#$%^&*()]/.test(value); // Проверка на наличие спецсимвола
    const hasCyrillic = /[а-яА-ЯЁё]/.test(value); // Проверка на наличие кириллицы

    if (hasCyrillic) {
        return 'Пароль может содержать только латинские буквы';
    }
    if (value.length < 8) {
        return 'Пароль не может быть короче 8 симоволов';
    }
    if (!hasDigit) {
        return 'Пароль должен содержать хотя бы 1 цифру';
    }
    if (!hasLowercase) {
        return 'Пароль должен содержать хотя бы 1 букву нижнего регистра';
    }
    if (!hasUppercase) {
        return 'Пароль должен содержать хотя бы 1 букву верхнего регистра';
    }
    if (!hasSpecialChar) {
        return 'Пароль должен содержать хотя бы 1 спецсимвол';
    }
    if (value.length > 32) {
        return 'Пароль не может быть длинее 32 симоволов';
    }
    if (hasDigit && hasLowercase && hasUppercase && hasSpecialChar) {
        return true;
    }
    return 'Пароль содержит недопустимые символы';
};

export const defaultValues: AccountDataWithPassword = {
    password: '',
    passwordConfirm: '',
    username: '',
    permissions: [],
    organization: '',
    phoneNumber: '+7',
    email: '',
};

export const getLinkedObjects = (
    list: PermissionItem[],
    permission: PermissionItem,
): PermissionItem[] => {
    // Если у объекта поле linkedCode пустое (null или ''), возвращаем массив только с этим объектом
    if (!permission.linkedCode) {
        return [permission];
    }

    // Ищем объект, у которого code совпадает с obj.linkedCode
    const linkedObject = list.find((item) => item.code === permission.linkedCode);

    // Если связанный объект найден
    if (linkedObject) {
        // Рекурсивно вызываем функцию для найденного объекта, чтобы получить все его зависимости
        const linkedChain = getLinkedObjects(list, linkedObject);

        // Возвращаем массив, объединяющий текущий объект и все связанные объекты
        return [permission, ...linkedChain];
    }

    // Если связанный объект не найден, возвращаем массив только с текущим объектом
    return [permission];
};
