import {Box, ButtonProps, IconButton, Stack, Typography} from '@mui/material';
import {SidebarDialog} from 'components/SidebarDialog/SidebarDialog';
import {Incident} from '../types';
import React, {useCallback, useState} from 'react';
import Close from '@mui/icons-material/Close';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import {IncidentDetailsView} from './IncidentDetailsView';
import {IncidentDetailsEdit} from './IncidentDetailsEdit';
import {useQuery} from '@tanstack/react-query';
import {Loader} from '@ui/Loader/Loader';
import {IncidentService} from '@services/IncidentService/IncidentService';
import {useLocation, useParams} from 'react-router-dom';
import {RouteParams} from 'utils';
import {WellsService} from '@services/Wells/WellsService';
import {ButtonWithConfirm} from '@ui/ButtonWithConfirm/ButtonWithConfirm';
import {StatusChip} from '@ui/StatusChip/StatusChip';
import {Routes as RoutesLinks} from '../../../constants/routes';

type IncidentEditDialogProps = {
    data: Incident;
    setOpen: (open: boolean) => void;
    refetchTableData?: () => void;
    hideBackdrop?: boolean;
};
export const IncidentEditDetailsDialog: React.FC<IncidentEditDialogProps> = ({
    data,
    setOpen,
    refetchTableData,
    hideBackdrop = false,
}) => {
    const [editMode, setEditMode] = useState<boolean>(false);
    const onClose = () => {
        setOpen(false);
    };
    const {pathname} = useLocation();
    const service = WellsService.getInstance();
    const params = useParams<RouteParams>();
    const onEdit = () => setEditMode(!editMode);
    const loadIncident = useCallback(() => {
        return IncidentService.getIncident(data.id);
    }, [data.id]);

    const {
        data: incident,
        isLoading,
        refetch,
    } = useQuery({
        queryKey: [`incident/${data.id}`],
        queryFn: loadIncident,
    });

    const loadWellById = useCallback(() => {
        return service.getWellById(params.wellId || incident?.wellId);
    }, [incident?.wellId, params.wellId, service]);

    const {data: well} = useQuery({
        queryKey: [`/well/${params.wellId || incident?.wellId}`],
        queryFn: loadWellById,
        enabled: (params.wellId || incident?.wellId) !== undefined,
    });

    const onDelete = useCallback(() => {
        IncidentService.deleteData({
            id: incident?.id,
        })
            .then(() => {
                refetchTableData?.();
                setOpen(false);
            })
            .catch((error) => error);
    }, [incident?.id, refetchTableData, setOpen]);
    const refetchAll = useCallback(() => {
        refetchTableData?.();
        refetch();
    }, [refetch, refetchTableData]);

    const actions: {
        cancel?: ButtonProps;
        submit?: ButtonProps;
    } = editMode
        ? {
              cancel: {onClick: onClose},
              submit: {form: 'editIncidentForm', type: 'submit'},
          }
        : {};

    if (isLoading || !incident) {
        return <Loader />;
    }

    return (
        <SidebarDialog
            open={!!data}
            onClose={onClose}
            maxWidth='900px'
            actions={actions}
            hideBackdrop={hideBackdrop}
            customTitleBlock={
                <Stack
                    direction='row'
                    alignItems='center'
                    gap='16px'
                >
                    <Typography variant='h3'>
                        <div
                            style={{
                                background: 'var(--mui-palette-action-hover)',
                                borderRadius: '4px',
                                padding: '4px',
                            }}
                        >
                            {well?.name}
                        </div>
                    </Typography>
                    <Box
                        component='div'
                        sx={{flexShrink: 1, maxWidth: 580, overflow: 'hidden'}}
                    >
                        {incident.fullTitle || 'Инцидент'}
                    </Box>
                    <StatusChip status={incident.status} />
                </Stack>
            }
            customTitleActions={
                <div style={{color: 'var(--mui-palette-action-active)', flexShrink: 0}}>
                    {pathname !== RoutesLinks.CommonInfo && (
                        <>
                            <IconButton
                                onClick={onEdit}
                                sx={{
                                    backgroundColor: editMode
                                        ? 'var(--mui-palette-action-focus)'
                                        : '',
                                }}
                            >
                                <EditOutlinedIcon />
                            </IconButton>
                            <ButtonWithConfirm
                                fontSize='medium'
                                onConfirm={onDelete}
                            />
                        </>
                    )}
                    <IconButton
                        onClick={onClose}
                        style={{marginRight: '20px'}}
                    >
                        <Close />
                    </IconButton>
                </div>
            }
        >
            {editMode ? (
                <IncidentDetailsEdit
                    refetchAll={refetchAll}
                    onSave={() => setEditMode(false)}
                    data={incident}
                />
            ) : (
                <IncidentDetailsView
                    data={incident}
                    withDateLink
                />
            )}
        </SidebarDialog>
    );
};
