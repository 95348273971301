import {RangeBarItem, RangeBarProps} from '../types';
import {BarConfig} from '@ant-design/plots';
import {SupportedColorScheme} from '@mui/material';
import {computeFill} from './common';
import {DT_FORMATS, formatDate} from 'utils';

export const getRangeBarConfig = (
    props: RangeBarProps,
    colorScheme: SupportedColorScheme | undefined,
): Omit<BarConfig, 'data'> => {
    const [min, max] = getMainRange(props);

    return {
        xField: 'x',
        yField: 'groupName',
        seriesField: 'seriesName',
        isRange: true,
        maxBarWidth: 20,
        legend: {
            itemName: {
                style: {
                    fill: computeFill(colorScheme),
                },
            },
        },
        meta: {
            x: {
                type: 'time',
                min,
                max,
            },
        },
        label: {
            content: (data: unknown) => {
                return formatDate((data as RangeBarItem).x[0], DT_FORMATS.defaultDate) as string;
            },
            offsetY: -20,
            offsetX: -10,
            position: () => 'left',
            style: {
                fill: computeFill(colorScheme),
            },
        },
        tooltip: {
            formatter: (data: any) => {
                return {
                    name: data.seriesName as string,
                    value: `${formatDate(data.x[0], DT_FORMATS.defaultDate)} - ${formatDate(
                        data.x[1],
                        DT_FORMATS.defaultDate,
                    )}`,
                };
            },
            fields: ['seriesName', 'x', 'y', 'groupName'],
        },
        xAxis: {
            label: {
                formatter: (text) => {
                    return formatDate(text, DT_FORMATS.defaultDate) as string;
                },
                style: {
                    fill: computeFill(colorScheme),
                },
            },
        },
    };
};

const getMainRange = (data: RangeBarProps): [string, string] => {
    if (!data?.length) {
        return ['2020-01-01', new Date().toISOString()];
    }

    const firstItem = data[0] as RangeBarItem;
    const mainRange = firstItem.x.map((item) => new Date(item));

    data.forEach((item) => {
        const startDate = new Date(item.x[0]);
        const endDate = new Date(item.x[1]);

        if (startDate < mainRange[0]) {
            mainRange[0] = startDate;
        }

        if (endDate > mainRange[1]) {
            mainRange[1] = endDate;
        }
    });

    return mainRange.map((item) => formatDate(item, DT_FORMATS.utcDate) as string) as [
        string,
        string,
    ];
};
