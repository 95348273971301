import axios, {AxiosError, AxiosRequestConfig, AxiosResponse, isAxiosError} from 'axios';
import {AuthService} from '../../services/AuthService';
import store from 'slices';
import {setNotification} from 'slices/notifications/notificationsSlice';
import {getErrorText} from 'utils/functions/getErrorText';
import {ParamsType} from 'types/common';

enum RequestMethod {
    Get = 'get',
    Post = 'post',
    Put = 'put',
    Patch = 'patch',
    Delete = 'delete',
}

interface HTTPMethod {
    <R = unknown>(
        url: string,
        data?: unknown,
        config?: Partial<AxiosRequestConfig>,
        query?: ParamsType,
    ): Promise<R>;
}

const baseHeaders = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
};

export const instance = axios.create({
    withCredentials: true,
    baseURL: '/api',
    // позволяет передавать параметры в виде foo=[1,2,3] и отправлять в виде ?foo=1&foo=2&foo=3
    paramsSerializer: {
        indexes: null,
    },
});

instance.interceptors.request.use(async (config) => {
    if (AuthService.isAuthenticated()) {
        await AuthService.refreshToken();

        if (AuthService.getToken() && config.headers) {
            config.headers.setAuthorization(`Bearer ${AuthService.getToken()}`);
        }
    }

    return config;
});

instance.interceptors.response.use(
    (config: AxiosResponse): AxiosResponse => config,

    async (error: AxiosError) => {
        if (
            isAxiosError(error) &&
            error.response?.status === 401 &&
            error.config &&
            // @ts-ignore
            !error.config._isRetry
        ) {
            const originalRequest = error.config;

            try {
                // @ts-ignore
                originalRequest._isRetry = true;

                await AuthService.refreshToken();

                return instance.request(originalRequest);
            } catch {
                console.log('Not authorized');
            }
        }

        const formattedError: AxiosError = {...error};

        // Для запросов с типом ответа BLOB необходимо распарсить значение
        if (error?.config?.responseType === 'blob') {
            // @ts-ignore
            const data = JSON.parse(await error.response?.data.text())
            formattedError.response = {...error.response!, data}
        }

        store.dispatch(
            setNotification({
                variant: 'error',
                text: getErrorText(formattedError),
            }),
        );

        return Promise.reject(formattedError);
    },
);

const baseRequest = <R = unknown>(config: AxiosRequestConfig): Promise<R> => {
    return instance<R>({
        ...config,
        headers: Object.assign(baseHeaders, config?.headers),
    }).then((response) => response.data);
};

const api: Record<RequestMethod, HTTPMethod> = {
    get: (url, data, config) =>
        baseRequest({method: RequestMethod.Get, url: url, params: data, ...config}),
    post: (url, data, config, query) =>
        baseRequest({
            method: RequestMethod.Post,
            url,
            data,
            params: query,
            ...config,
        }),
    put: (url, data, config, query) =>
        baseRequest({
            method: RequestMethod.Put,
            url,
            data,
            params: query,
            ...config,
        }),
    patch: (url, data, config) => baseRequest({method: RequestMethod.Patch, url, data, ...config}),
    delete: (url, data, config, query) =>
        baseRequest({method: RequestMethod.Delete, url, data, params: query, ...config}),
};

export default api;
