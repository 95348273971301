import {Dialog, DialogContent, DialogTitle} from '@mui/material';
import {AccountData, AccountDataWithPassword, OpenModal, PermissionItem} from '../types';
import {AccountModalForm} from './AccountModalForm';
import {useQuery} from '@tanstack/react-query';
import {AccountsService} from '@services/Accounts/AccountsService';
import {useSnackbar} from 'notistack';
import {SuccessNotification} from '../AccontNotifications/SuccessNotification';
import {useEffect, useState} from 'react';

type AccountModalProps = {
    id?: string;
    setOpenModal: React.Dispatch<React.SetStateAction<OpenModal>>;
    setForceRefetch: React.Dispatch<React.SetStateAction<number>>;
};

export const AccountModal = ({id, setOpenModal, setForceRefetch}: AccountModalProps) => {
    const {enqueueSnackbar, closeSnackbar} = useSnackbar();
    const [initialData, setInitialData] = useState<AccountData>();
    const handleClose = (withRefetch?: boolean) => {
        setOpenModal(null);
        if (withRefetch) {
            setForceRefetch((prev) => prev + 1);
        }
    };

    const title = id ? 'Редактирование учетной записи' : 'Создание учетной записи';

    const {data: accountData} = useQuery({
        queryKey: [`getUserData_${id}`, id],
        queryFn: () => AccountsService.getUserData(id || ''),
        gcTime: 0,
        enabled: !!id,
    });

    useEffect(() => {
        if (accountData) {
            setInitialData(accountData);
        }
    }, [accountData]);

    const {data: permissionsList} = useQuery({
        queryKey: ['getPermissionsList'],
        queryFn: () => AccountsService.getPermissionsList(),
    });

    useEffect(() => {
        if (permissionsList && initialData && !initialData.fullPermissions) {
            const updatedPermissions = permissionsList.reduce((acc, permission) => {
                const item = initialData?.permissions?.find((el) => el.code === permission.code);
                if (item) {
                    // Собираем данные с бэка + информацию с апи в один объект
                    acc.push({...permission, ...item});
                }
                return acc;
            }, [] as PermissionItem[]);
            setInitialData({...initialData, fullPermissions: updatedPermissions});
        }
    }, [permissionsList, initialData]);

    const setOpenNotification = (data: AccountDataWithPassword) => {
        enqueueSnackbar('Успешно', {
            persist: false,
            autoHideDuration: 5000,
            content: (key) => (
                <SuccessNotification
                    onClose={() => closeSnackbar(key)}
                    data={data}
                />
            ),
        });
    };

    return (
        <Dialog
            open
            PaperProps={{sx: {maxWidth: 'fit-content'}}}
        >
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                <AccountModalForm
                    handleClose={handleClose}
                    initialData={initialData}
                    setOpenNotification={setOpenNotification}
                />
            </DialogContent>
        </Dialog>
    );
};
