import {memo} from 'react';
import {IconButton, InputAdornment, TextField, TextFieldVariants} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';

type SearchInputProps = {
    label?: string;
    value: string;
    onInput: (value: string) => void;
    width?: string;
    variant?: TextFieldVariants;
    size?: 'small' | 'medium';
    disabled?: boolean;
};
export const SearchInput = memo(
    ({
        value,
        onInput,
        label = 'Найти',
        variant = 'standard',
        width = '220px',
        size = 'medium',
        disabled = false,
    }: SearchInputProps) => {
        return (
            <TextField
                InputLabelProps={{
                    shrink: true,
                }}
                disabled={disabled}
                value={value}
                onChange={(e) => onInput(e.target.value)}
                sx={{width}}
                size={size}
                InputProps={{
                    startAdornment: (
                        <InputAdornment position='start'>
                            <SearchIcon />
                        </InputAdornment>
                    ),
                    endAdornment: value && (
                        <InputAdornment
                            sx={{cursor: 'pointer'}}
                            position='end'
                        >
                            <IconButton onClick={() => onInput('')}>
                                <CloseIcon fontSize='small' />
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
                placeholder={label}
                variant={variant}
            />
        );
    },
);
