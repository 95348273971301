import { ReactElement } from 'react';
import {Row} from './types';
import {Draggable, DraggableProvided} from 'react-beautiful-dnd';

type TableDragDropContextProps = {
    children: (draggableProvided: DraggableProvided) => ReactElement;
    row: Row;
    index: number;
};

export const TableDraggableRow = ({children, row, index}: TableDragDropContextProps) => {
    return (
        <Draggable
            draggableId={row.id + ''}
            index={index}
        >
            {(draggableProvided: DraggableProvided) => children(draggableProvided)}
        </Draggable>
    );
};
