import {Divider, Paper, Stack, ToggleButton, ToggleButtonGroup} from '@mui/material';
import {DraggableModal} from '../../../../../components/DraggableModal/DraggableModal';
import {TripHeader} from './TripHeader';
import {useCallback, useEffect, useState} from 'react';
import api from 'utils/api';
import {useQuery} from '@tanstack/react-query';
import {IncidentsByWellResponse} from '@features/incident/types';
import {LightTooltip} from '@ui/LightTooltip/LightTooltip';
import {TripInfo} from './TripInfo';
import {TripIncidents} from './TripIncidents';

enum Tabs {
    ABOUT = 'about',
    INCIDENTS = 'incidents',
}

type Props = {
    tripId: number;
    onClose: () => void;
    aboveModal?: boolean;
    setSelectedTripByIndex?: (idx: number | null) => void;
    currentTripIndex?: number;
    tripsCount?: number;
};
export const TripModal = ({
    tripId,
    onClose,
    aboveModal = false,
    setSelectedTripByIndex,
    currentTripIndex,
    tripsCount,
}: Props) => {
    const [tab, setTab] = useState<Tabs>(Tabs.ABOUT);
    const loadTripIncidents = () =>
        api.get<IncidentsByWellResponse>(
            `/bha-runs/${tripId}/incidents`,
            {},
            {withoutGlobalLoader: true},
        );

    const {
        data: incidents,
        isLoading,
        refetch,
    } = useQuery<IncidentsByWellResponse>({
        queryKey: ['/bha-runs/incidents', tripId],
        queryFn: loadTripIncidents,
    });

    const handleTabChange: (event: React.MouseEvent<HTMLElement, MouseEvent>, value: any) => void =
        useCallback((e, v) => {
            if (v !== null) {
                setTab(v);
            }
        }, []);
    useEffect(() => {
        if (!isLoading && incidents?.summaries?.length === 0 && tab === Tabs.INCIDENTS) {
            setTab(Tabs.ABOUT);
        }
    }, [isLoading, incidents, tab]);
    return (
        <DraggableModal id='trips_modal'>
            <div
                className='trips-modal-draggable'
                style={{
                    position: 'absolute',
                    top: '0',
                    right: '300px',
                    borderRadius: '8px',
                    width: '600px',
                    zIndex: aboveModal
                        ? 'calc(var(--mui-zIndex-modal) + 1)'
                        : 'var(--mui-zIndex-appBar)',
                }}
            >
                <Paper sx={{padding: '16px', height: tab === Tabs.ABOUT ? 'unset' : '100%'}}>
                    <Stack
                        direction='column'
                        gap='8px'
                        height='100%'
                    >
                        <TripHeader
                            onClose={onClose}
                            tripId={tripId}
                            setSelectedTripByIndex={setSelectedTripByIndex}
                            currentTripIndex={currentTripIndex}
                            tripsCount={tripsCount}
                        />

                        <Divider
                            flexItem
                            sx={{
                                margin: '8px 0 8px -16px',
                                width: 'calc(100% + 32px)',
                            }}
                        />

                        <ToggleButtonGroup
                            size='small'
                            sx={{width: '100%', marginBottom: '16px'}}
                            value={tab}
                            exclusive
                            onChange={handleTabChange}
                        >
                            <ToggleButton
                                size='small'
                                value={Tabs.ABOUT}
                                disabled={tab === Tabs.ABOUT}
                                sx={{width: '50%', textTransform: 'unset'}}
                            >
                                О рейсе
                            </ToggleButton>
                            <LightTooltip
                                title={incidents?.summaries?.length === 0 ? 'Инцидентов нет' : ''}
                                offset={[20, 0]}
                            >
                                <div style={{width: '50%'}}>
                                    <ToggleButton
                                        size='small'
                                        value={Tabs.INCIDENTS}
                                        disabled={
                                            tab === Tabs.INCIDENTS ||
                                            isLoading ||
                                            incidents?.summaries?.length === 0
                                        }
                                        sx={{width: '100%', textTransform: 'unset'}}
                                    >
                                        Инциденты
                                    </ToggleButton>
                                </div>
                            </LightTooltip>
                        </ToggleButtonGroup>

                        {tab === Tabs.ABOUT ? (
                            <TripInfo tripId={tripId} />
                        ) : (
                            <TripIncidents
                                data={incidents?.summaries}
                                refetch={refetch}
                            />
                        )}
                    </Stack>
                </Paper>
            </div>
        </DraggableModal>
    );
};
