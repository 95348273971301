import {Autocomplete, Stack, TextField} from '@mui/material';
import React, {useMemo} from 'react';
import {useDictionary} from 'utils/hooks/useDictionary';
import {useSelector} from 'react-redux';
import {selectDictionaries} from 'slices';
import {CustomTags} from '../CustomTags';
import {useKeepOpen} from 'utils/hooks/useKeepOpen';
import {useWells} from '@ui/WellsTree/hooks/useWells';

type FiltersLimitedWellsProps = {
    setSelectedStages: (values: string[]) => void;
    setSelectedWells: (values: string[]) => void;
    selectedWells: string[];
    selectedStages: string[];
    wellIds: string[];
};
export const FiltersLimitedWells: React.FC<FiltersLimitedWellsProps> = ({
    setSelectedWells,
    setSelectedStages,
    selectedWells,
    selectedStages,
    wellIds,
}) => {
    const constructionStageApi = 'WellConstructionStage';
    useDictionary({code: constructionStageApi});
    const {wells} = useWells();
    const dictionaries = useSelector(selectDictionaries);
    const constructionStageNamesDictionary = dictionaries[constructionStageApi] || [];
    const constructionStageNames = constructionStageNamesDictionary.map((el) => el.value);
    const wellsOptions = useMemo(
        () =>
            wells
                ?.filter((w) => wellIds?.includes(`${w.id}`))
                .map((w) => ({label: w.name, value: w.id})) || [],
        [wellIds, wells],
    );
    return (
        <Stack
            direction='row'
            gap='8px'
        >
            <Autocomplete
                {...useKeepOpen()}
                multiple
                sx={{width: '50%'}}
                disableClearable
                options={constructionStageNames}
                onChange={(_, values) => {
                    setSelectedStages(values);
                }}
                value={selectedStages}
                renderTags={() => (
                    <CustomTags
                        dataLength={selectedStages.length}
                        onClear={() => setSelectedStages([])}
                    />
                )}
                renderInput={(params) => {
                    return (
                        <TextField
                            {...params}
                            label='Этап строительства'
                            variant='standard'
                        />
                    );
                }}
            />
            <Autocomplete
                {...useKeepOpen()}
                multiple
                sx={{width: '50%'}}
                disableClearable
                blurOnSelect={false}
                isOptionEqualToValue={(option, value) => option.value === value.value}
                options={wellsOptions}
                value={wellsOptions.filter((w) => selectedWells.includes(`${w.value}`))}
                onChange={(_, values) => {
                    setSelectedWells(values.map((v) => `${v.value}`));
                }}
                renderTags={() => (
                    <CustomTags
                        dataLength={selectedWells.length}
                        onClear={() => setSelectedWells([])}
                    />
                )}
                renderInput={(params) => {
                    return (
                        <TextField
                            {...params}
                            label='Скважина'
                            variant='standard'
                        />
                    );
                }}
            />
        </Stack>
    );
};
