import 'reflect-metadata';
import ReactDOM from 'react-dom/client';
import {Provider} from 'react-redux';
import {createTheme, StyledEngineProvider, ThemeProvider} from '@mui/material';
import {Experimental_CssVarsProvider as CssVarsProvider} from '@mui/material/styles/CssVarsProvider';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/ru';
import {LocalizationProvider} from '@mui/x-date-pickers';

import App from './App';
import store from './slices';

import 'antd/dist/reset.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import AuthWrapper from './components/AuthWrapper';

import {theme} from '@core/theme/theme';
import {AntThemeOverride} from '@core/theme/AntThemeOverride';
import {QueryClientProvider} from '@tanstack/react-query';
import {client} from '@core/api/reactQueryClient';
import {NotificationsProvider} from '@features/notifications/NotificationsProvider';
import FullScreenLoader from '@ui/Loader/FullScreenLoader';
import {Notifications} from '@features/notifications/Notifications';
import {ErrorBoundary} from '@ui/ErrorBoundary/ErrorBoundary';
import {ruRU as pickersRuRU} from '@mui/x-date-pickers/locales';
import {ruRU as dataGridRuRU} from '@mui/x-data-grid/locales';
import {ruRU as coreRuRU} from '@mui/material/locale';

const translationTheme = createTheme(
    undefined,
    coreRuRU, // core translations
    dataGridRuRU, // x-data-grid translations
    pickersRuRU, // x-date-pickers translations
);
const root = ReactDOM.createRoot(document.getElementById('root') as HTMLDivElement);

root.render(
    <ErrorBoundary>
        <QueryClientProvider client={client}>
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={translationTheme}>
                    <CssVarsProvider theme={theme}>
                        <Provider store={store}>
                            <AuthWrapper>
                                <LocalizationProvider
                                    dateAdapter={AdapterDayjs}
                                    adapterLocale='ru-RU'
                                    localeText={pickersRuRU.components.MuiLocalizationProvider.defaultProps.localeText}
                                >
                                    <NotificationsProvider>
                                        <AntThemeOverride>
                                            <FullScreenLoader/>
                                            <Notifications/>
                                            <App/>
                                        </AntThemeOverride>
                                    </NotificationsProvider>
                                </LocalizationProvider>
                            </AuthWrapper>
                        </Provider>
                    </CssVarsProvider>
                </ThemeProvider>
            </StyledEngineProvider>
        </QueryClientProvider>
    </ErrorBoundary>,
)
;
