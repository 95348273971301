import {Routes} from 'constants/routes';
import {MenuItem, MenuItemType} from 'utils';

const computeUrl = (item: MenuItem, wellId: string) => {
    if (item.isFiles) {
        return `${Routes.Files}/${wellId}/files/${item.code}`;
    }
    if ([MenuItemType.KnbkGisGroups].includes(item.type)) {
        return `${Routes.KnbkGis}/${wellId}/${item.code}`;
    }
    if ([MenuItemType.CasingColumnDescentProtocol].includes(item.type)) {
        return `${Routes.Ccdp}/${wellId}/${item.code}`;
    }
    if ([MenuItemType.Prices].includes(item.type)) {
        return `${Routes.Prices}/${wellId}`;
    }
    if ([MenuItemType.Ggd].includes(item.type)) {
        return `${Routes.Ggd}/${wellId}/${item.code}`;
    }
    if ([MenuItemType.KnbkDrillingGroups].includes(item.type)) {
        return `${Routes.Knbk}/${wellId}/${item.code}`;
    }

    if ([MenuItemType.CalendarOperations, MenuItemType.LiquorActualCalendar].includes(item.type)) {
        return `${Routes.Calendar}/${wellId}/${item.code}`;
    }

    if ([MenuItemType.Nested].includes(item.type) && item.children) {
        return item.children.map((child) => `${Routes.Tables}/${wellId}/${child.code}`);
    }

    if (item.type === MenuItemType.Trajectory) {
        return `${Routes.Trajectory}/${wellId}`;
    }
    return `${Routes.Tables}/${wellId}/${item.code}`;
};

export const getUrlForMenuItem = (item: MenuItem, wellId: string) => {
    if (item.type === MenuItemType.Trajectory) {
        return computeUrl(item, wellId);
    }
    const itemToNavigateTo = item.childToRedirectTo ?? item;
    return computeUrl(itemToNavigateTo, wellId);
};

export const getChildUrlsForMenuItem = (item: MenuItem, wellId: string) => {
    const result = item.children?.reduce((acc: String[], item) => {
        const url = computeUrl(item, wellId);
        if (Array.isArray(url)) {
            acc.push(...url);
            return acc;
        } else {
            acc.push(url);
            return acc;
        }
    }, []);

    if (item.type === MenuItemType.Trajectory) {
        const url = computeUrl(item, wellId);
        if (Array.isArray(url)) {
            result?.push(...url);
        } else {
            result?.push(url);
        }
    }
    return result;
};
