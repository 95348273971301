import React from 'react';
import {Typography} from '@mui/material';

export interface IErrorBoundaryState {
    hasError: boolean;
    error: Error | null;
}

export interface IErrorBoundary {
    renderFallback?: (error: Error) => React.ReactNode;
    children: React.ReactNode;
}

const DefaultErrorMessage = () => {
    return (
        <Typography
            ml={2}
            mt={3}
            variant='h3'
        >
            Что-то пошло не так.... Пожалуйста, перезагрузите страницу
        </Typography>
    );
};

export class ErrorBoundary extends React.PureComponent<IErrorBoundary> {
    override state: IErrorBoundaryState = {
        hasError: false,
        error: null,
    };

    static getDerivedStateFromError(error: Error): {
        hasError: boolean;
        error: Error;
    } {
        return {hasError: true, error};
    }

    renderFallback = (): React.ReactNode => {
        const {renderFallback} = this.props;
        const {error} = this.state;
        return renderFallback ? renderFallback(error as Error) : <DefaultErrorMessage />;
    };

    override render(): React.ReactNode {
        const {children} = this.props;
        const {hasError} = this.state;

        return hasError ? this.renderFallback() : children;
    }
}
