import {Stack, Typography} from '@mui/material';
import {ErrorOutline} from '@mui/icons-material';

type WarningsProps = {
    warnings: string[];
    maxHeight?: number;
    bgcolor?: string;
};
export const Warnings = ({
    warnings,
    maxHeight = 180,
    bgcolor = 'var(--mui-palette-paper_elevation-16)',
}: WarningsProps) => {
    if (warnings.length === 0) {
        return null;
    }
    return (
        <div
            style={{
                position: 'relative',
            }}
        >
            <Stack
                gap='2px'
                position='relative'
                padding='10px'
                borderRadius='var(--mui-shape-borderRadius)'
                border='1px solid var(--mui-palette-action-disabled)'
                my='16px'
                overflow='auto'
                maxHeight={`${maxHeight}px`}
            >
                {warnings.map((w) => (
                    <Typography
                        variant='body2'
                        key={w}
                    >
                        {w}
                    </Typography>
                ))}
            </Stack>
            <Stack
                alignItems='center'
                px='4px'
                position='absolute'
                bgcolor={bgcolor}
                top='-11px'
                left='8px'
                sx={{
                    borderRadius: '50%',
                }}
            >
                <ErrorOutline
                    color='error'
                    fontSize='small'
                />
            </Stack>
        </div>
    );
};
