import React, {useMemo, useState} from 'react';
import styles from './styles.module.css';
import {useParams} from 'react-router-dom';
import {RouteParams} from 'utils';
import {FiltersWell} from './filters/FiltersWell';
import {useLoadPnv} from './hooks';
import {ListPopup} from './listPopup/ListPopup';
import {ListPopupSummary, PnvByWellSummary} from '../types';

const transformPnvData: (pnvs: PnvByWellSummary[]) => ListPopupSummary[] | undefined = (pnvs) => {
    if (!pnvs) {
        return undefined;
    }

    return pnvs.map((pnv) => ({
        title: pnv.workCategory,
        durationSumDays: pnv.durationSumDays,
        durationSumPercent: pnv.durationSumPercent,
        incidents: pnv.pnvs.map((p) => ({
            durationHours: p.durationHours,
            id: `${p.casingColumn} - ${p.workType}`,
            title: `${p.casingColumn} - ${p.workType}`,
            disableSelection: true,
        })),
    }));
};

type PnvListPopupProps = {
    onClose: () => void;
    page: string;
};
export const PnvListPopup = ({onClose, page}: PnvListPopupProps) => {
    const params = useParams<RouteParams>();
    const [selectedStages, setSelectedStages] = useState<string[]>([]);
    const [selectedCasingColumns, setSelectedCasingColumns] = useState<string[]>([]);
    const {data, refetch, isLoading} = useLoadPnv({
        wellId: params.wellId!,
        casingColumn: selectedCasingColumns,
        wellConstructionStage: selectedStages,
        enabled: params.wellId !== undefined,
    });
    const transformedData = useMemo(() => transformPnvData(data), [data]);

    if (!transformedData) {
        return null;
    }

    return (
        <ListPopup
            popupType='PNV_list'
            data={transformedData}
            refetch={refetch}
            isLoading={isLoading}
            onClose={onClose}
            page={page}
            className={styles.incidentsWell}
            filters={
                <FiltersWell
                    setSelectedStages={setSelectedStages}
                    setSelectedCasingColumns={setSelectedCasingColumns}
                    selectedStages={selectedStages}
                    selectedCasingColumns={selectedCasingColumns}
                />
            }
        />
    );
};
