import {IncidentType} from '@features/incident/types';
import {ToggleButton, ToggleButtonGroup, Typography} from '@mui/material';

type ToggleIncidentsTypesProps = {
    incidentType: IncidentType;
    setIncidentType: (incident: IncidentType) => void;
};

export const ToggleIncidentsTypes = ({
    incidentType,
    setIncidentType,
}: ToggleIncidentsTypesProps) => {
    const handleIncidentChange = (
        event: React.MouseEvent<HTMLElement>,
        newIncident: IncidentType | null,
    ) => {
        if (newIncident) {
            setIncidentType(newIncident);
        }
    };

    return (
        <ToggleButtonGroup
            value={incidentType}
            exclusive
            onChange={handleIncidentChange}
            aria-label='incident type'
            size='small'
        >
            <ToggleButton
                value={IncidentType.NPV}
                aria-label='НПВ'
                disableRipple
            >
                <Typography
                    variant='subtitle1'
                    sx={{
                        textTransform: 'none',
                        fontWeight: 500,
                        color: 'var(--mui-palette-text-primary)',
                    }}
                >
                    НПВ
                </Typography>
            </ToggleButton>
            <ToggleButton
                value={IncidentType.PNV}
                aria-label='ПНВ'
                disableRipple
            >
                <Typography
                    variant='subtitle1'
                    sx={{
                        textTransform: 'none',
                        fontWeight: 500,
                        color: 'var(--mui-palette-text-primary)',
                    }}
                >
                    ПНВ
                </Typography>
            </ToggleButton>
            <ToggleButton
                value={IncidentType.ADDITIONAL_WORK}
                aria-label='Доп. работы'
                disableRipple
            >
                <Typography
                    variant='subtitle1'
                    sx={{
                        textTransform: 'none',
                        fontWeight: 500,
                        color: 'var(--mui-palette-text-primary)',
                    }}
                >
                    Доп. работы
                </Typography>
            </ToggleButton>
        </ToggleButtonGroup>
    );
};
