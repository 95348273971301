import {DonutValues} from '../types';
import {getExtendedValues} from '../utils';
import {Stack, Box, Typography, Divider, StackProps} from '@mui/material';
import styles from './DonutLegendByService.module.css';
import {getIndexColor} from '../../../features/wellDesign/DashboardItems/utils';
import {useMemo} from 'react';

type DonutLegendProps = {
    values: DonutValues;
    size: 'small' | 'medium' | 'large';
    headerProps?: StackProps;
} & StackProps;
export const DonutLegendByService = ({
    values,
    size,
    headerProps,
    ...otherProps
}: DonutLegendProps) => {
    const extendedValues = getExtendedValues(values);

    const fontSize = useMemo(() => {
        if (size === 'small') return 10;
        if (size === 'medium') return 14;
        return 14;
    }, [size]);
    const minWidth = size === 'small' ? 30 : 50;

    return (
        <Stack
            direction='column'
            gap='3px'
            maxHeight='142px'
            overflow='hidden'
            paddingRight='10px'
            sx={{overflowY: 'auto'}}
            {...otherProps}
        >
            <Stack
                direction='row'
                gap={1}
                alignItems='center'
                justifyContent='flex-start'
                position='sticky'
                sx={{backgroundColor: 'var(--mui-palette-background-default)'}}
                top={0}
                zIndex={100}
                {...headerProps}
            >
                <Box
                    flexGrow={2}
                    component='div'
                ></Box>

                <Box
                    component='div'
                    marginRight='14px'
                >
                    <Typography
                        variant='body2'
                        minWidth={minWidth}
                        textAlign='right'
                        color='text.primary'
                        fontSize='12px'
                        noWrap
                    >
                        Тип сервиса
                    </Typography>
                </Box>

                <Box component='div'>
                    <Typography
                        variant='body2'
                        minWidth={minWidth}
                        textAlign='right'
                        color='text.primary'
                        fontSize='12px'
                        noWrap
                    >
                        %
                    </Typography>
                </Box>

                <Box
                    component='div'
                    marginLeft='5px'
                >
                    <Typography
                        variant='body2'
                        minWidth={minWidth}
                        textAlign='right'
                        color='text.primary'
                        fontSize='12px'
                        noWrap
                    >
                        млн. руб
                    </Typography>
                </Box>
            </Stack>
            <Divider flexItem={true} />

            {extendedValues.map((v, i) => {
                return (
                    <Stack
                        key={v.name}
                        display='flex'
                        direction='row'
                        alignItems='center'
                        justifyContent='flex-start'
                    >
                        <Box
                            flexShrink={0}
                            flexBasis={12}
                            width={12}
                            justifyContent='space-between'
                            alignItems='center'
                            style={{backgroundColor: v.color || getIndexColor(i)}}
                            className={styles.icon}
                            component='div'
                        ></Box>

                        <Box
                            width='106px'
                            justifyContent='center'
                            overflow='hidden'
                            marginLeft='5px'
                            component='div'
                        >
                            <Typography
                                variant='body2'
                                color='text.primary'
                                fontSize={fontSize}
                                noWrap
                            >
                                {v?.name}
                            </Typography>
                        </Box>

                        <Box
                            display='flex'
                            justifyContent='center'
                            alignItems='center'
                            marginLeft='8px'
                            component='div'
                        >
                            <Typography
                                variant='regular2'
                                minWidth={minWidth}
                                textAlign='right'
                                fontSize={fontSize}
                            >
                                {Math.round(v.percent * 10) / 10}
                            </Typography>
                        </Box>

                        <Box
                            display='flex'
                            alignItems='center'
                            width='61px'
                            textOverflow='ellipsis'
                            justifyContent='flex-end'
                            overflow='hidden'
                            marginLeft='8px'
                            component='div'
                        >
                            <Typography
                                variant='regular2'
                                textAlign='right'
                                fontSize={fontSize}
                            >
                                {v.value.toFixed(3)}
                            </Typography>
                        </Box>
                    </Stack>
                );
            })}
        </Stack>
    );
};
