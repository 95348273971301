import React from 'react';
import {ParametersListProps} from '../types';
import {
    Box,
    Card,
    CardContent,
    Table,
    TableBody,
    TableCell as MuiTableCell,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import {styled} from '@mui/material/styles';
import {grey} from '@mui/material/colors';
import {getColorByIndex} from '../utils/utils';

const TableCell = styled(MuiTableCell)`
    border-bottom: none;
    padding-top: 8px;
    padding-bottom: 8px;
`;

const ParametersList = ({title, items}: ParametersListProps) => {
    if (!items?.length) {
        return null;
    }

    return (
        <Card
            sx={{
                width: '50%',
                marginRight: 2,
                '&:last-child': {
                    marginRight: 0,
                },
            }}
        >
            <CardContent>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <Typography
                                    color={grey[800]}
                                    textTransform='uppercase'
                                    fontWeight={500}
                                    component='h3'
                                    variant='body2'
                                >
                                    {title}
                                </Typography>
                            </TableCell>
                            <TableCell sx={{color: grey[500], fontSize: '12px'}}>
                                время, ч
                            </TableCell>
                            <TableCell sx={{color: grey[500]}}>%</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {items.map((item, index) => (
                            <TableRow key={item.name}>
                                <TableCell>
                                    <Box sx={{display: 'flex', alignItems: 'center'}} component="div">
                                        <Box
                                            sx={{
                                                flexShrink: 0,
                                                width: '10px',
                                                height: '10px',
                                                borderRadius: '50%',
                                                background: getColorByIndex(index),
                                                marginRight: 1,
                                            }}
                                            component="div"
                                        ></Box>
                                        {item.name}
                                    </Box>
                                </TableCell>
                                <TableCell>{item.hours}</TableCell>
                                <TableCell>{item.percents}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </CardContent>
        </Card>
    );
};

export default React.memo(ParametersList);
