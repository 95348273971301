import React, {PropsWithChildren} from 'react';
import {Form as FormComponent, FormRenderProps} from 'react-final-form';
import {FormProps} from './types';

const Form = <Type extends object>({
    children,
    onSubmit,
    initialValues,
}: PropsWithChildren<FormProps<Type>>) => {
    return (
        <FormComponent
            onSubmit={onSubmit}
            initialValues={initialValues}
        >
            {({handleSubmit}: FormRenderProps<Type>) => (
                <form onSubmit={handleSubmit}>{children}</form>
            )}
        </FormComponent>
    );
};

export default Form;
